<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col md="12">
        <div class="dispatch-list">
          <div>
            <p>Indicator List Card</p>
          </div>
        </div>
        <b-row>
          <b-col
            sm="6"
            lg="3"
            v-for="(item, index) in checklistItem"
            :key="`dynlist-${index}`"
          >
            <b-card
              no-body
              class="bg-background"
              @click="onfilterChecklist(item)"
            >
              <b-card-body>
                <div class="text-center">
                  <h5>{{ item.checklistdescription }}</h5>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import api from "../../services/api";

export default {
  data: function () {
    return {
      checklistItem: [],
    };
  },
  methods: {
    //Checlist fileter
    onfilterChecklist: function (val) {
      localStorage.removeItem("checklistidd");

      localStorage.setItem(val.checklistId, "checklistidd");
      this.$router.push(
        "/Maintenance/2017042003480096/indicator-by-checlistId/" +
          val.checklistId
      );
    },
    GetChecklistID: function () {
      let self = this;

      self.$store.commit("setLoading", true);
      self.checklistItem = [];
      api.instance
        .get(
          "itsek/get-chechlist-by-indicator?department=" +
            this.$store.getters.warehousecode
        )
        .then(function (resp) {
          resp.data.forEach((element) => {
            self.checklistItem.push({
              checklistId: element.checklistId,
              checklistdescription: element.checklistdescription,
            });
          });
          self.ChecklistidModal = true;
          self.$store.commit("setLoading", false);
        })
        .catch(function (err) {
          api.httpMsg(self, err.status, err.data);
          self.$store.commit("setLoading", false);
        });
    },
  },
  created: function () {
    this.GetChecklistID();
  },
};
</script>
