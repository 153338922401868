<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col md="12">
        <div class="dispatch-list row">
          <b-button variant="none" @click="back">
            <i class="fa fa-chevron-left"></i>
            Back
          </b-button>
        </div>

        <div class="dispatch-table">
          <b-card header>
            <div slot="header">
              <span>Indicator List</span>
              <a
                href="#"
                class="btn btn-sm btn-info float-right ml-1"
                id="printpagebutton"
                @click="onPrint"
              >
                <i class="fa fa-print"></i> Print
              </a>
              <button
                id="printexport"
                class="btn btn-sm btn-info float-right"
                @click="onexport"
              >
                Export
              </button>
            </div>
            <div class="newButton">
              <b-button
                variant="primary"
                class="btnsm"
                @click="OnClinkNEwIndicator()"
              >
                <i class="fa fa-plus-circle"></i>&nbsp;New Indicator
              </b-button>
              <div class="float-right">
                <b-button
                  variant="success"
                  class="btnsm"
                  @click="ExportIndicators()"
                  style="margin-right:20px;"
                >
                  <i class="fa fa-save"></i>&nbsp;Update Indicators
                </b-button>
                <!-- <b-button
                  variant="warning"
                  class="btnsm mx-2"
                  @click="loadIndicatorsModal = true"
                >
                  <i class="fa fa-download"></i>&nbsp;Load Remembered Indicators
                </b-button> -->

                 <b-button
                  variant="success"
                  class="btnsm"
                 @click="loadIndicatorsModal = true"
                >
                  <i class="fa fa-save"></i>&nbsp;Update Attachment
                </b-button>
              </div>
            </div>
            <b-row>
              <b-col md="4" class="my-1">
                <b-form-group label-cols-sm="1" class="mb-0">
                  <b-input-group>
                    <b-form-input
                      v-model="filter"
                      placeholder="Type to Search"
                    />
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''"
                        >Clear</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col> </b-row
            >&nbsp;
            <b-table
              show-empty
              stacked="md"
              responsive="sm"
              hover
              small
              striped
              fixed
              head-variant="light"
              :busy.sync="isBusy"
              :items="items"
              :fields="fields"
              :per-page="perPage"
              :filter="filter"
            >
            <!-- <template v-slot:cell(check_button)="data">
            <b-form-checkbox v-model="checkitem" :value="data.item.indicatorId">{{checkitem}}</b-form-checkbox>
              </template> -->
              <template v-slot:cell(action_button)="data">
                <b-button
                  variant="primary"
                  class="btn btn-primary btn-sm btn-square"
                  @click="OnClinkUpdateIndicator(data.item.indicatorId)"
                >
                  <i class="fa fa-pencil-square-o"></i> </b-button
                >&nbsp;
                <!-- <router-link
                  tag="button"
                  class="btn btn-primary btn-sm btn-square"
                  :to="'/Maintenance/2017042003480096/modify/indicator/' + data.item.indicatorId"

                >
                  <i class="fa fa-pencil-square-o"></i>
                </router-link>&nbsp; -->
                <b-button
                  size="sm"
                  variant="danger"
                  class="btn btn-danger btn-sm btn-square"
                  @click="onSelecteItemToDelete(data.item)"
                >
                  <i class="fa fa-trash-o"></i>
                </b-button>
              </template>
              <template slot="description" slot-scope="data">
                <div class="ellipsis">
                  <span v-b-popover.hover="data.item.description">{{
                    data.item.description
                  }}</span>
                </div>
              </template>
              <template slot="valuedatatype" slot-scope="data">{{
                valueTypeToLemans(data.item.valuedatatype)
              }}</template>
              <template slot="status" slot-scope="data">
                <i
                  :class="
                    data.item.status == 'A'
                      ? 'activeStatus icon-check active_icon'
                      : 'inactiveStatus icon-close inactive_icon'
                  "
                ></i>
              </template>
            </b-table>
          </b-card>
          <div style="margin-top: 5px">
            <b-pagination
              size="sm"
              :total-rows="totalItem"
              v-model="currentPage"
              :per-page="perPage"
              @input="onPageSelect"
            ></b-pagination>
          </div>
        </div>
        <div class="dispatch-list">
          <div class="newButton">
            <div>
              <p>Indicator List</p>
            </div>
            <b-button
              variant="primary"
              class="btnsm"
              @click="OnClinkNEwIndicator()"
            >
              <i class="fa fa-plus-circle"></i>&nbsp;New Indicator
            </b-button>
          </div>

          <b-list-group
            v-for="(item, index) in items"
            :key="`dynlist-${index}`"
            class="shadow"
          >
            <b-list-group-item class="flex-column align-items-start">
              <div class="list-field">
                <span class="mb-1 dis">Shortname:{{ item.shortname }}</span>
              </div>

              <div class="list-field">
                <span class="mb-1 dis">Description:</span>

                <span>{{ item.description }}</span>
              </div>
              <div class="list-field">
                <span class="mb-1 dis">Attach Required:</span>
                <span>{{ item.attachrequired }}</span>
              </div>

              <div class="list-field">
                <span class="mb-1 dis">Value Data Type:</span>
                <span>{{ valueTypeToLemans(item.valuedatatype) }}</span>
              </div>
              <div class="list-field">
                <span class="mb-1 dis">Status:</span>
                <i
                  :class="
                    item.status == 'A'
                      ? 'activeStatus icon-check active_icon'
                      : 'inactiveStatus icon-close inactive_icon'
                  "
                ></i>
              </div>

              <hr />
              <div class="row">
                <div class="col">
                  <b-button
                    variant="primary"
                    class="btn btnsm btn-primary btn-block"
                    @click="OnClinkUpdateIndicator(item.indicatorId)"
                  >
                    <i class="fa fa-pencil-square-o"></i>
                    Edit
                  </b-button>
                  

                  <b-button
                    class="btn btnsm btn-danger btn-block"
                    @click="onSelecteItemToDelete(item)"
                  >
                    <span class="text-light">
                      <i class="fa fa-trash-o"></i>
                      Remove
                    </span>
                  </b-button>
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
        </div>
        <b-modal
          title="System Message"
          v-model="deleteModal"
          @ok="onDeleteItem"
        >
          Are you sure you want to remove
          <strong>
            {{ selectedItemLabel }}
            ?
          </strong>
        </b-modal>

        <b-modal
          title="Update Attachments for Site/s"
          v-model="loadIndicatorsModal"
        >
          
        </b-modal>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import api from "../../services/api";
import JSONToCSVConvertor from "../../../src/services/JSONToCSVConvertor.js";
import miniToastr from "mini-toastr";
export default {
  data: function () {
    return {
      loadIndicatorsModal: false,
      saveCounter: 0,
      deleteModal: false,
      selectedItem: {},
      selectedItemLabel: "",
      checklistItem: [],
      checklist: "",
      checkitem: [],
      // perPage: this.$store.getters.perPage,
      perPage: 100,
      totalItem: 0,
      currentPage: 1,
      filter: null,
      sortOptions: [],
      isBusy: false,
      indicatorId: "",
      fields: [
        //  {
        //   key: "check_button",
        //   label: "Select",
        // },
        {
          key: "action_button",
          label: "Action",
        },
        {
          key: "shortname",
          label: "Short name",
          sortable: true,
        },
        {
          key: "description",
          label: "Description",
        },
        {
          key: "attachrequired",
          label: "Attach Required",
          sortable: true,
        },
        {
          key: "valuedatatype",
          label: "Value Data Type",
        },

        {
          key: "status",
          label: "Status",
        },
        {
          key: "sortnumber",
          label: "Sort",
          sortable: true,
        },
      ],

      items: [],
      warehouse: this.$store.getters.warehousecode,
      checkid: null,
    };
  },
  methods: {
    ExportIndicators() {
      // localStorage.setItem("itsekIndicators", JSON.stringify(this.items));
      // console.log('exported:',this.items);
      this.checkid = localStorage.getItem("checklistidd");

      this.isBusy = true;
      let self = this;
      self.$store.commit("setLoading", true);
      let promise = api.instance.get(
        "Itsek/get-maintenance-allsites-indicators?code=" +
          this.warehouse +
          "&checkid=" +
          this.$route.params.id +
          "&createby=" +
          this.$store.state.firstname +
          " " +
          this.$store.state.user.lastname
      );

      return promise.then((resp) => {
        var mess = resp.data.Message;
        miniToastr.success(mess);
        self.$store.commit("setLoading", false);
        window.location.reload();
      });
    },
    ImportIndicators() {
      // console.log('imported:',localStorage.getItem("itsekIndicators"));

      this.items = JSON.parse(localStorage.getItem("itsekIndicators"));
      this.saveCounter = 0;
      this.totalItem = this.items.length;
      this.items.forEach(function (v) {
        delete v.indicatorId;
      });
      if (this.items.length > 0 || this.items != null) {
        this.PostIndicator(this.items[0]);
      }
    },
    PostIndicator(data) {
      // console.log(data)

      data.checklistId = this.$route.params.id;
      data.department = this.warehouse;
      this.$store.commit("setLoading", true);
      api.instance
        .post("itsek/add-indicator", data)
        .then((resp) => {
          this.isShowAlert = true;
          if (this.saveCounter != this.items.length - 1) {
            this.saveCounter += 1;
          }
          this.$store.commit("setLoading", false);
        })
        .catch((err) => {
          if (err.response.data.ExceptionMessage === "") {
            api.httpMsg(this, err.status, err.data);
          } else {
            miniToastr.error(err.response.data.ExceptionMessage);
          }
          this.$store.commit("setLoading", false);
        });
    },
    valueTypeToLemans: function (val) {
      if (val === "BOOLEAN") {
        return " YES/NO";
      } else if (val === "TEXT") {
        return "PARAGRAPH";
      } else if (val === "NUMERIC") {
        return "NUMBER";
      }
    },
    back: function () {
      this.$router.push("/Maintenance/2017042003480096");
    },
    onPrint: function (evt) {
      evt.preventDefault();
      // this.isHidden = true;
      var printButton = document.getElementById("printpagebutton");
      var printExport = document.getElementById("printexport");
      var breadView = document.getElementById("breadview");
      //Set the print button visibility to 'hidden'
      printButton.style.visibility = "hidden";
      printExport.style.visibility = "hidden";
      breadView.style.visibility = "hidden";
      //Print the page content
      window.print();
      printButton.style.visibility = "visible";
      printExport.style.visibility = "visible";
      breadView.style.visibility = "visible";
    },
    onexport: function () {
      JSONToCSVConvertor(this.items, "Indicator List", true);
    },
    // initTable() {
    //   // Here we don't set isBusy prop, so busy state will be handled by table itself
    //   this.isBusy = true;
    //   let self = this;
    //   self.GetChecklistID();
    //   self.$store.commit("setLoading", true);
    //   let promise = api.instance.get(
    //     "itsek/get-indicator-list?department=" +
    //       self.warehouse +
    //       "&param=&tk=" +
    //       self.perPage +
    //       "&pg=" +
    //       self.currentPage
    //   );

    //   return promise
    //     .then(resp => {
    //       self.items = resp.data.indicators;
    //       self.totalItem = resp.data.totalItem;
    //       self.$store.commit("setLoading", false);

    //       // Here we could override the busy state, setting isBusy to false
    //       self.isBusy = false;
    //       //   return items;
    //     })
    //     .catch(err => {
    //       // Here we could override the busy state, setting isBusy to false
    //       self.isBusy = false;
    //       self.$store.commit("setLoading", false);
    //       api.httpMsg(self, err.status, err.data);
    //       // Returning an empty array, allows table to correctly handle busy state in case
    //       // of error
    //       return [];
    //     });
    // },

    onPageSelect(page) {
      this.isBusy = true;
      let self = this;
      self.$store.commit("setLoading", false);
      let promise = api.instance.get(
        "itsek/get-indicator-bychecklist?department=" +
          self.warehouse +
          "&param=&tk=" +
          self.perPage +
          "&pg=" +
          page +
          "&checklistId=" +
          self.$route.params.id
      );

      return promise
        .then((resp) => {
          self.items = resp.data.indicators;
          self.totalItem = resp.data.totalItem;
          self.$store.commit("setLoading", false);

          console.log(self.items);

          // Here we could override the busy state, setting isBusy to false
          self.isBusy = false;
        })
        .catch((err) => {
          // Here we could override the busy state, setting isBusy to false
          self.isBusy = false;
          self.$store.commit("setLoading", false);
          api.httpMsg(self, err.status, err.data);
          // Returning an empty array, allows table to correctly handle busy state in case
          // of error
          return [];
        });
    },
    onSelecteItemToDelete: function (param) {
      this.selectedItem = param;
      this.selectedItemLabel = this.selectedItem.indicatorId;
      this.deleteModal = true;
    },
    onDeleteItem: function () {
      this.$store.commit("setLoading", true);
      api.instance
        .delete(
          "itsek/remove-indicator?indicatorId=" + this.selectedItem.indicatorId
        )
        .then((resp) => {
          this.onfilterChecklist();

          this.deleteModal = false;
          miniToastr.success(resp.data);
          this.$store.commit("setLoading", false);
        })
        .catch((err) => {
          if (err.response.data === "") {
            api.httpMsg(this, err.status, err.data);
          } else {
            miniToastr.error(err.response.data);
          }
          this.$store.commit("setLoading", false);
        });
    },
    //Checlist fileter
    onfilterChecklist: function (val) {
      this.isBusy = true;
      let self = this;
      self.GetChecklistID();
      self.$store.commit("setLoading", true);
      let promise = api.instance.get(
        "itsek/get-indicator-bychecklist?department=" +
          self.warehouse +
          "&param=&tk=" +
          self.perPage +
          "&pg=" +
          self.currentPage +
          "&checklistId=" +
          self.$route.params.id
      );

      return promise
        .then((resp) => {
          self.items = resp.data.indicators;
          self.totalItem = resp.data.totalItem;
          self.$store.commit("setLoading", false);

console.log(self.items);
          self.isBusy = false;
        })
        .catch((err) => {
          self.isBusy = false;
          self.$store.commit("setLoading", false);
          api.httpMsg(self, err.status, err.data);

          return [];
        });
    },
    GetChecklistID: function () {
      let self = this;

      self.$store.commit("setLoading", true);
      self.checklistItem = [];
      api.instance
        .get(
          "itsek/get-chechlist-by-indicator?department=" +
            this.$store.getters.warehousecode
        )
        .then(function (resp) {
          resp.data.forEach((element) => {
            self.checklistItem.push({
              checklistId: element.checklistId,
              checklistdescription: element.checklistdescription,
            });
          });
          self.ChecklistidModal = true;
          self.$store.commit("setLoading", false);
        })
        .catch(function (err) {
          api.httpMsg(self, err.status, err.data);
          self.$store.commit("setLoading", false);
        });
    },
    OnClinkNEwIndicator: function () {
      this.$router.push(
        "/Maintenance/2017042003480096/new/indicator/" + this.$route.params.id
      );
    },
    OnClinkUpdateIndicator: function (val) {
      this.indicatorId = val;
      this.$router.push(
        "/Maintenance/2017042003480096/modify/indicator/" +
          this.indicatorId +
          "/" +
          this.$route.params.id
      );
    },
  },
  watch: {
    saveCounter() {
      this.PostIndicator(this.items[this.saveCounter]);
    },
  },
  created: function () {
    this.onfilterChecklist();
  },
  computed: {
    filterchecklistid: function () {
      var self = this;
      return self.items.filter(function (cust) {
        return (
          cust.checklistdescription
            .toLowerCase()
            .indexOf(self.checklist.toLowerCase()) >= 0
        );
      });
    },
  },
};
</script>
