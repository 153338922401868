<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="12" class="mt-1">
        <div class="dispatch-list row">
          <b-button variant="none" @click="back">
            <i class="fa fa-chevron-left"></i> Back
          </b-button>
        </div>
        <b-card :header="appendtext +' Group Maintenance Setup'">
          <b-alert :show="isShowAlert" variant="success" isShowAlert="false">
            <p>
              {{appendtext}} Save Complete
              <b-button v-show="buttonishow" size="sm" variant="primary" @click="reload">
                <i class="fa fa-plus-circle"></i>&nbsp;Groupcode
              </b-button>
            </p>
          </b-alert>

          <b-form @submit="onSubmit" v-if="!isShowAlert">
            <b-alert :show="errors.length > 0" variant="danger">
              <h6 class="alert-heading">Please fill in all required field!</h6>
              <ul>
                <li v-for="(error,i) in errors" :key="`error-${i}`">{{ error }}</li>
              </ul>
            </b-alert>
            <b-form-group id="exampleInputGroup2" label="Group Code*" label-for="exampleInput2">
              <b-form-input
                id="exampleInput2"
                type="text"
                v-model="form.groupcode"
                placeholder="Group Code"
              ></b-form-input>
            </b-form-group>

            <b-form-group>
              <label for="remarks">Description*</label>
              <b-form-input
                type="text"
                id="remarks"
                v-model="form.description"
                placeholder="Description"
              ></b-form-input>
            </b-form-group>

            <b-form-checkbox-group stacked id="basicCustomCheckboxes" class="form-group col-md-12">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="isactive"
                  value="1"
                  v-model="checked"
                />
                <label class="custom-control-label" for="isactive">is Active</label>
              </div>
            </b-form-checkbox-group>

            <div class="dispatch-table">
              <b-button type="submit" variant="primary">
                <i class="fa fa-save"></i>
                {{appendtext}}
              </b-button>
            </div>
            <div class="dispatch-list">
              <b-button type="submit" variant="primary" class="btn btn-primary btn-sm btn-block">
                <i class="fa fa-save"></i>
                {{appendtext}}
              </b-button>
            </div>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
    <!-- <pre>{{$data.form|JSON}}</pre> -->
  </div>
</template>

<script>
import api from "../../services/api";

export default {
  data() {
    return {
      errors: [],
      isShowAlert: false,
      appendtext: this.$route.params.id == undefined ? "Create" : "Update",
      buttonishow: this.$route.params.id == undefined ? true : false,
      checked: true,
      form: {
        ID: this.$route.params.id == undefined ? "" : this.$route.params.id,
        groupcode: "",
        department: this.$store.getters.selectedWarehouse,
        description: "",
        isactive: 1,

        created_by:
          this.$store.getters.user.firstname +
          " " +
          this.$store.getters.user.lastname,
        create_date: "",
        modify_by:
          this.$store.getters.user.firstname +
          " " +
          this.$store.getters.user.lastname,
        modify_date: ""
      }
    };
  },

  methods: {
    checkForm: function() {
      if (this.form.groupcode && this.form.description) {
        return true;
      } else {
        this.errors = [];

        if (!this.form.groupcode) {
          this.errors.push("Groupcode required.");
        }

        if (!this.form.description) {
          this.errors.push("Description required.");
        }

        return false;
      }
    },
    onSubmit: function(evt) {
      evt.preventDefault();
      if (this.checkForm() == false) {
        return;
      }
      this.$store.commit("setLoading", true);
      if (this.appendtext == "Create") {
        api.instance
          .post("itsek/add-itsek-group", this.form)
          .then(() => {
            this.isShowAlert = true;
            this.$store.commit("setLoading", false);
          })
          .catch(function(err) {
            api.httpMsg(this, err.status, err.data);
            this.$store.commit("setLoading", false);
          });
      } else if (this.appendtext == "Update") {
        api.instance
          .put("itsek/edit-itsek-group", this.form)
          .then(() => {
            this.isShowAlert = true;
            this.$store.commit("setLoading", false);
          })
          .catch(function(err) {
            api.httpMsg(this, err.status, err.data);
            this.$store.commit("setLoading", false);
          });
      }
    },
    back: function() {
      this.$router.push("/Maintenance/636898096947541420");
    },
    reload: function() {
      this.$router.go(this.$router.currentRoute);
    },
    getData: function() {
      let self = this;
      if (self.$route.params.id !== undefined) {
        self.$store.commit("setLoading", true);
        api.instance
          .get("itsek/select-groupcode-id?ID=" + self.$route.params.id)
          .then(resp => {
            self.form = resp.data;

            if (self.form.isactive === 1) {
              self.checked = true;
            } else {
              self.checked = false;
            }
            self.$store.commit("setLoading", false);
          })
          .catch(err => {
            self.$store.commit("setLoading", false);
            api.httpMsg(self, err.status, err.data);
          });
      }
    }
  },
  watch: {
    selectdepartment: function(param) {
      //select groupcode
      this.form.department = param.department;
    },
    checked: function(val) {
      if (val) {
        this.form.isactive = 1;
      } else {
        this.form.isactive = 0;
      }
    }
  },
  created: function() {
    this.getData();
  }
};
</script>
