<template>
<div class="animated fadeIn">
    <b-row>
        <b-col md="12" class="mt-1">
            <div class="dispatch-table">
                <b-tabs content-class="mt-3">
                    <b-tab title="Yes per Checklist" active>
                <b-card id="graphIndicator" ref="graphIndicator">
                    <div slot="header">
                        <span>Percentage YES per Checklist</span>
                    </div>
                    <span>Start Week</span>
                    <b-form-select v-model="yesPerChecklist.startWeek" :options="startWeekOptions"></b-form-select>
                    <span>Start Year</span>
                    <b-form-select v-model="yesPerChecklist.startYear" :options="startYearOptions"></b-form-select>
                    <span>End Week</span>
                    <b-form-select v-model="yesPerChecklist.endWeek" :options="endWeekOptions"></b-form-select>
                    <span>End Year</span>
                    <b-form-select v-model="yesPerChecklist.endYear" :options="endYearOptions"></b-form-select>

                    <center>
                        <b-button style="margin-top:30px;margin-bottom:30px" @click="loadYesPerCheckList" variant="primary">Load Data</b-button>
                    </center>
                    <h6 v-if="arrangedChartData.series.length">Note: Please click colored bars or legend list to view analysis and details.</h6>
                    <div class="scrollable">
                    <bar-chart v-if="arrangedChartData.series.length" @selectLegend="SelectBarData" @selectSeries="SelectBarData" :data="arrangedChartData" :options="options" />
                    </div>
                    <div class="scrollable">
                    <div v-if="table.length">
                        <h2>{{table[0].ChecklistName}}</h2>
                        <h4>Analysis:</h4>
                        <ul style="list-style-type: none;">
                            <li v-for="analyze in yesPerChecklist.analysis" :key="analyze" :class="analyze.class">{{analyze.phrase}}</li>
                        </ul>
                        <b-table striped hover bordered :fields="yesPerChecklist.fields" :busy="isBusy" :items="table.map((x,index)=>{return{'Rank':index+1,'IndicatorDescription': x.IndicatorDescription,'CategoryDescription': x.CategoryDescription,'NumberofNO': x.IncidentCount}})">
                            <template slot="action_button" slot-scope="data">
                                <b-link :key="data.IndicatorDescription" tag="button" @click="PostToPalo" class="btn btn-primary btn-sm btn-square">
                                    <i class="fa fa-pencil-square-o"></i>
                                    &nbsp;Post issue in Paloapp
                                </b-link>&nbsp;
                            </template>
                        </b-table>
                    </div>
                    </div>
                </b-card>
                    </b-tab>
                    <!-- atrisk graph -->
                    <b-tab title="Atrisk behavior">
                        <atrisk></atrisk>
                    </b-tab>
                    <!-- <b-tab title="Correlation">
                        <correlation></correlation>
                    </b-tab> -->
                </b-tabs>
            </div>

            <div class="dispatch-list">
                <!-- <b-card id="graphIndicator" ref="graphIndicator">
                    <div slot="header">
                        <span>Percentage YES per Checklist</span>
                    </div>
                    <span>Start Week</span>
                    <b-form-select v-model="yesPerChecklist.startWeek" :options="startWeekOptions"></b-form-select>
                    <span>Start Year</span>
                    <b-form-select v-model="yesPerChecklist.startYear" :options="startYearOptions"></b-form-select>
                    <span>End Week</span>
                    <b-form-select v-model="yesPerChecklist.endWeek" :options="endWeekOptions"></b-form-select>
                    <span>End Year</span>
                    <b-form-select v-model="yesPerChecklist.endYear" :options="endYearOptions"></b-form-select>

                    <center>
                        <b-button style="margin-top:30px;margin-bottom:30px" @click="loadYesPerCheckList" variant="primary">Load Data</b-button>
                    </center>
                    <h6 v-if="arrangedChartData.series.length">Note: Please click colored bars or legend list to view analysis and details.</h6>
                    <bar-chart v-if="arrangedChartData.series.length" @selectLegend="SelectBarData" @selectSeries="SelectBarData" :data="arrangedChartData" :options="optionsMobile" />
                    <div v-if="table.length">
                        <h2>{{table[0].ChecklistName}}</h2>
                        <h4>Analysis:</h4>
                        <ul style="list-style-type: none;">
                            <li v-for="analyze in yesPerChecklist.analysis" :key="analyze" :class="analyze.class">{{analyze.phrase}}</li>
                        </ul>
                        <b-table responsive striped hover bordered :fields="yesPerChecklist.fields" :busy="isBusy" :items="table.map((x,index)=>{return{'Rank':index+1,'IndicatorDescription': x.IndicatorDescription,'CategoryDescription': x.CategoryDescription,'NumberofNO': x.IncidentCount}})" style="width:350px">
                            <template slot="action_button" slot-scope="data">
                                <b-link :key="data.IndicatorDescription" tag="button" @click="PostToPalo" class="btn btn-primary btn-sm btn-square">
                                    <i class="fa fa-pencil-square-o"></i>
                                    &nbsp;Post issue in Paloapp
                                </b-link>&nbsp;
                            </template>
                        </b-table>
                    </div>
                </b-card> -->
                <b-tabs content-class="mt-3">
                    <b-tab title="Yes per Checklist" active>
                <b-card id="graphIndicator" ref="graphIndicator">
                    <div slot="header">
                        <span>Percentage YES per Checklist</span>
                    </div>
                    <span>Start Week</span>
                    <b-form-select v-model="yesPerChecklist.startWeek" :options="startWeekOptions"></b-form-select>
                    <span>Start Year</span>
                    <b-form-select v-model="yesPerChecklist.startYear" :options="startYearOptions"></b-form-select>
                    <span>End Week</span>
                    <b-form-select v-model="yesPerChecklist.endWeek" :options="endWeekOptions"></b-form-select>
                    <span>End Year</span>
                    <b-form-select v-model="yesPerChecklist.endYear" :options="endYearOptions"></b-form-select>

                    <center>
                        <b-button style="margin-top:30px;margin-bottom:30px" @click="loadYesPerCheckList" variant="primary">Load Data</b-button>
                    </center>
                    <div class="scrollable">
                    <h6 v-if="arrangedChartData.series.length">Note: Please click colored bars or legend list to view analysis and details.</h6>
                    <bar-chart v-if="arrangedChartData.series.length" @selectLegend="SelectBarData" @selectSeries="SelectBarData" :data="arrangedChartData" :options="options" />
                    </div>
                    <div v-if="table.length" class="scrollable">
                        <h2>{{table[0].ChecklistName}}</h2>
                        <h4>Analysis:</h4>
                        <ul style="list-style-type: none;">
                            <li v-for="analyze in yesPerChecklist.analysis" :key="analyze" :class="analyze.class">{{analyze.phrase}}</li>
                        </ul>
                        <b-table striped hover bordered :fields="yesPerChecklist.fields" :busy="isBusy" :items="table.map((x,index)=>{return{'Rank':index+1,'IndicatorDescription': x.IndicatorDescription,'CategoryDescription': x.CategoryDescription,'NumberofNO': x.IncidentCount}})">
                            <template slot="action_button" slot-scope="data">
                                <b-link :key="data.IndicatorDescription" tag="button" @click="PostToPalo" class="btn btn-primary btn-sm btn-square">
                                    <i class="fa fa-pencil-square-o"></i>
                                    &nbsp;Post issue in Paloapp
                                </b-link>&nbsp;
                            </template>
                        </b-table>
                    </div>
                </b-card>
                    </b-tab>
                    <!-- atrisk graph -->
                    <b-tab title="Atrisk behavior">
                        <atrisk class="scrollable"></atrisk>
                    </b-tab>
                    <!-- <b-tab title="Correlation">
                        <correlation></correlation>
                    </b-tab> -->
                </b-tabs>
            </div>
        </b-col>
    </b-row>
</div>
</template>

<script>
import 'tui-chart/dist/tui-chart.css';
import {
    columnChart,
    barChart,
    pieChart
} from '@toast-ui/vue-chart';
import api from '../../services/api';
import atrisk from '../../components/AtriskGraph';
import correlation from '../../components/Correlation';
export default {
    components: {
        'bar-chart': columnChart,
        'atrisk': atrisk,
        'correlation': correlation
    },
    data() {
        return {
            isBusy: false,
            arrangedChartData: { // for 'data' prop of 'bar-chart'

                categories: ['Week1', 'Week2', 'Week3', 'Week4', 'Week5'],
                series: [
                    // {
                    //     name: 'INCOMING VEHICLE INSPECTION CHECKLIST',
                    //     data: [56, 50, 10, 70, 80]
                    // },
                    // {
                    //     name: 'BBSI',
                    //     data: [34, 64, 23, 88, 34]
                    // },
                    // {
                    //     name: 'TECHNICAL',
                    //     data: [23, 61, 34, 90, 88]
                    // },
                    // {
                    //     name: 'DEV',
                    //     data: [46, 74, 96, 34, 13]
                    // }
                ]
            },
            table: [],
            optionsMobile: {
                chart: {
                    width: 350,
                    height: 500,
                    title: this.$store.state.warehousedesc + '(Percentage YES per Checklist)',
                    format: '100'
                },
                legend: {
                    visible: true,
                    align: "bottom"
                },
                xAxis: {
                    max: 35
                },
                series: {
                    showLabel: true,
                    vertical: false,
                }
            },
            options: {
                chart: {
                    width: 1200,
                    height: 500,
                    title: this.$store.state.warehousedesc + '(Percentage YES per Checklist)',
                    format: '100'
                },
                legend: {
                    visible: true
                },
                xAxis: {
                    max: 35
                },
                series: {
                    showLabel: true,
                    vertical: false,
                }
            },
            yesPerChecklist: {
                data: [],
                startWeek: 1,
                endWeek: 1,
                startYear: new Date().getFullYear(),
                endYear: new Date().getFullYear(),
                analysis: [{
                    phrase: [],
                    class: []
                }],
                fields: {
                    Rank: {
                        label: "Rank"
                    },
                    IndicatorDescription: {
                        label: 'Indicator Description'
                    },
                    CategoryDescription: {
                        label: 'Category'
                    },
                    NumberofNO: {
                        label: 'Number of NO'
                    },
                    action_button: {
                        label: 'Suggested Solution'
                    }
                }

            }
        }
    },
    created() {
      this.getCalendar();
        // console.log('user:',this.$store.state.warehousecode);
        //this.loadYesPerCheckList();
    },
    methods: {
        
      getCalendar(){
      let self = this;
      let promise = api.instance.get("/itsek/get-itsek-calendar");

      return promise
        .then(resp => {
          self.$store.state.calendar = resp.data;
          //   return items;
        })
        .catch(error => {
          alert('Error in getting calendara data!');
          // Here we could override the busy state, setting isBusy to false
          //alert('Unable to obtain calendar data!')
          // Returning an empty array, allows table to correctly handle busy state in case
          // of error
          return [];
        });
    },
        PostToPalo() {
            window.open("https://paloapp.fastlogistics.com.ph/", "_blank");
        },
        setAnalysis(value) {
            if (value.legendIndex == undefined) {
                value.legendIndex = value.index;
            }
            this.yesPerChecklist.analysis = [];
            var Subject = this.arrangedChartData.series[value.legendIndex].name;
            var data = this.arrangedChartData.series[value.legendIndex].data;
            var average = Math.round(data.reduce((a, b) => a + b, 0) / data.length);
            var highestrate = Math.max.apply(Math, data);
            var highestrateTime = this.arrangedChartData.categories[data.indexOf(highestrate)];
            var lowestrate = Math.min.apply(Math, data);
            var lowestrateTime = this.arrangedChartData.categories[data.indexOf(lowestrate)];
            var latestDifference = data[data.length - 1] - average;
            this.yesPerChecklist.analysis.push({
                phrase: 'The average rating of ' + Subject + ' from ' + this.arrangedChartData.categories[0] + ' to ' + this.arrangedChartData.categories[this.arrangedChartData.categories.length - 1] + ' is ' + average + '%',
                class: (average >= 80 ? 'hit' : (average < 50 ? 'miss' : 'neutral'))
            });

            this.yesPerChecklist.analysis.push({
                phrase: 'The highest rating is ' + highestrate + '% during ' + highestrateTime,
                class: (highestrate >= 80 ? 'hit' : (highestrate < 50 ? 'miss' : 'neutral'))
            });

            this.yesPerChecklist.analysis.push({
                phrase: 'The lowest rating is ' + lowestrate + '% during ' + lowestrateTime,
                class: (lowestrate >= 80 ? 'hit' : (lowestrate < 50 ? 'miss' : 'neutral'))
            });

            this.yesPerChecklist.analysis.push({
                phrase: 'The last instance (' + this.arrangedChartData.categories[this.arrangedChartData.categories.length - 1] + ') is ' + (latestDifference > 0 ? (latestDifference + '% higher than ') : (latestDifference < 0 ? ((-1 * latestDifference) + '% lower than ') : 'the same as ')) + 'the average rate.',
                class: (latestDifference > 0 ? 'hit' : (latestDifference < 0 ? 'miss' : 'neutral'))
            });
            //this.yesPerChecklist.analysis = 
        },
        onResize() {
            var dis = this;
            window.onresize = function (event) {
                dis.options.chart.width = document.getElementById("graphIndicator").offsetWidth - 300;
                //alert(document.getElementById("graphIndicator").offsetWidth + ' '+dis.options.chart.width);
                //console.log(event.target.innerWidth)
            };
        },
        SelectBarData(value) {
            //console.log('selected:', value);
            this.loadRankedNoPerCheckList(value);
        },
        getWeeks() {

        },
        getChartSeries() {
            var result = [];
            [...new Set(this.yesPerChecklist.data.map(x => x.ChecklistName))].forEach(element => {
                var dataArray = [];
                this.yesPerChecklist.data.filter(x => x.ChecklistName == element).forEach(elementScope => {
                    if (elementScope.TotalIndicator == 0 || elementScope.TotalIndicator == null) {
                        dataArray.push(0);
                    } else {
                        dataArray.push(Number(((elementScope.TotalYes / elementScope.TotalIndicator) * 100).toFixed(0)))
                    }
                })
                result.push({
                    name: element,
                    data: dataArray
                })
            });
            console.log('result:', result);
            return result;
            
        },
        loadYesPerCheckList() {
            
            this.isBusy = true;
            let self = this;
            self.$store.commit("setLoading", true);
            let promise = api.instance.get(
                "Itsek/get-checklist-report-yesperchecklistWeekly?brancode=" +
                self.$store.state.warehousecode +
                "&startYear=" +
                self.yesPerChecklist.startYear +
                "&startWeek=" +
                self.yesPerChecklist.startWeek +
                "&endYear=" +
                self.yesPerChecklist.endYear +
                "&endWeek=" +
                self.yesPerChecklist.endWeek
            );

            return promise
                .then(resp => {
                    self.yesPerChecklist.data = resp.data;
                    self.yesPerChecklist.analysis = [{
                        phrase: [],
                        class: []
                    }];
                    self.table = [];
                    self.$store.commit("setLoading", false);

                    console.log(self.yesPerChecklist.data);
                    // Here we could override the busy state, setting isBusy to false
                    self.isBusy = false;
                    //   return items;
                })
                .catch(error => {
                    // Here we could override the busy state, setting isBusy to false
                    self.isBusy = false;
                    self.$store.commit("setLoading", false);
                    // Returning an empty array, allows table to correctly handle busy state in case of error
                    return [];
                });
        },
        loadRankedNoPerCheckList(value) {
            var checklistName = value.legend;
            this.isBusy = true;
            let self = this;
            self.$store.commit("setLoading", true);
            let promise = api.instance.get(
                "Itsek/get-checklist-report-rankednoperchecklist?brancode=" +
                self.$store.state.warehousecode +
                "&checklistID=" +
                self.yesPerChecklist.data.filter(x => x.ChecklistName == checklistName)[0].ChecklistID
            );

            return promise
                .then(resp => {
                    self.table = resp.data;
                    //if(resp.data.length>0){
                    self.setAnalysis(value);
                    //}
                    console.log('indicators:', self.table);
                    self.$store.commit("setLoading", false);
                    console.log(self.yesPerChecklist.data);
                    // Here we could override the busy state, setting isBusy to false
                    self.isBusy = false;
                    //   return items;
                })
                .catch(error => {
                    // Here we could override the busy state, setting isBusy to false
                    self.isBusy = false;
                    self.$store.commit("setLoading", false);
                    // Returning an empty array, allows table to correctly handle busy state in case of error
                    return [];
                });
        }
    },
    computed: {
        chartData() {
            return {
                categories: [...new Set(this.yesPerChecklist.data.map(x => x.Scope))],
                series: this.getChartSeries()
            }
        },
        containerWidth() {
            return document.getElementById("graphIndicator").offsetWidth;
        },
        Calendar() {
            return this.$store.state.calendar;
        },
        startYearOptions() {
            return Array.from(
                new Set(this.Calendar.map(x => x.analysisyear))
            ).sort(function (a, b) {
                return b - a;
            })
        },
        endYearOptions() {
            return Array.from(
                    new Set(this.Calendar.map(x => x.analysisyear))
                )
                .sort(function (a, b) {
                    return b - a;
                })
                .filter(x => x >= this.yesPerChecklist.startYear)
        },
        startWeekOptions() {
            return Array.from(
                new Set(this.Calendar.filter(y => y.analysisyear == this.yesPerChecklist.startYear).map(x => Number(x.analysisweek)))
            ).sort(function (a, b) {
                return a - b;
            })
        },
        endWeekOptions() {
            return Array.from(
                new Set(
                    this.Calendar.filter(
                        x => x.analysisyear == this.yesPerChecklist.endYear
                    ).map(x => x.analysisweek)
                )
            ).sort(function (a, b) {
                return a - b;
            })
        }
    },
    watch: {
        chartData() {
            this.arrangedChartData = this.chartData;
        },
        containerWidth() {
            console.log('width:', this.containerWidth)
            console.log('char width:', this.options.chart.width)
            this.options.chart.width = this.containerWidth - 150;
        }
    },
    mounted() {
        this.onResize();
    }
}
</script>

<style scoped>
.scrollable{
    overflow:auto;
}
.hit {
    color: green;
}

.miss {
    color: red;
}

.neutral {
    color: #e6b800;
}
</style>
