<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col md="12" class="mt-1">
        <b-card>
          <div slot="header">
            <span>Review Checklist Report</span>
            <a
              href="#"
              class="btn btn-sm btn-info float-right ml-1"
              onclick="javascript:window.print();"
            >
              <i class="fa fa-print"></i> Print
            </a>
            <button class="btn btn-sm btn-info float-right" @click="onexport">Export</button>
          </div>

          <table
            role="table"
            aria-busy="false"
            aria-colcount="4"
            class="table b-table table-sm b-table-fixed b-table-stacked-md show-empty table-bordered"
          >
            <thead class="text-center">
              <tr>
                <th>Item</th>
                <th>Requirements</th>
                <th>Measures</th>
                <th>MAX (OPPORTUNITY)</th>
                <th>Actual (EVENT)</th>
                <th>Remark</th>
              </tr>
            </thead>

            <tbody
              role="rowgroup"
              class
              v-for="(itemsa,quadrantdesk) in byQuandrant"
              :key="quadrantdesk"
            >
              <tr role="row" class>
                <th colspan="6" class="bg-primary">{{quadrantdesk}}</th>
              </tr>
              <tr role="row" class v-for="(item,i) in itemsa" :key="i">
                <td>{{item.itemquant}}</td>
                <td>{{item.requirements}}</td>
                <td>{{item.measures}}</td>
                <td>{{item.max}}</td>
                <td>{{item.actual}}</td>
                <td>{{item.remark}}</td>
              </tr>
            </tbody>
          </table>
        </b-card>
        <b-row>
          <b-col>
            <!-- <img style="width:175px;height:95px;" :src="item.img" /> -->
          </b-col>
        </b-row>

        <b-row class="ml-2 mt-10">
          <b-col>
            <u>
              <strong>Admin</strong>
            </u>
            <div>Prepared by</div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import api from "../../services/api";
import JSONToCSVConvertor from "./../../services/JSONToCSVConvertor.js";
export default {
  data: function() {
    return {
      progressCap: null,
      deleteModal: false,
      selectedItem: {},
      selectedItemLabel: "",
      selectedfile: null,
      perPage: this.$store.getters.perPage,
      totalItem: 0,
      currentPage: 1,

      items: [
        {
          id: "1",
          quadrantdesk: "Improvemnt",
          itemquant: "2",
          requirements: "Key performance",
          measures: "Not all member were able to capture errors",
          max: "4",
          actual: "3",
          remark: ""
        },
        {
          id: "2",
          quadrantdesk: "Improvemnt",
          itemquant: "4",
          requirements: "Key performance",
          measures: "Not all member were able to capture errors",
          max: "2",
          actual: "5",
          remark: ""
        },
        {
          id: "3",
          quadrantdesk: "Good",
          itemquant: "7",
          requirements: "Key performance",
          measures: "Not all member were able to capture errors",
          max: "8",
          actual: "2",
          remark: ""
        }
      ]
    };
  },
  computed: {
    byQuandrant() {
      return this.items.reduce((acc, cat) => {
        (acc[cat.quadrantdesk] = acc[cat.quadrantdesk] || []).push({
          id: cat.id,
          quadrantdesk: cat.quadrantdesk,
          itemquant: cat.itemquant,
          requirements: cat.requirements,
          measures: cat.measures,
          max: cat.max,
          actual: cat.actual,
          remark: cat.remark
        });
        return acc;
      }, {});
    }
  },
  methods: {
    onexport: function() {
      JSONToCSVConvertor(this.items, "CheckList Report", true);
    }
  }
};
</script>
