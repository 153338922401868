<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col md="12">
        <div class="dispatch-table">
          <b-card header>
            <div slot="header">
              <span>Group Code Maintenance List</span>
              <a
                href="#"
                class="btn btn-sm btn-info float-right ml-1"
                id="printpagebutton"
                @click="onPrint"
              >
                <i class="fa fa-print"></i> Print
              </a>
              <button
                id="printexport"
                class="btn btn-sm btn-info float-right"
                @click="onexport"
              >Export</button>
            </div>
            <div class="newButton">
              <b-button
                variant="primary"
                class="btnsm"
                @click="$router.push('/Maintenance/637523750183808272/new/groupcode')"
              >
                <i class="fa fa-plus-circle"></i>&nbsp;New Group Maintenace
              </b-button>
            </div>
            <b-row>
              <b-col md="4" class="my-1">
                <b-form-group label-cols-sm="1" class="mb-0">
                  <b-input-group>
                    <b-form-input v-model="filter" placeholder="Type to Search" />
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>&nbsp;
            <b-table
              show-empty
              stacked="md"
              responsive="sm"
              hover
              small
              striped
              fixed
              head-variant="light"
              :busy.sync="isBusy"
              :items="items"
              :fields="fields"
              :per-page="perPage"
              :filter="filter"
            >
              <template v-slot:cell(action_button)="data">
                <router-link
                  tag="button"
                  :to="'/Maintenance/637523750183808272/modify/groupcode/' + data.item.ID"
                  class="btn btn-primary btn-sm btn-square"
                >
                  <i class="fa fa-pencil-square-o"></i>
                </router-link>&nbsp;
                <b-button
                  size="sm"
                  variant="danger"
                  class="btn btn-danger btn-sm btn-square"
                  @click="onSelecteItemToDelete(data.item)"
                >
                  <i class="fa fa-trash-o"></i>
                </b-button>
              </template>
              <template v-slot:cell(isactive)="data">
                <i
                  :class="data.item.isactive == 1 ?  'activeStatus icon-check active_icon' : 'inactiveStatus icon-close inactive_icon'"
                ></i>
              </template>
            </b-table>
          </b-card>
          <div style="margin-top:5px;">
            <b-pagination
              size="sm"
              :total-rows="totalItem"
              v-model="currentPage"
              :per-page="perPage"
              @input="onPageSelect"
            ></b-pagination>
          </div>
        </div>
        <div class="dispatch-list">
          <div>
            <p>Group Code Maintenance List</p>
          </div>
          <div class="newButton">
            <b-button
              variant="primary"
              class="btnsm"
              @click="$router.push('/Maintenance/636898096947541420/new/groupcode')"
            >
              <i class="fa fa-plus-circle"></i>&nbsp;New Group Maintenace
            </b-button>
          </div>
          <b-list-group v-for="(item,index) in items" :key="`dynlist-${index}`" class="mb-2">
            <b-list-group-item class="flex-column align-items-start">
              <div class="list-field">
                <span class="mb-1 dis">Groupcode:{{item.groupcode}}</span>
              </div>

              <div class="list-field">
                <span class="mb-1 dis">Department:</span>
                <span>{{item.department}}</span>
              </div>
              <div class="list-field">
                <span class="mb-1 dis">Description:</span>
                <span>{{item.description}}</span>
              </div>
              <hr />
              <div class="row">
                <div class="col">
                  <router-link
                    tag="button"
                    :to="'/Maintenance/636898096947541420/modify/groupcode/' + item.ID"
                    class="btn btn-primary btn-sm btn-block"
                  >
                    <span class="text-light">
                      <i class="fa fa-pencil-square-o"></i>
                      Edit
                    </span>
                  </router-link>

                  <b-button
                    class="btn btn-danger btnsm btn-block"
                    @click="onSelecteItemToDelete(item)"
                  >
                    <span class="text-light">
                      <i class="fa fa-trash-o"></i>
                      Remove
                    </span>
                  </b-button>
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
        </div>
        <b-modal title="System Message" v-model="deleteModal" @ok="onDeleteItem">
          Are you sure you want to remove
          <strong>{{selectedItemLabel}} ?</strong>
        </b-modal>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import api from "../../services/api";
import fab from "vue-fab";
import JSONToCSVConvertor from "../../services/JSONToCSVConvertor.js";
import miniToastr from "mini-toastr";
export default {
  data: function() {
    return {
      deleteModal: false,
      selectedItem: {},
      selectedItemLabel: "",

      perPage: this.$store.getters.perPage,
      totalItem: 0,
      currentPage: 1,
      filter: null,
      sortOptions: [],
      isBusy: false,
      fields: [
        {
          key: "action_button",
          label: "Action"
        },
        {
          key: "groupcode",
          label: "Group Code",
          sortable: true
        },
        {
          key: "description"
        },
        {
          key: "isactive",
          label: "Status"
        }
      ],
      items: []
    };
  },
  methods: {
    onPrint: function(evt) {
      evt.preventDefault();
      // this.isHidden = true;
      var printButton = document.getElementById("printpagebutton");
      var printExport = document.getElementById("printexport");
      var breadView = document.getElementById("breadview");
      //Set the print button visibility to 'hidden'
      printButton.style.visibility = "hidden";
      printExport.style.visibility = "hidden";
      breadView.style.visibility = "hidden";
      //Print the page content
      window.print();
      printButton.style.visibility = "visible";
      printExport.style.visibility = "visible";
      breadView.style.visibility = "visible";
    },
    onexport: function() {
      JSONToCSVConvertor(this.items, "GroupCode Maintenance", true);
    },
    initTable() {
      // Here we don't set isBusy prop, so busy state will be handled by table itself
      this.isBusy = true;
      let self = this;
      self.$store.commit("setLoading", true);
      let promise = api.instance.get(
        "itsek/get-itsek-groupcode-list?param=&tk=" +
          self.perPage +
          "&pg=" +
          self.currentPage +
          "&department=" +
          this.$store.getters.warehousecode
      );

      return promise
        .then(resp => {
          self.items = resp.data.listgroupcode;
          self.totalItem = resp.data.totalItem;
          self.$store.commit("setLoading", false);

          // Here we could override the busy state, setting isBusy to false
          self.isBusy = false;
          //   return items;
        })
        .catch(err => {
          // Here we could override the busy state, setting isBusy to false
          self.isBusy = false;
          self.$store.commit("setLoading", false);
          api.httpMsg(self, err.status, err.data);
          // Returning an empty array, allows table to correctly handle busy state in case of error
          return [];
        });
    },
    onSelecteItemToDelete: function(param) {
      this.selectedItem = param;
      this.selectedItemLabel = this.selectedItem.ID;
      this.deleteModal = true;
    },
    onDeleteItem: function() {
      this.$store.commit("setLoading", true);
      api.instance
        .delete("itsek/remove-groupcode-by-id?ID=" + this.selectedItem.ID)
        .then(resp => {
          this.initTable();
          this.deleteModal = false;
          this.$store.commit("setLoading", false);
          miniToastr.success(resp.data);
        })
        .catch(err => {
          if (err.response.data === "") {
            api.httpMsg(this, err.status, err.data);
          } else {
            miniToastr.error(err.response.data);
          }

          this.$store.commit("setLoading", false);
        });
    },
    onPageSelect(page) {
      this.isBusy = true;
      let self = this;
      self.$store.commit("setLoading", true);
      let promise = api.instance.get(
        "itsek/get-itsek-groupcode-list?param=&tk=" +
          self.perPage +
          "&pg=" +
          page +
          "&department=" +
          this.$store.getters.warehousecode
      );

      return promise
        .then(resp => {
          self.items = resp.data.listgroupcode;
          self.totalItem = resp.data.totalItem;
          self.$store.commit("setLoading", false);

          // Here we could override the busy state, setting isBusy to false
          self.isBusy = false;
        })
        .catch(err => {
          // Here we could override the busy state, setting isBusy to false
          self.isBusy = false;
          self.$store.commit("setLoading", false);
          api.httpMsg(self, err.status, err.data);
          // Returning an empty array, allows table to correctly handle busy state in case of error
          return [];
        });
    }
  },
  created: function() {
    this.initTable();
  }
};
</script>

