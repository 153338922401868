<template>
    <p>Maintenance Menu</p>
</template>

<script>
    export default {
        name: "MaintenanceMenu"
    }
</script>

<style scoped>

</style>