<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col md="12" class="mt-1">
        <b-table
          responsive
          striped
          hover
          bordered
          :busy="isBusy"
          :items="utilization.data.map"
          style="width:350px"
        ></b-table>
        <div class="dispatch-table">
          <b-tabs content-class="mt-3">
            <b-tab title="Safety Officer Utilization" active>
              <b-card id="graphIndicator" ref="graphIndicator">
                <div slot="header">
                  <span>Safety Officer Utilization</span>
                </div>
                <span>Start Week</span>
                <b-form-select v-model="utilization.startWeek" :options="startWeekOptions"></b-form-select>
                <span>Start Year</span>
                <b-form-select v-model="utilization.startYear" :options="startYearOptions"></b-form-select>
                <span>End Week</span>
                <b-form-select v-model="utilization.endWeek" :options="endWeekOptions"></b-form-select>
                <span>End Year</span>
                <b-form-select v-model="utilization.endYear" :options="endYearOptions"></b-form-select>
                <span>Chart Type</span>
                <b-form-select v-model="chartType" :options="['Line Chart','Column Chart']"></b-form-select>

                <center>
                  <b-button
                    style="margin-top:30px;margin-bottom:30px"
                    @click="loadutilization('employeename')"
                    variant="primary"
                  >Load Data</b-button>
                </center>
                <bar-chart
                class="scrollable"
                  v-if="arrangedChartData.series.length && chartType=='Column Chart'"
                  :data="arrangedChartData"
                  :options="options"
                />
                <line-chart
                class="scrollable"
                  v-if="arrangedChartData.series.length && chartType=='Line Chart'"
                  :data="arrangedChartData"
                  :options="options"
                />

                <b-table
                class="scrollable"
                  sticky-header
                  responsive
                  striped
                  hover
                  bordered
                  :busy="isBusy"
                  :items="table"
                ></b-table>
              </b-card>
            </b-tab>
            <!-- atrisk graph -->
            <!-- <b-tab title="Atrisk behavior">
                        <atrisk></atrisk>
            </b-tab>-->
            <b-tab title="Site Utilization">
              <b-card id="graphIndicator" ref="graphIndicator">
                <div slot="header">
                  <span>Site Utilization</span>
                </div>
                <span>Start Week</span>
                <b-form-select v-model="utilization.startWeek" :options="startWeekOptions"></b-form-select>
                <span>Start Year</span>
                <b-form-select v-model="utilization.startYear" :options="startYearOptions"></b-form-select>
                <span>End Week</span>
                <b-form-select v-model="utilization.endWeek" :options="endWeekOptions"></b-form-select>
                <span>End Year</span>
                <b-form-select v-model="utilization.endYear" :options="endYearOptions"></b-form-select>
                <span>District</span>
                <b-form-select v-model="selectedDistrict" :options="districtList"></b-form-select>
                <span>Chart Type</span>
                <b-form-select v-model="chartType" :options="['Line Chart','Column Chart']"></b-form-select>

                <center>
                  <b-button
                    style="margin-top:30px;margin-bottom:30px"
                    @click="loadutilization('District')"
                    variant="primary"
                  >Load Data</b-button>
                </center>
                <bar-chart
                class="scrollable"
                  v-if="arrangedChartData.series.length && chartType=='Column Chart'"
                  :data="arrangedChartData"
                  :options="options"
                />
                <line-chart
                class="scrollable"
                  v-if="arrangedChartData.series.length && chartType=='Line Chart'"
                  :data="arrangedChartData"
                  :options="options"
                />

                <b-table
                class="scrollable"
                  sticky-header
                  responsive
                  striped
                  hover
                  bordered
                  :busy="isBusy"
                  :items="table"
                ></b-table>

                <!-- percentage utilization -->
                <bar-chart
                class="scrollable"
                  v-if="arrangedChartDataRating.series.length && chartType=='Column Chart'"
                  :data="arrangedChartDataRating"
                  :options="optionsRating"
                />
                <line-chart
                class="scrollable"
                  v-if="arrangedChartDataRating.series.length && chartType=='Line Chart'"
                  :data="arrangedChartDataRating"
                  :options="optionsRating"
                />

                <b-table
                class="scrollable"
                  sticky-header
                  responsive
                  striped
                  hover
                  bordered
                  :fields="tableRatingFields"
                  :busy="isBusy"
                  :items="tableRating"
                >
                </b-table>
              </b-card>
            </b-tab>

            <b-tab title="Overall Utilization">
              <b-card id="graphIndicator" ref="graphIndicator">
                <div slot="header">
                  <span>Overall Utilization</span>
                </div>
                <span>Start Week</span>
                <b-form-select v-model="utilization.startWeek" :options="startWeekOptions"></b-form-select>
                <span>Start Year</span>
                <b-form-select v-model="utilization.startYear" :options="startYearOptions"></b-form-select>
                <span>End Week</span>
                <b-form-select v-model="utilization.endWeek" :options="endWeekOptions"></b-form-select>
                <span>End Year</span>
                <b-form-select v-model="utilization.endYear" :options="endYearOptions"></b-form-select>
                <span>Chart Type</span>
                <b-form-select v-model="chartType" :options="['Line Chart','Column Chart']"></b-form-select>

                <center>
                  <b-button
                    style="margin-top:30px;margin-bottom:30px"
                    @click="loadutilization('overall')"
                    variant="primary"
                  >Load Data</b-button>
                </center>
                <bar-chart
                class="scrollable"
                  v-if="arrangedChartData.series.length && chartType=='Column Chart'"
                  :data="arrangedChartData"
                  :options="options"
                />
                <line-chart
                class="scrollable"
                  v-if="arrangedChartData.series.length && chartType=='Line Chart'"
                  :data="arrangedChartData"
                  :options="options"
                />

                <b-table
                class="scrollable"
                  sticky-header
                  responsive
                  striped
                  hover
                  bordered
                  :busy="isBusy"
                  :items="table"
                ></b-table>                
              </b-card>
            </b-tab>

            <b-tab title="Checklist Utilization">
              <b-card id="graphIndicator" ref="graphIndicator">
                <div slot="header">
                  <span>Checklist Utilization</span>
                </div>
                  <span class="mt-2">Checklist</span>
            <b-form-select
              v-model="CheckLismod"
              :options="checklistlist"
            ></b-form-select>
                <span>Start Week</span>
                <b-form-select v-model="utilization.startWeek" :options="startWeekOptions"></b-form-select>
                <span>Start Year</span>
                <b-form-select v-model="utilization.startYear" :options="startYearOptions"></b-form-select>
                <span>End Week</span>
                <b-form-select v-model="utilization.endWeek" :options="endWeekOptions"></b-form-select>
                <span>End Year</span>
                <b-form-select v-model="utilization.endYear" :options="endYearOptions"></b-form-select>
                <span>District</span>
                <b-form-select v-model="selectedDistrict" :options="districtList"></b-form-select>
                <span>Chart Type</span>
                <b-form-select v-model="chartType" :options="['Line Chart','Column Chart']"></b-form-select>

                <center>
                  <b-button
                    style="margin-top:30px;margin-bottom:30px"
                    @click="loadutilization('Checklist')"
                    variant="primary"
                  >Load Data</b-button>
                </center>

                <!-- Utilization counter -->
                <bar-chart
                class="scrollable"
                  v-if="arrangedChartData.series.length && chartType=='Column Chart'"
                  :data="arrangedChartData"
                  :options="options"
                />
                <line-chart
                class="scrollable"
                  v-if="arrangedChartData.series.length && chartType=='Line Chart'"
                  :data="arrangedChartData"
                  :options="options"
                />

                <b-table
                class="scrollable"
                  sticky-header
                  responsive
                  striped
                  hover
                  bordered
                  :busy="isBusy"
                  :items="table"
                ></b-table>

                <!-- percentage utilization -->
                <bar-chart
                class="scrollable"
                  v-if="arrangedChartDataRating.series.length && chartType=='Column Chart'"
                  :data="arrangedChartDataRating"
                  :options="options"
                />
                <line-chart
                class="scrollable"
                  v-if="arrangedChartDataRating.series.length && chartType=='Line Chart'"
                  :data="arrangedChartDataRating"
                  :options="options"
                />

                <b-table
                class="scrollable"
                  sticky-header
                  responsive
                  striped
                  hover
                  bordered
                  :fields="tableRatingFields"
                  :busy="isBusy"
                  :items="tableRating"
                ></b-table>

              </b-card>
            </b-tab>
          </b-tabs>
        </div>

        <div class="dispatch-list">
          <b-tabs content-class="mt-3">
            <b-tab title="Safety Officer Utilization" active>
              <b-card id="graphIndicator" ref="graphIndicator">
                <div slot="header">
                  <span>Safety Officer Utilization</span>
                </div>
                <span>Start Week</span>
                <b-form-select v-model="utilization.startWeek" :options="startWeekOptions"></b-form-select>
                <span>Start Year</span>
                <b-form-select v-model="utilization.startYear" :options="startYearOptions"></b-form-select>
                <span>End Week</span>
                <b-form-select v-model="utilization.endWeek" :options="endWeekOptions"></b-form-select>
                <span>End Year</span>
                <b-form-select v-model="utilization.endYear" :options="endYearOptions"></b-form-select>
                <span>Chart Type</span>
                <b-form-select v-model="chartType" :options="['Line Chart','Column Chart']"></b-form-select>

                <center>
                  <b-button
                    style="margin-top:30px;margin-bottom:30px"
                    @click="loadutilization('employeename')"
                    variant="primary"
                  >Load Data</b-button>
                </center>
                <bar-chart
                class="scrollable"
                  v-if="arrangedChartData.series.length && chartType=='Column Chart'"
                  :data="arrangedChartData"
                  :options="options"
                />
                <line-chart
                class="scrollable"
                  v-if="arrangedChartData.series.length && chartType=='Line Chart'"
                  :data="arrangedChartData"
                  :options="options"
                />

                <b-table
                class="scrollable"
                  sticky-header
                  responsive
                  striped
                  hover
                  bordered
                  :busy="isBusy"
                  :items="table"
                ></b-table>
              </b-card>
            </b-tab>
            <!-- atrisk graph -->
            <!-- <b-tab title="Atrisk behavior">
                        <atrisk></atrisk>
            </b-tab>-->
            <b-tab title="Site Utilization">
              <b-card id="graphIndicator" ref="graphIndicator">
                <div slot="header">
                  <span>Site Utilization</span>
                </div>
                <span>Start Week</span>
                <b-form-select v-model="utilization.startWeek" :options="startWeekOptions"></b-form-select>
                <span>Start Year</span>
                <b-form-select v-model="utilization.startYear" :options="startYearOptions"></b-form-select>
                <span>End Week</span>
                <b-form-select v-model="utilization.endWeek" :options="endWeekOptions"></b-form-select>
                <span>End Year</span>
                <b-form-select v-model="utilization.endYear" :options="endYearOptions"></b-form-select>
                <span>District</span>
                <b-form-select v-model="selectedDistrict" :options="districtList"></b-form-select>
                <span>Chart Type</span>
                <b-form-select v-model="chartType" :options="['Line Chart','Column Chart']"></b-form-select>

                <center>
                  <b-button
                    style="margin-top:30px;margin-bottom:30px"
                    @click="loadutilization('District')"
                    variant="primary"
                  >Load Data</b-button>
                </center>

                <!-- Utilization counter -->
                <bar-chart
                class="scrollable"
                  v-if="arrangedChartData.series.length && chartType=='Column Chart'"
                  :data="arrangedChartData"
                  :options="options"
                />
                <line-chart
                class="scrollable"
                  v-if="arrangedChartData.series.length && chartType=='Line Chart'"
                  :data="arrangedChartData"
                  :options="options"
                />

                <b-table
                class="scrollable"
                  sticky-header
                  responsive
                  striped
                  hover
                  bordered
                  :busy="isBusy"
                  :items="table"
                ></b-table>

                <!-- percentage utilization -->
                <bar-chart
                class="scrollable"
                  v-if="arrangedChartData.series.length && chartType=='Column Chart'"
                  :data="arrangedChartData"
                  :options="options"
                />
                <line-chart
                class="scrollable"
                  v-if="arrangedChartData.series.length && chartType=='Line Chart'"
                  :data="arrangedChartData"
                  :options="options"
                />

                <b-table
                class="scrollable"
                  sticky-header
                  responsive
                  striped
                  hover
                  bordered
                  :busy="isBusy"
                  :items="table"
                ></b-table>

              </b-card>
            </b-tab>

            <b-tab title="Overall Utilization">
              <b-card id="graphIndicator" ref="graphIndicator">
                <div slot="header">
                  <span>Overall Utilization</span>
                </div>
                <span>Start Week</span>
                <b-form-select v-model="utilization.startWeek" :options="startWeekOptions"></b-form-select>
                <span>Start Year</span>
                <b-form-select v-model="utilization.startYear" :options="startYearOptions"></b-form-select>
                <span>End Week</span>
                <b-form-select v-model="utilization.endWeek" :options="endWeekOptions"></b-form-select>
                <span>End Year</span>
                <b-form-select v-model="utilization.endYear" :options="endYearOptions"></b-form-select>
                <span>Chart Type</span>
                <b-form-select v-model="chartType" :options="['Line Chart','Column Chart']"></b-form-select>

                <center>
                  <b-button
                    style="margin-top:30px;margin-bottom:30px"
                    @click="loadutilization('overall')"
                    variant="primary"
                  >Load Data</b-button>
                </center>
                <bar-chart
                class="scrollable"
                  v-if="arrangedChartData.series.length && chartType=='Column Chart'"
                  :data="arrangedChartData"
                  :options="options"
                />
                <line-chart
                class="scrollable"
                  v-if="arrangedChartData.series.length && chartType=='Line Chart'"
                  :data="arrangedChartData"
                  :options="options"
                />

                <b-table
                class="scrollable"
                  sticky-header
                  responsive
                  striped
                  hover
                  bordered
                  :busy="isBusy"
                  :items="table"
                ></b-table>
              </b-card>
            </b-tab>
          </b-tabs>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import "tui-chart/dist/tui-chart.css";
import { columnChart, lineChart } from "@toast-ui/vue-chart";
import api from "../../services/api";
import atrisk from "../../components/AtriskGraph";

export default {
  components: {
    "bar-chart": columnChart,
    "line-chart": lineChart,
    atrisk: atrisk
  },
  data() {
    return {
      chartType: "Column Chart",
      selectedFilterMode: "",
      checklistlist: [],
      CheckLismod: null,
      chartData: {},
      chartDataRating:{},
      officerUtil: {
        dataTable: []
      },
      isBusy: false,
      selectedDistrict: null,
      arrangedChartDataRating:{
        categories: [],
        series: []
      },
      arrangedChartData: {
        // for 'data' prop of 'bar-chart'

        categories: ["Week1", "Week2", "Week3", "Week4", "Week5"],
        series: [
          // {
          //     name: 'INCOMING VEHICLE INSPECTION CHECKLIST',
          //     data: [56, 50, 10, 70, 80]
          // },
          // {
          //     name: 'BBSI',
          //     data: [34, 64, 23, 88, 34]
          // },
          // {
          //     name: 'TECHNICAL',
          //     data: [23, 61, 34, 90, 88]
          // },
          // {
          //     name: 'DEV',
          //     data: [46, 74, 96, 34, 13]
          // }
        ]
      },
      table: [],
      tableRating:[],
      tableRatingFields:[],
      optionsMobile: {
        chart: {
          width: 350,
          height: 500,
          title: "Utilization",
          format: "100"
        },
        legend: {
          visible: true,
          align: "bottom"
        },
        xAxis: {
          max: 35
        },
        series: {
          showLabel: true,
          vertical: false
        }
      },
      options: {
        chart: {
          width: 1000,
          height: 500,
          title: "Utilization",
          format: "100"
        },
        legend: {
          align: "right",
          visible: true
        },
        xAxis: {
          max: 35
        },
        series: {
          showLabel: true,
          vertical: false
        }
      },
      optionsRating: {
        chart: {
          width: 1000,
          height: 500,
          title: "Utilization Percentage",
          format: "100"
        },
        legend: {
          align: "right",
          visible: true
        },
        xAxis: {
          max: 35
        },
        series: {
          showLabel: true,
          vertical: false
        }
      },
      utilization: {
        ratingData:[],
        data: [],
        startWeek: 1,
        endWeek: 1,
        startYear: new Date().getFullYear(),
        endYear: new Date().getFullYear(),
        analysis: [
          {
            phrase: [],
            class: []
          }
        ],
        fields: {
          // Rank: {
          //     label: "Rank"
          // },
          employeename: {
            label: "employeename"
          },
          scope: {
            label: "Week #"
          },
          counts: {
            label: "counts"
          }
        }
      }
    };
  },
  created() {
        this.getchecklists();
    this.getCalendar();

    // console.log('user:',this.$store.state.warehousecode);
    //this.loadutilization();
  },
  methods: {
    getCalendar() {
      let self = this;
      let promise = api.instance.get("/itsek/get-itsek-calendar");

      return promise
        .then(resp => {
          self.$store.state.calendar = resp.data;
          //   return items;
        })
        .catch(error => {
          alert("Error in getting calendara data!");
          // Here we could override the busy state, setting isBusy to false
          //alert('Unable to obtain calendar data!')
          // Returning an empty array, allows table to correctly handle busy state in case
          // of error
          return [];
        });
    },
    PostToPalo() {
      window.open("https://paloapp.fastlogistics.com.ph/", "_blank");
    },
    onResize() {
      var dis = this;
      window.onresize = function(event) {
        dis.options.chart.width =
          document.getElementById("graphIndicator").offsetWidth - 300;
        //alert(document.getElementById("graphIndicator").offsetWidth + ' '+dis.options.chart.width);
        //console.log(event.target.innerWidth)
      };
    },
    SelectBarData(value) {
      //console.log('selected:', value);
      this.loadRankedNoPerCheckList(value);
    },
    getWeeks() {},
    getChartSeriesRating() {
      var result = [];
      var dis = this;
      if (dis.selectedFilterMode == "employeename") {
        [...new Set(dis.utilization['ratingData'].map(x => x.employeename))].forEach(
          element => {
            var dataArray = [];
            var elementScope = [
              ...new Set(
                dis.utilization['ratingData'].filter(x => x.employeename == element)
              )
            ][0];
            Object.keys(elementScope).forEach(tableproperty => {
              if (tableproperty !== "employeename") {
                dataArray.push(elementScope[tableproperty]);
              }
            });

            result.push({
              name: element,
              data: dataArray
            });
          }
        );
      }
      if (dis.selectedFilterMode == "District") {
        [...new Set(dis.utilization['ratingData'].map(x => x.Site))].forEach(element => {
          var dataArray = [];
          var elementScope = [
            ...new Set(dis.utilization['ratingData'].filter(x => x.Site == element))
          ][0];
          Object.keys(elementScope).forEach(tableproperty => {
            if (tableproperty !== "Site") {
              dataArray.push((elementScope[tableproperty]=='0/0')?0:eval((elementScope[tableproperty])*100).toFixed(2));
            }
          });

          result.push({
            name: element,
            data: dataArray
          });
        });
      }
      if (dis.selectedFilterMode == "overall") {
        [...new Set(dis.utilization['ratingData'].map(x => x.District))].forEach(
          element => {
            var dataArray = [];
            var elementScope = [
              ...new Set(
                dis.utilization['ratingData'].filter(x => x.District == element)
              )
            ][0];
            Object.keys(elementScope).forEach(tableproperty => {
              if (tableproperty !== "District") {
                dataArray.push(eval(elementScope[tableproperty]));
              }
            });

            result.push({
              name: element,
              data: dataArray
            });
          }
        );
      }

     if (dis.selectedFilterMode == "Checklist") {
        [...new Set(dis.utilization['ratingData'].map(x => x.Site))].forEach(element => {
          var dataArray = [];
          var da = [];
          var elementScope = [
            ...new Set(dis.utilization['ratingData'].filter(x => x.Site == element))
          ][0];
          Object.keys(elementScope).forEach(tableproperty => {
            if (tableproperty !== "Site") {
              dataArray.push((elementScope[tableproperty]=='0/0')?0:eval(elementScope[tableproperty])*100);
            }
          });

        // for(var i=0; i > dataArray.length; i++) {
        //   da.push({
        //     d: dataArray[i].toFixed(2)
        //     })
        // }
          result.push({
            name: element,
            data: dataArray
          });
        });
      }

      console.log("result:", result);
      return result;
    },
    getChartSeries() {
      var result = [];
      var dis = this;
      if (dis.selectedFilterMode == "employeename") {
        [...new Set(dis.utilization['data'].map(x => x.employeename))].forEach(
          element => {
            var dataArray = [];
            var elementScope = [
              ...new Set(
                dis.utilization['data'].filter(x => x.employeename == element)
              )
            ][0];
            Object.keys(elementScope).forEach(tableproperty => {
              if (tableproperty !== "employeename") {
                dataArray.push(elementScope[tableproperty]);
              }
            });

            result.push({
              name: element,
              data: dataArray
            });
          }
        );
      }
      if (dis.selectedFilterMode == "District") {
        [...new Set(dis.utilization['data'].map(x => x.Site))].forEach(element => {
          var dataArray = [];
          var elementScope = [
            ...new Set(dis.utilization['data'].filter(x => x.Site == element))
          ][0];
          Object.keys(elementScope).forEach(tableproperty => {
            if (tableproperty !== "Site") {
              dataArray.push(elementScope[tableproperty]);
            }
          });

          result.push({
            name: element,
            data: dataArray
          });
        });
      }
      if (dis.selectedFilterMode == "overall") {
        [...new Set(dis.utilization['data'].map(x => x.District))].forEach(
          element => {
            var dataArray = [];
            var elementScope = [
              ...new Set(
                dis.utilization['data'].filter(x => x.District == element)
              )
            ][0];
            Object.keys(elementScope).forEach(tableproperty => {
              if (tableproperty !== "District") {
                dataArray.push(elementScope[tableproperty]);
              }
            });

            result.push({
              name: element,
              data: dataArray
            });
          }
        );
      }

          if (dis.selectedFilterMode == "Checklist") {
        [...new Set(dis.utilization['data'].map(x => x.Site))].forEach(element => {
          var dataArray = [];
          var elementScope = [
            ...new Set(dis.utilization['data'].filter(x => x.Site == element))
          ][0];
          Object.keys(elementScope).forEach(tableproperty => {
            if (tableproperty !== "Site") {
              dataArray.push(elementScope[tableproperty]);
            }
          });

          result.push({
            name: element,
            data: dataArray
          });
        });
      }

      console.log("result:", result);
      return result;
    },
      getchecklists() {
      let self = this;
      self.$store.commit("setLoading", true);

      let promise = api.instance.get(
        "/itsek/get-checklist-for-other?department=" +
          self.$store.getters.warehousecode
      );

      return promise.then((resp) => {
        for (var i = 0; i < resp.data.length; i++) {
         self.checklistlist.push(resp.data[i].checklistdescription) 
          
        }

        console.log('cheklc', this.checklistlist);
        self.$store.commit("setLoading", false);
      });
    },

    loadutilization(scope) {
      var requestString = "";
      this.selectedFilterMode = scope;
      if (scope == "District") {
        requestString =
          "scope=district&district=" +
          this.$store.state.access.filter(
            x => x.name == this.selectedDistrict
          )[0].id +
          "&";
      }

        if (scope == "Checklist") {
        requestString =
          "scope=checklist&district=" +
          this.$store.state.access.filter(
            x => x.name == this.selectedDistrict
          )[0].id +
          "&checkdesc=" + this.CheckLismod + "&";
      }

      if (scope == "overall") {
        requestString = "scope=overall&";
      }
      this.isBusy = true;
      let self = this;
      self.$store.commit("setLoading", true);
      let promise = api.instance.get(
        "Itsek/get-itsek-util-officer?" +
          requestString +
          "startYear=" +
          self.utilization.startYear +
          "&startWeek=" +
          self.utilization.startWeek +
          "&endYear=" +
          self.utilization.endYear +
          "&endWeek=" +
          self.utilization.endWeek
      );

      return promise
        .then(resp => {
          console.log('raw:',resp.data)
          self.utilization.data = resp.data.seriesData;
          self.utilization.ratingData = resp.data.rating;

          self.utilization.analysis = [
            {
              phrase: [],
              class: []
            }
          ];
          console.log("Data", self.utilization.data);
          self.table = [];
          self.table = resp.data.seriesData;
          self.tableRating = resp.data.rating;

          self.table = self.table.sort(function(a, b) {
            return b.Employeename - a.Employeename;
          });
          self.tableRating = self.tableRating.sort(function(a, b) {
            return b.Employeename - a.Employeename;
          });
          // console.log(self.utilization.data);
          self.changeCategory();
          // Here we could override the busy state, setting isBusy to false
          self.$store.commit("setLoading", false);

          self.isBusy = false;
          //   return items;
        })
        .catch(error => {
          // Here we could override the busy state, setting isBusy to false
          self.isBusy = false;
          self.$store.commit("setLoading", false);
          // Returning an empty array, allows table to correctly handle busy state in case of error
          return [];
        });
    },
    loadRankedNoPerCheckList(value) {
      var checklistName = value.legend;
      this.isBusy = true;
      let self = this;
      self.$store.commit("setLoading", true);
      let promise = api.instance.get(
        "Itsek/get-checklist-report-rankednoperchecklist?brancode=" +
          self.$store.state.warehousecode +
          "&checklistID=" +
          self.utilization.data.filter(x => x.ChecklistName == checklistName)[0]
            .ChecklistID
      );

      return promise
        .then(resp => {
          self.table = resp.data;
          //if(resp.data.length>0){
          // self.setAnalysis(value);
          //}
          console.log("indicators:", self.table);
          self.$store.commit("setLoading", false);
          console.log(self.utilization.data);
          // Here we could override the busy state, setting isBusy to false
          self.isBusy = false;
          //   return items;
        })
        .catch(error => {
          // Here we could override the busy state, setting isBusy to false
          self.isBusy = false;
          self.$store.commit("setLoading", false);
          // Returning an empty array, allows table to correctly handle busy state in case of error
          return [];
        });
    },
    changeCategory() {
        var cat = [...new Set(Object.keys(this.utilization.data[0]))];
        var catRating =[];

      if (this.selectedFilterMode == "employeename") {
        cat.splice(
          cat.findIndex(x => x == "employeename"),
          1
        );
        this.chartData = {
          categories: cat,
          series: this.getChartSeries()
        };
        
      }
      if (this.selectedFilterMode == "District") {
        catRating= [...new Set(Object.keys(this.utilization.ratingData[0]))];
        cat.splice(
          cat.findIndex(x => x == "Site"),
          1
        );
        this.chartData = {
          categories: cat,
          series: this.getChartSeries()
        };
        this.chartDataRating = {
          categories:cat,
          series: this.getChartSeriesRating()
        }
      }
      if (this.selectedFilterMode == "overall") {
        catRating= [...new Set(Object.keys(this.utilization.ratingData[0]))];
        
        cat.splice(
          cat.findIndex(x => x == "District"),
          1
        );
        this.chartData = {
          categories: cat,
          series: this.getChartSeries()
        };
      }
       if (this.selectedFilterMode == "Checklist") {
        catRating= [...new Set(Object.keys(this.utilization.ratingData[0]))];
        cat.splice(
          cat.findIndex(x => x == "Site"),
          1
        );
        this.chartData = {
          categories: cat,
          series: this.getChartSeries()
        };
        this.chartDataRating = {
          categories:cat,
          series: this.getChartSeriesRating()
        }
      }
    }
  },
  computed: {
    districtList() {
      return this.$store.state.access.map(x => x.name);
    },
    // chartData() {
    //     return {
    //         categories: [...new Set(this.utilization.data.map(x => x.District))],
    //         series: this.getChartSeries()
    //     }
    // },
    containerWidth() {
      return document.getElementById("graphIndicator").offsetWidth;
    },
    Calendar() {
      return this.$store.state.calendar;
    },
    startYearOptions() {
      return Array.from(new Set(this.Calendar.map(x => x.analysisyear))).sort(
        function(a, b) {
          return b - a;
        }
      );
    },
    endYearOptions() {
      return Array.from(new Set(this.Calendar.map(x => x.analysisyear)))
        .sort(function(a, b) {
          return b - a;
        })
        .filter(x => x >= this.utilization.startYear);
    },
    startWeekOptions() {
      return Array.from(
        new Set(
          this.Calendar.filter(
            y => y.analysisyear == this.utilization.startYear
          ).map(x => Number(x.analysisweek))
        )
      ).sort(function(a, b) {
        return a - b;
      });
    },
    endWeekOptions() {
      return Array.from(
        new Set(
          this.Calendar.filter(
            x => x.analysisyear == this.utilization.endYear
          ).map(x => x.analysisweek)
        )
      ).sort(function(a, b) {
        return a - b;
      });
    }
  },
  watch: {
    tableRating(){
      // if(this.tableRating.length>0){
//  this.tableRatingFields= Object.keys(this.tableRating[0]);
//       }
//     else{
//       this.tableRatingFields=[];
//     }
      this.tableRating.forEach(element => {
        Object.keys(element).forEach(keys => {
          if(keys!='Site'){
            element[keys] = element[keys].split('/')[1]==0?'No Maintained Frequency':(eval(element[keys])*100).toString()+'%'
          }
        });
      });

    },
    utilization() {
      // this.officerUtil.dataTable =
      // var returns = [...new Set(this.utilization.map(x=>x.scope))];
      //    console.log('fields:', returns);
    },
    chartData() {
      console.log("change  chart:", this.chartData);
      this.arrangedChartData = this.chartData;
      this.arrangedChartDataRating = this.chartDataRating;
      
    },
    containerWidth() {
      console.log("width:", this.containerWidth);
      console.log("char width:", this.options.chart.width);
      this.options.chart.width = this.containerWidth - 150;
    }
  },
  mounted() {
    this.onResize();
  }
};
</script>

<style scoped>
.scrollable{
    overflow:auto;
}
.hit {
  color: green;
}

.miss {
  color: red;
}

.neutral {
  color: #e6b800;
}
</style>
