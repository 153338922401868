<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="12" class="mt-1">
        <div class="dispatch-list row">
          <b-button variant="none" @click="back">
            <i class="fa fa-chevron-left"></i> Back
          </b-button>
        </div>
        <b-card :header="appendtext +' Checklist Indicator Setup'">
          <b-alert :show="isShowAlert" variant="success" @dismissed="isShowAlert=false">
            <p>
              {{appendtext}} Save Complete
              <b-button v-show="buttonishow" size="sm" variant="primary" @click="reload">
                <i class="fa fa-plus-circle"></i>&nbsp;Checklist
              </b-button>
            </p>
          </b-alert>

          <b-form @submit="onSubmit" v-if="!isShowAlert">
            <b-alert :show="errors.length > 0" variant="danger">
              <h6 class="alert-heading">Please fill in all required field!</h6>
              <ul>
                <li v-for="(error,i) in errors" :key="`error-${i}`">{{ error }}</li>
              </ul>
            </b-alert>
            <b-form-group id="exampleInputGroup1" label="Checklist*">
              <input
                type="text"
                class="form-control bg-white"
                readonly
                v-model="ChecklistDescription"
                @click="GetChecklistID"
              />
            </b-form-group>

            <b-form-group id="exampleInputGroup1" label="Indicator*">
              <input
                type="text"
                class="form-control bg-white"
                readonly
                v-model="IndicatorDescription"
                @click="Getindicatorid"
              />
            </b-form-group>

            <b-form-group id="exampleInputGroup2" label="CheckList Alias ">
              <b-form-input
                id="plateno"
                type="text"
                v-model="form.checklistalias"
                placeholder="CheckList Alias"
              ></b-form-input>
            </b-form-group>
            <b-form-checkbox-group stacked id="basicCustomCheckboxes" class="form-group col-md-12">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="isrequired"
                  value="1"
                  v-model="checkedrequired"
                />
                <label class="custom-control-label" for="isrequired">is Required</label>
              </div>
            </b-form-checkbox-group>
            <b-form-checkbox-group stacked id="basicCustomCheckboxes" class="form-group col-md-12">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="isactive"
                  value="1"
                  v-model="checked"
                />
                <label class="custom-control-label" for="isactive">is Active</label>
              </div>
            </b-form-checkbox-group>

            <div class="dispatch-table">
              <b-button type="submit" variant="primary">
                <i class="fa fa-save"></i>
                {{appendtext}}
              </b-button>
            </div>
            <div class="dispatch-list">
              <b-button type="submit" variant="primary" class="btn btn-primary btn-sm btn-block">
                <i class="fa fa-save"></i>
                {{appendtext}}
              </b-button>
            </div>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
    <b-modal v-model="ChecklistidModal" title="Select Checklist" ok-title="Save">
      <input type="Text" placeholder="Search" v-model="Searchchecklistid" class="form-control mb-2" />
      <span v-if="filterchecklistid.length == 0">No available Checklist.</span>
      <form class="form">
        <div style="height:50vh; overflow-x:auto;">
          <b-list-group>
            <b-list-group-item v-for="(item,index) in filterchecklistid" :key="index">
              <b-form-radio
                v-model="ChecklistModel"
                name="some-radios"
                :value="item"
                @change="onSelectChecklist"
              >{{item.checklistdescription}}</b-form-radio>
            </b-list-group-item>
          </b-list-group>
          {{ChecklistModel}}
        </div>
      </form>
    </b-modal>
    <b-modal v-model="IndicatoridModal" title="Select Indicator" ok-title="Save">
      <input type="Text" placeholder="Search" v-model="Searchindicatorid" class="form-control mb-2" />
      <span v-if="filterIndicatorid.length == 0">No available IndicatorId.</span>
      <form class="form">
        <div style="height:50vh; overflow-x:auto;">
          <b-list-group>
            <b-list-group-item v-for="(item,index) in filterIndicatorid" :key="index">
              <b-form-radio
                v-model="IndicatoridModel"
                name="some-radios"
                :value="item"
                @change="onSelectIndicator"
              >{{item.description}}</b-form-radio>
            </b-list-group-item>
          </b-list-group>
        </div>
      </form>
    </b-modal>
    <!-- <pre>{{$data|JSON}}</pre> -->
  </div>
</template>

<script>
import api from "../../services/api";

export default {
  data() {
    return {
      errors: [],
      isShowAlert: false,
      indicatoroptions: [],
      IndicatorDescription: "",
      checklistIdoptions: [],
      ChecklistidModal: false,
      IndicatoridModal: false,
      ChecklistModel: "",
      ChecklistDescription: "",
      IndicatoridModel: "",
      Searchindicatorid: "",
      Searchchecklistid: "",
      GroupidModel: "",

      selectIndicatorid: {},
      appendtext: this.$route.params.id == undefined ? "Create" : "Update",
      buttonishow: this.$route.params.id == undefined ? true : false,
      checkedrequired: false,
      checked: false,
      form: {
        checklistindicatorId:
          this.$route.params.id == undefined ? "" : this.$route.params.id,

        indicatorId: "",
        checklistalias: "",
        isrequired: 0,
        status: "",
        checklistId: "",
        created_by:
          this.$store.getters.user.firstname +
          " " +
          this.$store.getters.user.lastname,
        create_date: "2017-05-11T14:35:31"
      }
    };
  },
  computed: {
    filterchecklistid: function() {
      var self = this;
      return self.checklistIdoptions.filter(function(cust) {
        return (
          cust.checklistdescription
            .toLowerCase()
            .indexOf(self.Searchchecklistid.toLowerCase()) >= 0
        );
      });
    },
    filterIndicatorid: function() {
      var self = this;
      return self.indicatoroptions.filter(function(cust) {
        return (
          cust.description
            .toLowerCase()
            .indexOf(self.Searchindicatorid.toLowerCase()) >= 0
        );
      });
    }
  },
  methods: {
    onSelectChecklist: function(val) {
      let self = this;
      self.form.checklistId = val.checklistId;
      self.ChecklistDescription = val.checklistdescription;
    },
    onSelectIndicator: function(param) {
      let self = this;
      self.form.indicatorId = param.indicatorId;
      self.IndicatorDescription = param.description;
    },
    checkForm: function(e) {
      if (this.form.checklistId && this.form.indicatorId) {
        return true;
      }

      this.errors = [];
      if (!this.form.checklistId) {
        this.errors.push("ChecklistId required.");
      }
      if (!this.form.IndicatorId) {
        this.errors.push("IndicatorId required");
      }

      e.preventDefault();
    },
    onSubmit: function(evt) {
      evt.preventDefault();
      if (!this.checkForm()) {
        return;
      }
      this.$store.commit("setLoading", true);
      if (this.appendtext == "Create") {
        api.instance
          .post("itsek/add-checklist-indicator", this.form)
          .then(() => {
            this.isShowAlert = true;
            this.$store.commit("setLoading", false);
          })
          .catch(function(err) {
            api.httpMsg(this, err.status, err.data);
            this.$store.commit("setLoading", false);
          });
      } else if (this.appendtext == "Update") {
        api.instance
          .put("itsek/edit-checklist-indicator", this.form)
          .then(() => {
            this.isShowAlert = true;
            this.$store.commit("setLoading", false);
          })
          .catch(function(err) {
            api.httpMsg(this, err.status, err.data);
            this.$store.commit("setLoading", false);
          });
      }
    },
    back: function() {
      this.$router.push("/Maintenance/2017042003511326t");
    },
    reload: function() {
      this.$router.go(this.$router.currentRoute);
    },
    getData: function() {
      let self = this;

      if (this.$route.params.id !== undefined) {
        self.$store.commit("setLoading", true);
        api.instance
          .get(
            "itsek/get-checklist-indicator-by-id?checklistindicatorId=" +
              this.$route.params.id
          )
          .then(resp => {
            self.form = resp.data;

            self.ChecklistDescription = resp.data.ChecklistDescription;
            self.IndicatorDescription = resp.data.IndicatorDescription;
            if (self.form.status === "A") {
              self.checked = true;
            } else {
              self.checked = false;
            }
            if (self.form.isrequired === true) {
              self.checkedrequired = true;
            } else {
              self.checkedrequired = false;
            }
            self.$store.commit("setLoading", false);
          })
          .catch(err => {
            self.$store.commit("setLoading", false);
            api.httpMsg(self, err.status, err.data);
          });
      }
    },
    GetChecklistID: function() {
      let self = this;

      self.$store.commit("setLoading", true);
      self.checklistIdoptions = [];
      api.instance
        .get(
          "itsek/get-chechlist-by-department?department=" +
            this.$store.getters.warehousecode
        )
        .then(function(resp) {
          resp.data.forEach(element => {
            self.checklistIdoptions.push({
              checklistId: element.checklistId,
              checklistdescription: element.checklistdescription
            });
          });
          self.ChecklistidModal = true;
          self.$store.commit("setLoading", false);
        })
        .catch(function(err) {
          api.httpMsg(self, err.status, err.data);
          self.$store.commit("setLoading", false);
        });
    },
    Getindicatorid: function() {
      let self = this;
      self.$store.commit("setLoading", true);
      self.indicatoroptions = [];
      api.instance
        .get(
          "itsek/get-indicator-by-department?department=" +
            this.$store.getters.warehousecode
        )
        .then(function(resp) {
          resp.data.forEach(element => {
            self.indicatoroptions.push({
              indicatorId: element.indicatorId,
              description: element.description
            });
          });

          self.IndicatoridModal = true;
          self.$store.commit("setLoading", false);
        })
        .catch(function(err) {
          api.httpMsg(self, err.status, err.data);
          self.$store.commit("setLoading", false);
        });
    }
  },
  created: function() {
    this.getData();
  },
  watch: {
    checked: function(val) {
      if (val) {
        this.form.status = "A";
      } else {
        this.form.status = "I";
      }
    },
    checkedrequired: function(val) {
      if (val) {
        this.form.isrequired = 1;
      } else {
        this.form.isrequired = 0;
      }
    }
  }
};
</script>
