<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center loginForm">
        <div class="form-elegant">
          <div class="card border-none">
            <div class="card-body mx-4">
              <b-form>
                <div class="dispatch-list">
                  <b-row>
                    <div class="navbar-brand mx-auto">
                      <img
                        src="../assets/img/logo.png"
                        class="mr-md-3 animated zoomIn imgicon"
                        data-toggle="tooltip"
                        id="WFMS"
                        style="height: 100px; width: 100px"
                      />
                    </div>
                  </b-row>
                </div>

                <div class="row my-3">
                  <div class="dispatch-table">
                    <!--Itsek-->
                    <img
                      src="../assets/img/itsek.png"
                      class="mr-md-3 animated zoomIn imgicon"
                      data-toggle="tooltip"
                      id="itsek"
                    />

                    <img
                      src="../assets/img/paloup.png"
                      class="mr-md-3 animated zoomIn imgicon"
                      data-toggle="tooltip"
                      id="paloup"
                    />
                    <!--PISO-->
                    <img
                      src="../assets/img/piso.png"
                      class="mr-md-3 animated zoomIn imgicon"
                      data-toggle="tooltip"
                      id="piso"
                    />

                    <img
                      src="../assets/img/pism.png"
                      class="mr-md-3 animated zoomIn imgicon"
                      data-toggle="tooltip"
                      id="PISM"
                    />
                    <!--PMS-->
                    <img
                      src="../assets/img/pms.png"
                      class="mr-md-3 animated zoomIn imgicon"
                      data-toggle="tooltip"
                      id="PMS"
                    />
                    <!--wfms-->
                    <img
                      src="../assets/img/WFMS.png"
                      class="mr-md-3 animated zoomIn imgicon"
                      data-toggle="tooltip"
                      id="WFMS"
                    />
                    <b-tooltip target="itsek" placement="bottom"
                      >ITSEK</b-tooltip
                    >
                    <b-tooltip target="paloup" placement="bottom"
                      >paloup</b-tooltip
                    >
                    <b-tooltip target="piso" placement="bottom">PISO</b-tooltip>
                    <b-tooltip target="PISM" placement="bottom">PISM</b-tooltip>
                    <b-tooltip target="PMS" placement="bottom">PMS</b-tooltip>
                    <b-tooltip target="WFMS" placement="bottom">WFMS</b-tooltip>
                  </div>
                </div>
                <!--Header-->
                <h5 class="dark-grey-text mb-2">
                  <span class="ng-binding">Sign in</span>
                </h5>
                <!-- Fast Application -->
                <!--Body-->
                <div class="md-form">
                  <b-input-group class="mb-3">
                    <b-input-group-prepend>
                      <b-input-group-text>
                        <i class="icon-user"></i>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <input
                      type="text"
                      name="email"
                      v-model="param.loginname"
                      class="form-control"
                      placeholder="Username"
                      autocomplete="username email"
                      @blur="getBranch"
                    />
                  </b-input-group>
                </div>

                <div class="md-form pb-3 password">
                  <b-input-group class="mb-4">
                    <b-input-group-prepend>
                      <b-input-group-text>
                        <i class="icon-lock"></i>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <input
                      type="password"
                      name="password"
                      class="form-control"
                      placeholder="Password"
                      autocomplete="current-password"
                      v-model="param.password"
                    />
                  </b-input-group>

                  <b-input-group class="mb-4">
                    <select v-model="selectedWarehouse" class="form-control">
                      <option
                        v-for="option in options"
                        :value="option.SiteCode"
                        v-bind:selected="
                          option == selectedWarehouse.serverwarehouseId
                        "
                        v-bind:key="option.SiteCode"
                      >
                        {{ option.SiteDescription }}
                      </option>
                    </select>
                  </b-input-group>
                  <div class="dispatch-table">
                    <p class="font-small blue-text d-flex justify-content-end">
                      <router-link :to="'#'">Forgot Password?</router-link>
                    </p>
                  </div>
                </div>

                <div class="row d-flex align-items-center mb-4">
                  <!--Grid column-->
                  <div class="text-center mb-3 col-md-12">
                    <b-button
                      variant="primary"
                      class="
                        btn btn-primary btn-block btn-rounded
                        z-depth-1
                        waves-effect waves-light
                        ng-hide
                      "
                      @click="login"
                      :disabled="!selectedWarehouse"
                      >Login</b-button
                    >
                  </div>
                  <div class="text-center">version: {{ version }}</div>
                  <!--Grid column-->
                </div>
              </b-form>
            </div>

            <!--Footer-->
          </div>
        </div>
      </b-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { router } from "@/router";
import vSelect from "vue-select";
import miniToastr from "mini-toastr";
// import { constants } from "fs";
import api from "./../services/api";
miniToastr.init();
export default {
  name: "Login",
  components: {
    vSelect,
  },
  data: function () {
    return {
      filter: null,
      param: {
        loginname: "",
        password: "",
      },
      branch: "",
      options: this.$store.getters.branches,
      warehousecode: "",
      version: this.$store.state.version5,
    };
  },
  computed: {

    // selectedWarehouse: {
    //   get: function () {
    //     return this.$store.getters.selectedWarehouse;
    //   },
    //   set: function (value) {
    //     this.$store.commit("selectedWarehouse", value);
    //   },
    // },
    selectedWarehouse: {
      get: function() {
        if (this.$store.getters.selectedWarehouse == undefined) {
          return this.options[0];
        }
        return this.$store.getters.selectedWarehouse;
      },
      set: function(value) {
        if (value != undefined) {
          console.log(value);
          this.$store.commit("selectedWarehouse", value);
          // this.$store.commit("warehousecode", value);
        }
      }
    }
    // version(){
    //   return this.$store.state.version;
    // }
  },
  methods: {
    login: function () {
      // if (this.selectedWarehouse === undefined) {
        if (this.selectedWarehouse.serverwarehouseId == 0) {
        miniToastr.error("Please select warehouse");
        return;
        // miniToastr.error("Login Failed");
      }
      let self = this;
      this.$store
        .dispatch("login", self.param)
        .then((resp) => {
          let warehousedesc = "";
          //get selected warehouse name
          self.options.forEach((element) => {
            console.log(element.SiteCode);
            if (element.SiteCode === self.selectedWarehouse) {
              warehousedesc = element.SiteDescription;
            }
          });

          let warehouseJsonData = {
            warehousecode: self.selectedWarehouse,
            warehousedesc: warehousedesc,
          };
          this.$store.commit("warehousecode", warehouseJsonData);

          this.$store.dispatch("getmockData", self.selectedWarehouse);
          this.$router.push("/dashboard");
        })
        .catch((err) => {
          console.log(err);

          miniToastr.error("Login Failed");
          reject(err);
        });
    },
    getBranch: async function () {
      let self = this;
      let response = await axios.request({
      // await api.instance.request({
        url:
          api.baseURL + "wfms/GetLoginSites?email=" + self.param.loginname,
          // this.$store.getters.apiName +
          // "wfms/GetLoginSites?email=" +
          // this.param.loginname,
        method: "get",
        headers: {
          Token:
            "MTMwMzgzMDo4ODkyZDgyNS1jZTQyLTQ0OGEtOTNlOS04ZTI1MWI4YWU3ZDd0c2Fm"
        },
      });
      this.options = response.data.sitesList;
      this.$store.commit("branches", this.options);
    },

    setBranch: function () {
      this.options = this.getBranch();
      this.$store.commit("branches", this.options);
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
