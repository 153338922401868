<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col md="12">
        <div class="dispatch-table">
          <b-card header>
            <div slot="header">
              <span>Itsek List</span>
              <a
                href="#"
                class="btn btn-sm btn-info float-right ml-1"
                id="printpagebutton"
                @click="onPrint"
              >
                <i class="fa fa-print"></i> Print
              </a>
              <button
                id="printexport"
                class="btn btn-sm btn-info float-right"
                @click="onexport"
              >Export</button>
            </div>

            <b-row>
               <b-col>
                <b-form-checkbox
                  @change="onchange()"
                  v-model="isdone"
                  name="checkbox-district"
                  class="float-left"
                  style="margin-right: 40px"
                >
                  Filter Done Checklists
                </b-form-checkbox>
              </b-col>
              <b-col md="4" class="my-1">
                <b-form-group label-cols-sm="1" class="mb-0">
                  <b-input-group>
                    <b-form-input v-model="filter" placeholder="Type to Search" />
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>&nbsp;
            <b-table
              show-empty
              stacked="md"
              responsive="sm"
              hover
              small
              striped
              fixed
              head-variant="light"
              :busy.sync="isBusy"
              :items="items"
              :fields="fields"
              :per-page="perPage"
              :filter="filter"
            >
              <template v-slot:cell(action_button)="data">
                <router-link
                  tag="button"
                  :to="'/Transaction/2017072411081871/' + data.item.id"
                  class="btn btn-primary btn-sm btn-square"
                  v-show="data.item.isposted == false"
                >
                  <i class="fa fa-pencil-square-o"></i>
                </router-link>&nbsp;
                <b-button
                  size="sm"
                  variant="danger"
                  class="btn btn-danger btnsm btn-square"
                  @click="onSelecteItemToDelete(data.item)"
                  v-show="data.item.isposted == false"
                >
                  <i class="fa fa-trash-o"></i>
                </b-button>
              </template>
              <template v-slot:cell(isactive)="data">
                <i
                  :class="data.item.isactive == 1 ?  'activeStatus icon-check active_icon' : 'inactiveStatus icon-close inactive_icon'"
                ></i>
              </template>
              <template v-slot:cell(created_date)="data">
                <span>{{data.item.created_date | moment("dddd, MMMM Do YYYY")}}</span>
              </template>
            </b-table>
          </b-card>
          <!-- <div style="margin-top:5px;">
            <b-pagination
              size="sm"
              :total-rows="totalItem"
              v-model="currentPage"
              :per-page="perPage"
              @input="onPageSelect"
            ></b-pagination>
          </div>-->
        </div>
        <div class="dispatch-list">
          <div>
            <p>Itsek List</p>
          </div>
          <b-row>
             <b-col>
                <b-form-checkbox
                  @change="onchange()"
                  v-model="isdone"
                  name="checkbox-district"
                  class="float-left"
                  style="margin-right: 40px"
                >
                  Filter Done Checklists
                </b-form-checkbox>
              </b-col>
                <!-- <b-col md="4" class="my-1">
                <b-form-group label-cols-sm="1" class="mb-0">
                  <b-input-group>
                    <b-form-input v-model="filter" placeholder="Type to Search" />
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col> -->
          </b-row>

          <b-list-group v-for="(item,index) in items" :key="`dynlist-${index}`" class="shadow">
            <b-list-group-item class="flex-column align-items-start">
               <div class="list-field">
                <span class="mb-1 dis">Id:</span>
                <span>{{item.id}}</span>
              </div>
              <div class="list-field">
                <span class="mb-1 dis">Checklist:{{item.checklistDescription}}</span>
              </div>

              <div class="list-field">
                <span class="mb-1 dis">Document:</span>
                <span>{{item.doc}}</span>
              </div>
              <div class="list-field">
                <span class="mb-1 dis">Reference No:</span>
                <span>{{item.refno}}</span>
              </div>
              <div class="list-field">
                <span class="mb-1 dis">Remark:</span>
                <span>{{item.remarks}}</span>
              </div>
              <div class="list-field">
                <span class="mb-1 dis">Created:</span>
                <span>{{item.created_date | moment("dddd, MMMM Do YYYY")}}</span>
              </div>
              <hr />
              <div class="row">
                <div class="col">
                  <router-link
                    tag="button"
                    :to="'/Transaction/2017072411081871/' + item.id"
                    class="btn btn-primary btnsm btn-block"
                  >
                    <span class="text-light">
                      <i class="fa fa-pencil-square-o"></i>
                      Edit
                    </span>
                  </router-link>

                  <b-button
                    class="btn btn-danger btnsm btn-block"
                    @click="onSelecteItemToDelete(item)"
                  >
                    <span class="text-light">
                      <i class="fa fa-trash-o"></i>
                      Remove
                    </span>
                  </b-button>
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
        </div>
        <b-modal title="System Message" v-model="deleteModal" @ok="onDeleteItem">
          Are you sure you want to remove
          <strong>{{selectedItemLabel}} ?</strong>
        </b-modal>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import api from "../../services/api";
import miniToastr from "mini-toastr";
import JSONToCSVConvertor from "../../../src/services/JSONToCSVConvertor.js";
export default {
  data: function() {
    return {
      deleteModal: false,
      selectedItem: {},
      selectedItemLabel: "",
      perPage: 0,
      filter: null,
      isdone: false,
       fields: [
        {
           key: "action_button",
           sortable: false,
           label: "Action"
        },
        {
          key: "id",
          label: "Itsek #"
        },
        {
          key: "checklistDescription",
          label: "Description"
        },
        {
          key: "doc",
          label: "Document"
        },
        {
          key: "refno",
          label: "Reference"
        },
        {
          key: "remarks",
          label: "Remarks"
        },
        {
          key: "created_by",
          label: "Created By",
          sortable: true
        },
        {
          key: "created_date",
          label: "Created Date",
          sortable: true
        }
       

      ],
      items: []
    };
  },

  methods: {
    onPrint: function(evt) {
      evt.preventDefault();
      // this.isHidden = true;
      var printButton = document.getElementById("printpagebutton");
      var printExport = document.getElementById("printexport");
      var breadView = document.getElementById("breadview");
      //Set the print button visibility to 'hidden'
      printButton.style.visibility = "hidden";
      printExport.style.visibility = "hidden";
      breadView.style.visibility = "hidden";
      //Print the page content
      window.print();
      printButton.style.visibility = "visible";
      printExport.style.visibility = "visible";
      breadView.style.visibility = "visible";
    },
    onexport: function() {
      JSONToCSVConvertor(this.items, "Itsek List", true);
    },

    onchange() {
      if(this.isdone == true) {
      //  this.initTable();
      this.loadtable();
      }
      else {
      //  this.loadtable();
      this.initTable();
      }
    },

    initTable() {
      this.isBusy = true;
      let self = this;
      self.$store.commit("setLoading", true);
      let promise = api.instance.get(
        "itsek/get-itsek-master-list?department=" +
          this.$store.getters.warehousecode + "&emplID="+this.$store.getters.user.emplId
      );

      return promise
        .then(resp => {
          self.items = resp.data;
          console.log('table:',resp.data)
          self.$store.commit("setLoading", false);
          // Here we could override the busy state, setting isBusy to false
          self.isBusy = false;
          //   return items;
        })
        .catch(err => {
          // Here we could override the busy state, setting isBusy to false
          self.isBusy = false;
          self.$store.commit("setLoading", false);
          api.httpMsg(self, err.status, err.data);
          // Returning an empty array, allows table to correctly handle busy state in case of error
          return [];
        });
    },

     loadtable() {
      this.isBusy = true;
      let self = this;
      self.$store.commit("setLoading", true);
      let promise = api.instance.get(
        "itsek/get-itsek-master-list-postedfval?department=" +
          this.$store.getters.warehousecode + "&emplID="+this.$store.getters.user.emplId
      );

      return promise
        .then(resp => {
          self.items = resp.data;
          console.log('table:',resp.data)
          self.$store.commit("setLoading", false);
          // Here we could override the busy state, setting isBusy to false
          self.isBusy = false;
          //   return items;
        })
        .catch(err => {
          // Here we could override the busy state, setting isBusy to false
          self.isBusy = false;
          self.$store.commit("setLoading", false);
          api.httpMsg(self, err.status, err.data);
          // Returning an empty array, allows table to correctly handle busy state in case of error
          return [];
        });
    },

    onSelecteItemToDelete: function(param) {
      this.selectedItem = param;
      this.selectedItemLabel = this.selectedItem.id;
      this.deleteModal = true;
    },
    onDeleteItem: function() {
      this.$store.commit("setLoading", true);
      api.instance
        .delete("itsek/remove-itsek-master?id=" + this.selectedItem.id)
        .then(resp => {
          this.initTable();
          this.deleteModal = false;
          this.$store.commit("setLoading", false);
          miniToastr.success(resp.data);
        })
        .catch(err => {
          api.httpMsg(this, err.status, err.data);

          this.$store.commit("setLoading", false);
        });
    }
  },
  created: function() {
    this.initTable();
   //this.loadtable();
  }
};
</script>
