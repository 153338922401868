<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col md="12">
        <div class="dispatch-table">
        <div class="newButton">
              <b-button
                variant="success"
                class="btnsm"
                @click="onClickNewCotegory()"
              >
                <i class="fa fa-plus-circle"></i>&nbsp;New Category
              </b-button>
            </div>
        </div>


        <div class="dispatch-list">
          <div>
            <p>Category List Card</p>
          </div>
           <div class="newButton">
              <b-button
                 variant="success"
                class="btnsm"
                @click="onClickNewCotegory()"
              >
                <i class="fa fa-plus-circle"></i>&nbsp;New Category
              </b-button>
            </div>
        </div>
        <b-row>
          <b-col sm="6" lg="3" v-for="(item,index) in checklistItem" :key="`dynlist-${index}`">
            <b-card no-body class="bg-background" @click="onfilterChecklist(item)">
              <b-card-body>
                <div class="text-center">
                  <h5>{{item.checklistdescription}}</h5>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import api from "../../services/api";

export default {
  data: function() {
    return {
      checklistItem: []
    };
  },
  methods: {
    //Checlist fileter
    onfilterChecklist: function(val) {
      this.$router.push(
        "/Maintenance/637523756515216988/category-by-checlistId/" +
          val.checklistId
      );
    },
    GetChecklistID: function() {
      let self = this;

      self.$store.commit("setLoading", true);
      self.checklistItem = [];
      api.instance
        .get(
          "itsek/get-chechlist-by-indicator?department=" +
            this.$store.getters.warehousecode
        )
        .then(function(resp) {
          resp.data.forEach(element => {
            self.checklistItem.push({
              checklistId: element.checklistId,
              checklistdescription: element.checklistdescription
            });
          });
          self.ChecklistidModal = true;
          self.$store.commit("setLoading", false);
        })
        .catch(function(err) {
          api.httpMsg(self, err.status, err.data);
          self.$store.commit("setLoading", false);
        });
    },
      onClickNewCotegory:function(){
      this.$router.push("/Maintenance/636984307928200838/new/category");
    }
  },
  created: function() {
    this.GetChecklistID();
  }
};
</script>
