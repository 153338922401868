<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col md="12">
        <div class="dispatch-table">
          <b-card header>
            <div slot="header">
              <span>Category List</span>
              <a
                href="#"
                class="btn btn-sm btn-info float-right ml-1"
                id="printpagebutton"
                @click="onPrint"
              >
                <i class="fa fa-print"></i> Print
              </a>
              <button
                id="printexport"
                class="btn btn-sm btn-info float-right"
                @click="onexport"
              >Export</button>
            </div>
            <div class="newButton">
              <b-button
                variant="primary"
                class="btnsm"
                @click="onClickNewCotegory()"
              >
                <i class="fa fa-plus-circle"></i>&nbsp;New Category
              </b-button>
            </div>
            <b-row>
              <b-col md="4" class="my-1">
                <b-form-group label-cols-sm="1" class="mb-0">
                  <b-input-group>
                    <b-form-input v-model="filter" placeholder="Type to Search" />
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>&nbsp;
            <b-table
              show-empty
              stacked="md"
              responsive="sm"
              hover
              small
              striped
              fixed
              head-variant="light"
              :busy.sync="isBusy"
              :items="items"
              :fields="fields"
              :filter="filter"
            >
              <template v-slot:cell(action_button)="data">
                    <b-button
                variant="primary"
                class="btn btn-primary btn-sm btn-square"
                @click="onClickUpdateCotegory(data.item.id)"
              >
                  <i class="fa fa-pencil-square-o"></i>
              </b-button>&nbsp;
                <!-- <router-link
                  tag="button"
                  :to="'/Maintenance/636984307928200838/modify/category/' + data.item.id"
                  class="btn btn-primary btn-sm btn-square"
                >
                  <i class="fa fa-pencil-square-o"></i>
                </router-link> -->
                <b-button
                  size="sm"
                  variant="danger"
                  class="btn btn-danger btn-sm btn-square"
                  @click="onSelecteItemToDelete(data.item)"
                >
                  <i class="fa fa-trash-o"></i>
                </b-button>
              </template>
              <template v-slot:cell(isactive)="data">
                <i
                  :class="data.item.isactive == 1 ?  'activeStatus icon-check active_icon' : 'inactiveStatus icon-close inactive_icon'"
                ></i>
              </template>
            </b-table>
          </b-card>
        </div>
        <div class="dispatch-list">
          <div>
            <p>Category List</p>
          </div>
          <div class="newButton">
             <b-button
                variant="primary"
                class="btnsm"
                @click="onClickNewCotegory()"
              >
                <i class="fa fa-plus-circle"></i>&nbsp;New Category
              </b-button>
          </div>
          <b-list-group v-for="(item,index) in items" :key="`dynlist-${index}`" class="shadow">
            <b-list-group-item class="flex-column align-items-start">
              <div class="list-field">
                <span class="mb-1 dis">Category Code:{{item.categorycode}}</span>
              </div>

              <div class="list-field">
                <span class="mb-1 dis">Category Name:</span>
                <span>{{item.categoryname}}</span>
              </div>

              <hr />
              <div class="row">
                <div class="col">
                   <b-button
                variant="primary"
                 class="btn btn-primary btn-block btnsm"
                @click="onClickUpdateCotegory(item.id)"
              >
                  <i class="fa fa-pencil-square-o"></i>
                      Edit
              </b-button>
                  <!-- <router-link
                    tag="button"
                    :to="'/Maintenance/636984307928200838/modify/category/' + item.id"
                    class="btn btn-primary btn-block btnsm"
                  >
                    <span class="text-light">
                      <i class="fa fa-pencil-square-o"></i>
                      Edit
                    </span>
                  </router-link> -->

                  <b-button
                    class="btn btn-danger btnsm btn-block"
                    @click="onSelecteItemToDelete(item)"
                  >
                    <span class="text-light">
                      <i class="fa fa-trash-o"></i>
                      Remove
                    </span>
                  </b-button>
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
        </div>
        <b-modal title="System Message" v-model="deleteModal" @ok="onDeleteItem">
          Are you sure you want to remove
          <strong>{{selectedItemLabel}} ?</strong>
        </b-modal>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import api from "../../services/api";
import fab from "vue-fab";
import JSONToCSVConvertor from "../../services/JSONToCSVConvertor.js";
import miniToastr from "mini-toastr";
export default {
  data: function() {
    return {
      deleteModal: false,
      selectedItem: {},
      selectedItemLabel: "",
      categoryid:"",
      totalItem: 0,
      currentPage: 1,
      filter: null,
      sortOptions: [],
      isBusy: false,
      fields: [
        {
          key: "action_button",
          label: "Action"
        },
        {
          key: "categorycode",
          label: "Category Code",
          sortable: true
        },
        {
          key: "categoryname",
          label: "Category Name"
        },
        {
          key: "checklistdescr",
          label: "Checklist"
        }
      ],
      items: []
    };
  },
  methods: {
    onPrint: function(evt) {
      evt.preventDefault();
      // this.isHidden = true;
      var printButton = document.getElementById("printpagebutton");
      var printExport = document.getElementById("printexport");
      var breadView = document.getElementById("breadview");
      //Set the print button visibility to 'hidden'
      printButton.style.visibility = "hidden";
      printExport.style.visibility = "hidden";
      breadView.style.visibility = "hidden";
      //Print the page content
      window.print();
      printButton.style.visibility = "visible";
      printExport.style.visibility = "visible";
      breadView.style.visibility = "visible";
    },
    onexport: function() {
      JSONToCSVConvertor(this.items, "GroupCode Maintenance", true);
    },
    initTable() {
      // Here we don't set isBusy prop, so busy state will be handled by table itself
      this.isBusy = true;
      let self = this;
      self.$store.commit("setLoading", true);
      let promise = api.instance.get(
        "itsek/get-category-list?&brancode=" + self.$store.getters.warehousecode + "&checklistId="
        + self.$route.params.checklistId
      );

      return promise
        .then(resp => {
          self.items = resp.data;
          self.$store.commit("setLoading", false);

          // Here we could override the busy state, setting isBusy to false
          self.isBusy = false;
          //   return items;
        })
        .catch(err => {
          // Here we could override the busy state, setting isBusy to false
          self.isBusy = false;
          self.$store.commit("setLoading", false);
          api.httpMsg(self, err.status, err.data);
          // Returning an empty array, allows table to correctly handle busy state in case of error
          return [];
        });
    },
    onSelecteItemToDelete: function(param) {
      this.selectedItem = param;
      this.selectedItemLabel = this.selectedItem.categoryname;
      this.deleteModal = true;
    },
    onDeleteItem: function() {
      this.$store.commit("setLoading", true);
      api.instance
        .delete("itsek/del-category?id=" + this.selectedItem.id)
        .then(resp => {
          this.initTable();
          this.deleteModal = false;
          this.$store.commit("setLoading", false);

          miniToastr.success(resp.data + " " + this.selectedItem.categoryname);
        })
        .catch(err => {
          if (err.response.data === "") {
            api.httpMsg(this, err.status, err.data);
          } else {
            miniToastr.error(err.response.data);
          }
          this.$store.commit("setLoading", false);
        });
    },
    onClickNewCotegory:function(){
      this.$router.push("/Maintenance/637523756515216988/add/category/" + this.$route.params.checklistId);
    },
    onClickUpdateCotegory:function(val){
      this.categoryid = val;
      this.$router.push("/Maintenance/637523756515216988/modify/category/" + this.categoryid +"/" + this.$route.params.checklistId);
    }

  },
  created: function() {
    this.initTable();
  }
};
</script>

