<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col md="12" class="mt-1">
        <div class="dispatch-table">
          <b-card>
            <div slot="header">
              <span>iTsek Validation List</span>
              <a
                href="#"
                class="btn btn-sm btn-info float-right ml-1"
                id="printpagebutton"
                @click="onPrint"
              >
                <i class="fa fa-print"></i> Print
              </a>
              <button
                id="printexport"
                class="btn btn-sm btn-info float-right"
                @click="onexport"
              >
                Export
              </button>
            </div>
            <b-row>
              <b-col md="3" class="my-1">
                <b-form-group label-cols-sm="1" class="mb-0">
                  <b-input-group>
                    <b-form-input v-model="filter" placeholder="Search.." />
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''"
                        >Clear</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="float-right">
              <b-col>
                <b-form-checkbox
                  @change="onchange()"
                  v-model="issub"
                  name="checkbox-district"
                  class="float-right"
                  style="margin-right: 40px"
                >
                  Filter By Subordinate
                </b-form-checkbox>
              </b-col>
              <b-col md="2">
                <i
                  @click="onfilter"
                  class="fa fa-lg fa-filter"
                  style="margin-right: 30px"
                ></i>
              </b-col>
            </b-row>
            <b-row class="mb-3"></b-row>
            <b-table
              show-empty
              stacked="md"
              hover
              fixed
              small
              head-variant="light"
              :busy.sync="isBusy"
              :items="items"
              :fields="fields"
              :filter="filter"
            >
              <template v-slot:cell(action_button)="data">
                <router-link
                  tag="button"
                  :to="
                    '/Transaction/637015532788413767/validation/' + data.item.id
                  "
                  class="btn btn-primary btn-sm btn-square"
                  >Validate</router-link
                >&nbsp;
              </template>
              <template v-slot:cell(created_date)="data">
                <div>
                  <span>{{
                    data.item.created_date | moment("dddd, MMMM Do YYYY")
                  }}</span>
                </div>
              </template>
            </b-table>
          </b-card>
        </div>

        <div class="dispatch-list">
          <b-row>
            <b-col>
              <span>iTsek Validation List</span>
            </b-col>
          </b-row>
          <div class="float-right">
            <b-col>
              <b-form-checkbox
                @change="onchange()"
                v-model="issub"
                name="checkbox-district"
              >
                Filter By Subordinate
              </b-form-checkbox>
            </b-col>
            <b-col>
              <i @click="onfilter" class="fa fa-lg fa-filter"></i>
            </b-col>
          </div>
          <br /><br />
          <b-list-group
            v-for="(item, index) in items"
            :key="`dynlist-${index}`"
            class="shadow"
          >
            <b-list-group-item class="flex-column align-items-start">
              <div class="list-field">
                <span class="mb-1 dis">iTsek #</span>
                <span>{{ item.id }}</span>
              </div>
              <div class="list-field">
                <span class="mb-1 dis">Checklist:</span>
                <span>{{ item.checklistDescription }}</span>
              </div>
              <div class="list-field">
                <span class="mb-1 dis">Document:</span>
                <span>{{ item.doc }}</span>
              </div>
              <div class="list-field">
                <span class="mb-1 dis">Reference no:</span>
                <span>{{ item.refno }}</span>
              </div>
              <div class="list-field">
                <span class="mb-1 dis">Created By:</span>
                <span>{{ item.created_by }}</span>
              </div>
              <div class="list-field">
                <span class="mb-1 dis">Date Created:</span>
                <span>{{
                  item.created_date | moment("dddd, MMMM Do YYYY")
                }}</span>
              </div>
              <div class="row">
                <div class="col">
                  <router-link
                    tag="button"
                    :to="
                      '/Transaction/637015532788413767/validation/' + item.id
                    "
                    class="btn btn-primary btn-sm btn-square btn-block"
                    >Validation</router-link
                  >
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
        </div>
        <b-modal
          title="System Message"
          id="modal-sm"
          size="sm"
          v-model="onviewModal"
          @ok="onfiltersearch"
        >
          <div class="px-5">
            <b-form-group>
              <label for="remarks">From</label>
              <!-- <datetime v-model="startdate"></datetime> -->
              <input class="form-control" type="date" v-model="startDate" />
            </b-form-group>
            <b-form-group>
              <label for="remarks">To</label>
              <!-- <datetime v-model="endDate"></datetime> -->
              <input class="form-control" type="date" v-model="endDate" />
            </b-form-group>
          </div>
        </b-modal>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import api from "../../services/api";
import JSONToCSVConvertor from "./../../services/JSONToCSVConvertor.js";

export default {
  data: function () {
    return {
      onviewModal: false,
      progressCap: null,
      deleteModal: false,
      selectedItem: {},
      selectedItemLabel: "",
      selectedfile: null,
      perPage: this.$store.getters.perPage,
      totalItem: 0,
      currentPage: 1,
      isBusy: false,
      filter: null,
      isoverall: false,
      issub: true,

      fields: [
        {
          key: "action_button",
          sortable: false,
          label: "Action",
        },
        {
          key: "id",
          label: "Itsek #",
        },
        {
          key: "checklistDescription",
          label: "Description",
        },
        {
          key: "doc",
          label: "Document",
        },
        {
          key: "refno",
          label: "Reference",
        },
        {
          key: "remarks",
          label: "Remarks",
        },
        {
          key: "created_by",
          label: "Created By",
          sortable: true,
        },
        {
          key: "created_date",
          label: "Created Date",
          sortable: true,
        },
      ],
      items: [],
      startDate: null,
      endDate: null,
    };
  },

  methods: {
    onPrint: function () {
      // this.isHidden = true;
      var printButton = document.getElementById("printpagebutton");
      var printExport = document.getElementById("printexport");
      var breadView = document.getElementById("breadview");
      //Set the print button visibility to 'hidden'
      printButton.style.visibility = "hidden";
      printExport.style.visibility = "hidden";
      breadView.style.visibility = "hidden";
      //Print the page content
      window.print();
      printButton.style.visibility = "visible";
      printExport.style.visibility = "visible";
      breadView.style.visibility = "visible";
    },
    onexport: function () {
      JSONToCSVConvertor(this.items, "CheckList Report", true);
    },
    onfileselected(event) {
      this.selectedfile = event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(this.selectedfile);
      reader.onload = function () {
        console.log(reader.result.split(",")[1]);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    },
    onfilter() {
      this.onviewModal = true;
    },
    onfiltersearch() {
      this.isBusy = true;
      let self = this;
      self.$store.commit("setLoading", true);
      let promise = api.instance.get(
        "itsek/get-itsek-bydate-validation?startDate=" +
          self.startDate +
          "&endDate=" +
          self.endDate +
          "&department=" +
          this.$store.getters.warehousecode +
          "&empid=" +
          this.$store.state.user.emplId
      );

      return promise
        .then((resp) => {
          console.log("for validation list:", resp.data);
          self.items = resp.data;
          self.$store.commit("setLoading", false);
          // Here we could override the busy state, setting isBusy to false
          self.isBusy = false;
          //   return items;
        })
        .catch((error) => {
          // Here we could override the busy state, setting isBusy to false
          self.isBusy = false;
          self.$store.commit("setLoading", false);
          // Returning an empty array, allows table to correctly handle busy state in case of error
          return [];
        });
    },

    onchange() {
      if (this.issub == true) {
        this.initTable();
      } else {
        this.loadallsite();
      }
    },

    initTable() {
      this.isBusy = true;
      let self = this;
      self.$store.commit("setLoading", true);
      let promise = api.instance.get(
        "itsek/get-itsek-master-list-post-head?department=" +
          this.$store.getters.warehousecode +
          "&empid=" +
          this.$store.state.user.emplId
      );

      return promise
        .then((resp) => {
          self.items = resp.data;
          console.log("for validation list:", resp.data);
          self.$store.commit("setLoading", false);
          // Here we could override the busy state, setting isBusy to false
          self.isBusy = false;
          //   return items;
        })
        .catch((error) => {
          // Here we could override the busy state, setting isBusy to false
          self.isBusy = false;
          self.$store.commit("setLoading", false);
          // Returning an empty array, allows table to correctly handle busy state in case of error
          return [];
        });
    },

    loadallsite() {
      this.isBusy = true;
      let self = this;
      self.$store.commit("setLoading", true);
      let promise = api.instance.get(
        "itsek/get-itsek-master-list-all-site?department=" +
          this.$store.getters.warehousecode +
          "&empid=" +
          this.$store.state.user.emplId
      );

      return promise
        .then((resp) => {
          self.items = resp.data;
          console.log("for validation list:", resp.data);
          self.$store.commit("setLoading", false);
          // Here we could override the busy state, setting isBusy to false
          self.isBusy = false;
          //   return items;
        })
        .catch((error) => {
          // Here we could override the busy state, setting isBusy to false
          self.isBusy = false;
          self.$store.commit("setLoading", false);
          // Returning an empty array, allows table to correctly handle busy state in case of error
          return [];
        });
    },
  },
  created: function () {
    this.initTable();
  },

  watch: {},
};
</script>
