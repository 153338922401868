<template>
  <div class="animated fadeIn">
    <div class="dispatch-list row">
      <b-button variant="none" @click="back">
        <i class="fa fa-chevron-left"></i> Back
      </b-button>
    </div>
    <b-card no-body>
      <div id="headview" slot="header">
        <span>Checklist Report</span>

        <a
          href="#"
          id="printpagebutton"
          class="btn btn-sm btn-info float-right ml-1 itshowprintExport"
          @click="onPrint"
        >
          <i class="fa fa-print"></i> Print
        </a>
        <button
          id="printexport"
          class="btn btn-sm btn-info float-right itshowprintExport"
          @click="onexport"
        >
          Export
        </button>
      </div>
      <b-card-body>
        <b-row v-for="(item, index) in items" :key="index">
          <div
            class="checklist-title"
            style="width: 100%; height: 50px; text-align: center"
          >
            <h3>{{ item.checklistdescription }}</h3>
          </div>
        </b-row>
        <b-row class="mb-1">
          <b-col class="mb-1" v-for="(item, index) in items" :key="index">
            <div>
              Reference 1 :
              <strong>{{ item.id }}</strong>
            </div>
            <div>
              REMARKS:
              <strong>{{ item.remarks }}</strong>
            </div>
          </b-col>

          <b-col sm="3" v-for="(item, index) in items" :key="index">
            <div>
              REFERENCE 2:
              <strong>{{ item.refno }}</strong>
            </div>
            <div>
              Date:
              <strong>{{
                item.created_date | moment("dddd, MMMM Do YYYY")
              }}</strong>
            </div>
          </b-col>
        </b-row>
        <b-table
          show-empty
          stacked="md"
          fixed
          small
          bordered
          :busy.sync="isBusy"
          :items="checkTrans"
          :fields="fields"
          :filter="filter"
        >
          <template v-slot:cell(checklistransactionId)="data">
            <span>{{ data.index + 1 }}</span>
          </template>
          <template v-slot:cell(attachment)="row">
            <div class="text-center">
              <span v-show="row.item.attachment == 0"
                >No Attachment Available.</span
              >
            </div>
            <div
              class="box"
              v-for="(item, index) in row.item.attachment"
              :key="index"
            >
              <div class="image" @click="onClickViewAttachment(item)">
                <img
                  class="img-thumbnail"
                  :src="item.img"
                  style="width: 100px; height: 100px"
                />
              </div>
            </div>
            <!-- <b-button
              class="btn btn-primary btn-sm"
              variant="btn-primary"
              size="sm"
              @click="row.toggleDetails"
              >{{ row.detailsShowing ? "Hide" : "Show" }}</b-button
            > -->
          </template>
          <template v-slot:cell(row_detail)="data">
            <b-card style="border: none">
              <div class="col-12 sm-4 md-2">
                <div class="text-center">
                  <span v-show="data.item.attachment != null"
                    >No Attachment Available.</span
                  >
                </div>

                <div
                  class="box"
                  v-for="(item, index) in data.item.attachment"
                  :key="index"
                >
                  <div class="image" @click="onClickViewAttachment(item)">
                    <img
                      class="img-thumbnail"
                      :src="item.img"
                      style="width: 100px; height: 100px"
                    />
                  </div>
                </div>
              </div>
            </b-card>
          </template>
        </b-table>
      </b-card-body>
    </b-card>

    <div class="container">
      <b-col md="12">
        <div class="row">
          <div class="col">
            <div class="float-left">
              <div class="mb-2">CHECKED BY:</div>
              <div v-for="(item, index) in signaturechecker" :key="index">
                <img class="signature-thumnail" :src="item.img" />
              </div>
              <span v-for="(item, index) in items" :key="index">
                <strong>
                  <u>{{ item.created_by }}</u>
                </strong>
              </span>
            </div>
          </div>
          <div class="col v-box-signature">
            <div class="box-m-display">
              <div class="mb-2">VALIDATED BY:</div>
              <div v-for="(item, index) in validateSignature" :key="index">
                <img class="signature-thumnail" :src="item.img" />
              </div>
              <span v-for="(item, index) in items" :key="index">
                <strong>
                  <u>{{ item.validated_by }}</u>
                </strong>
              </span>
            </div>
          </div>
        </div>
      </b-col>
    </div>
    <b-row></b-row>
    <b-modal title="Preview" v-model="viewattachmentModal" @ok="onmodalclose">
      <div class="col-12 sm-4 md-2">
        <img class="img-thumbnail" :src="selectedItemAttachment" />
      </div>
    </b-modal>
  </div>
</template>
<script>
import api from "../../services/api";
import JSONToCSVConvertor from "./../../services/JSONToCSVConvertor.js";

export default {
  data: function () {
    return {
      isBusy: false,
      filter: null,
      isHidden: false,
      viewattachmentModal: false,
      selectedItemAttachment: "",
      fields: [
        {
          key: "checklistransactionId",
          label: "NO.",
          thClass: ["width-50", "text-center"],
          tdClass: "text-center",
        },
        {
          key: "category",
          label: "CATEGORY",
          thClass: ["width-100", "text-center"],
          tdClass: "text-center",
          sortable: true,
        },
        {
          key: "description",
          label: "INDICATOR",
          tdClass: "text-center",
          sortable: true,
        },
        {
          key: "answer",
          thClass: ["width-70", "text-center"],
          label: "ANSWER",
          tdClass: "text-center",
        },
        {
          key: "reason",
          thClass: ["width-150", "text-center"],
          label: "REASON",
          tdClass: "text-center",
        },
        {
          key: "attachment",
          thClass: ["width-100", "text-center"],
          tdClass: "text-center",
          label: "Attachment",
          class: "action-button",
        },
      ],
      // fields: {
      //   checklistransactionId: {
      //     label: "NO.",
      //     thClass: ["width-20", "text-center"],
      //     tdClass: "text-center"
      //   },
      //   category: {
      //     label: "CATEGORY",
      //     thClass: ["width-110", "text-center"],
      //     tdClass: "text-center",
      //     sortable: true
      //   },
      //   description: {
      //     thClass: ["width-150", "text-center"],
      //     label: "INDICATOR",
      //     tdClass: "text-center",
      //     sortable: true
      //   },
      //   answer: {
      //     thClass: ["width-150", "text-center"],
      //     label: "ANSWER",
      //     tdClass: "text-center"
      //   },
      //   reason: {
      //     thClass: ["width-150", "text-center"],
      //     label: "REASON",
      //     tdClass: "text-center"
      //   },
      //   attachment: {
      //     thClass: ["width-50", "text-center"],
      //     tdClass: "text-center",
      //     label: "Attachment",
      //     class: "action-button"
      //   }
      // },
      items: [],
      transmas: [],
      checkTrans: [],
      signaturechecker: [],
      validateSignature: [],
    };
  },

  methods: {
    onClickViewAttachment: function (param) {
      this.selectedItem = param;
      this.selectedItemAttachment = this.selectedItem.img;
      this.attach = param.attachment;
      this.viewattachmentModal = true;
    },
    onmodalclose: function () {},
    onPrint: function (evt) {
      evt.preventDefault();
      // this.isHidden = true;

      var headview = document.getElementById("headview");
      var breadView = document.getElementById("breadview");
      var Department = document.getElementById("department");

      //Set the print button visibility to 'hidden'
      headview.style.visibility = "hidden";
      breadView.style.visibility = "hidden";
      Department.style.visibility = "hidden";

      //Print the page content
      window.print();
      headview.style.visibility = "visible";
      breadView.style.visibility = "visible";
      Department.style.visibility = "visible";
    },
    onexport: function () {
      JSONToCSVConvertor(this.items, "Checklist Report", true);
    },
    initTable() {
      // Here we don't set isBusy prop, so busy state will be handled by table itself
      this.isBusy = true;
      let self = this;
      self.$store.commit("setLoading", true);
      let promise = api.instance.get(
        "itsek/get-checklist-report?id=" +
          this.$route.params.id +
          "&department=" +
          this.$store.getters.warehousecode
      );

      return promise
        .then((resp) => {
          self.items = resp.data.itsekmasterlist;
          self.checkTrans = resp.data.checkTrans;
          self.signaturechecker = resp.data.signaturechecker;
          self.validateSignature = resp.data.validateSignature;
          self.attach = resp.data.checkTrans.attachment;
          consoe.log(resp.data);
          self.$store.commit("setLoading", false);
          // Here we could override the busy state, setting isBusy to false
          self.isBusy = false;
          //   return items;
        })
        .catch((error) => {
          // Here we could override the busy state, setting isBusy to false
          self.isBusy = false;
          self.$store.commit("setLoading", false);
          // Returning an empty array, allows table to correctly handle busy state in case of error
          return [];
        });
    },
    back: function () {
      this.$router.push("/Reports/636427046995420000");
    },
  },
  created: function () {
    this.initTable();
  },
};
</script>

