<template>
<div class="animated fadeIn">
    <b-row>
        <b-col md="12" class="mt-1">
            <div class="dispatch-table">
                <b-card id="graphIndicator" ref="graphIndicator">
                    <div slot="header">
                        <span>Atrisk Behavior per Category</span>
                    </div>
                    <span>Start Week</span>
                    <b-form-select v-model="atriskList.startWeek" :options="startWeekOptions"></b-form-select>
                    <span>Start Year</span>
                    <b-form-select v-model="atriskList.startYear" :options="startYearOptions"></b-form-select>
                    <span>End Week</span>
                    <b-form-select v-model="atriskList.endWeek" :options="endWeekOptions"></b-form-select>
                    <span>End Year</span>
                    <b-form-select v-model="atriskList.endYear" :options="endYearOptions"></b-form-select>
                    
                    <b-form-group label="Additional Filters:">

                    <b-form-checkbox v-if="districtList.length>0" id="checkbox-district" @change="isOverall=false" v-model="byDistrict.isByDistrict" name="checkbox-district">
                            Filter By District
                    </b-form-checkbox>

                    <b-form-checkbox v-if="districtList.length>=8" id="checkbox-overall" @change="byDistrict.isByDistrict=false" v-model="isOverall" name="checkbox-overall">
                            FSC/FTM wide
                    </b-form-checkbox>

                    <b-form-select v-if="byDistrict.isByDistrict" v-model="byDistrict.selectedDistrict" :options="districtList"></b-form-select>
                    <span>Chart Type</span>
                    <b-form-select v-model="chartType" :options="['Line Chart','Column Chart']"></b-form-select>
                    
                    </b-form-group>

                    <center>
                        <b-button :disabled="!(byDistrict.isByDistrict==true?byDistrict.selectedDistrict:1)" style="margin-top:30px;margin-bottom:30px" @click="loadatriskList" variant="primary">Load Data</b-button>
                    </center>
                    <!-- <h6 v-if="arrangedChartData.series.length">Note: Please click colored bars or legend list to view analysis and details.</h6> -->
                    <bar-chart v-if="arrangedChartData.series.length && chartType=='Column Chart'"  :data="arrangedChartData" :options="options" />
                    <line-chart v-if="arrangedChartData.series.length && chartType=='Line Chart'"  :data="arrangedChartData" :options="options" />
                    <div v-if="table.length">
                        <h2>{{table[0].ChecklistName}}</h2>
                        <h4>Analysis:</h4>
                        <ul style="list-style-type: none;">
                            <li v-for="analyze in atriskList.analysis" :key="analyze" :class="analyze.class">{{analyze.phrase}}</li>
                        </ul>
                        <b-table striped hover bordered :fields="atriskList.fields" :busy="isBusy" :items="table.map((x,index)=>{return{'Rank':index+1,'IndicatorDescription': x.IndicatorDescription,'CategoryDescription': x.CategoryDescription,'NumberofNO': x.IncidentCount}})">
                            <template slot="action_button" slot-scope="data">
                                <b-link :key="data.IndicatorDescription" tag="button" @click="PostToPalo" class="btn btn-primary btn-sm btn-square">
                                    <i class="fa fa-pencil-square-o"></i>
                                    &nbsp;Post issue in Paloapp
                                </b-link>&nbsp;
                            </template>
                        </b-table>
                    </div>
                </b-card>
            </div>

            <div class="dispatch-list">
                <b-card id="graphIndicator" ref="graphIndicator">
                    <div slot="header">
                        <span>Atrisk Behavior per Category</span>
                    </div>
                    <span>Start Week</span>
                    <b-form-select v-model="atriskList.startWeek" :options="startWeekOptions"></b-form-select>
                    <span>Start Year</span>
                    <b-form-select v-model="atriskList.startYear" :options="startYearOptions"></b-form-select>
                    <span>End Week</span>
                    <b-form-select v-model="atriskList.endWeek" :options="endWeekOptions"></b-form-select>
                    <span>End Year</span>
                    <b-form-select v-model="atriskList.endYear" :options="endYearOptions"></b-form-select>
                    <span>Chart Type</span>
                    <b-form-select v-model="chartType" :options="['Line Chart','Column Chart']"></b-form-select>
                    
                    <center>
                        <b-button style="margin-top:30px;margin-bottom:30px" @click="loadatriskList" variant="primary">Load Data</b-button>
                    </center>
                    <h6 v-if="arrangedChartData.series.length">Note: Please click colored bars or legend list to view analysis and details.</h6>
                    <bar-chart v-if="arrangedChartData.series.length && chartType=='Column Chart'"  :data="arrangedChartData" :options="options" />
                    <line-chart v-if="arrangedChartData.series.length && chartType=='Line Chart'"  :data="arrangedChartData" :options="options" />
                    
                    <div v-if="table.length">
                        <h2>{{table[0].ChecklistName}}</h2>
                        <h4>Analysis:</h4>
                        <ul style="list-style-type: none;">
                            <li v-for="analyze in atriskList.analysis" :key="analyze" :class="analyze.class">{{analyze.phrase}}</li>
                        </ul>
                        <b-table responsive striped hover bordered :fields="atriskList.fields" :busy="isBusy" :items="table.map((x,index)=>{return{'Rank':index+1,'IndicatorDescription': x.IndicatorDescription,'CategoryDescription': x.CategoryDescription,'NumberofNO': x.IncidentCount}})" style="width:350px">
                            <template slot="action_button" slot-scope="data">
                                <b-link :key="data.IndicatorDescription" tag="button" @click="PostToPalo" class="btn btn-primary btn-sm btn-square">
                                    <i class="fa fa-pencil-square-o"></i>
                                    &nbsp;Post issue in Paloapp
                                </b-link>&nbsp;
                            </template>
                        </b-table>
                    </div>
                </b-card>
            </div>
        </b-col>
    </b-row>
</div>
</template>

<script>
import 'tui-chart/dist/tui-chart.css';
import {
    columnChart,
    barChart,
    pieChart,
    lineChart
} from '@toast-ui/vue-chart';
import api from '../services/api';

export default {
    components: {
        'bar-chart': columnChart,
        'line-chart': lineChart
    },
    data() {
        return {
            chartType: 'Column Chart',
            // districtList:[],
            byDistrict: {
                isByDistrict: false,
                selectedDistrict: null
            },
            isOverall: false,
            isBusy: false,
            arrangedChartData: { // for 'data' prop of 'bar-chart'

                categories: ['Week1', 'Week2', 'Week3', 'Week4', 'Week5'],
                series: [
                    // {
                    //     name: 'INCOMING VEHICLE INSPECTION CHECKLIST',
                    //     data: [56, 50, 10, 70, 80]
                    // },
                    // {
                    //     name: 'BBSI',
                    //     data: [34, 64, 23, 88, 34]
                    // },
                    // {
                    //     name: 'TECHNICAL',
                    //     data: [23, 61, 34, 90, 88]
                    // },
                    // {
                    //     name: 'DEV',
                    //     data: [46, 74, 96, 34, 13]
                    // }
                ]
            },
            table: [],
            optionsMobile: {
                chart: {
                    width: 350,
                    height: 500,
                    title: this.$store.state.warehousedesc + '(Atrisk Behavior per Category)',
                    format: '100'
                },
                legend: {
                    visible: true,
                    align: "bottom"
                },
                xAxis: {
                    max: 35
                },
                series: {
                    showLabel: true,
                    vertical: false,
                }
            },
            options: {
                chart: {
                    width: 1000,
                    height: 500,
                    title: this.$store.state.warehousedesc + '(Atrisk Behavior per Category)',
                    format: '100'
                },
                legend: {
                    align:'right',
                    visible: true
                },
                xAxis: {
                    max: 35
                },
                series: {
                    showLabel: true,
                    vertical: false,
                }
            },
            atriskList: {
                data: [],
                startWeek: 1,
                endWeek: 1,
                startYear: new Date().getFullYear(),
                endYear: new Date().getFullYear(),
                analysis: [{
                    phrase: [],
                    class: []
                }],
                fields: {
                    categoryname: {
                        label: "Category"
                    }
                }

            }
        }
    },
    created() {
        console.log('menu:', this.$store.state.nav);
        console.log('access:', this.$store.state.access);
        //this.getCalendar();
        // console.log('user:',this.$store.state.warehousecode);
        //this.loadatriskList();
    },
    methods: {

        getCalendar() {
            let self = this;
            let promise = api.instance.get("/itsek/get-itsek-calendar");

            return promise
                .then(resp => {
                    self.$store.state.calendar = resp.data;
                    //   return items;
                })
                .catch(error => {
                    alert('Error in getting calendara data!');
                    // Here we could override the busy state, setting isBusy to false
                    //alert('Unable to obtain calendar data!')
                    // Returning an empty array, allows table to correctly handle busy state in case
                    // of error
                    return [];
                });
        },
        PostToPalo() {
            window.open("https://paloapp.fastlogistics.com.ph/", "_blank");
        },
        setAnalysis(value) {
            if (value.legendIndex == undefined) {
                value.legendIndex = value.index;
            }
            this.atriskList.analysis = [];
            var Subject = this.arrangedChartData.series[value.legendIndex].name;
            var data = this.arrangedChartData.series[value.legendIndex].data;
            var average = Math.round(data.reduce((a, b) => a + b, 0) / data.length);
            var highestrate = Math.max.apply(Math, data);
            var highestrateTime = this.arrangedChartData.categories[data.indexOf(highestrate)];
            var lowestrate = Math.min.apply(Math, data);
            var lowestrateTime = this.arrangedChartData.categories[data.indexOf(lowestrate)];
            var latestDifference = data[data.length - 1] - average;
            this.atriskList.analysis.push({
                phrase: 'The average rating of ' + Subject + ' from ' + this.arrangedChartData.categories[0] + ' to ' + this.arrangedChartData.categories[this.arrangedChartData.categories.length - 1] + ' is ' + average + '%',
                class: (average >= 80 ? 'hit' : (average < 50 ? 'miss' : 'neutral'))
            });

            this.atriskList.analysis.push({
                phrase: 'The highest rating is ' + highestrate + '% during ' + highestrateTime,
                class: (highestrate >= 80 ? 'hit' : (highestrate < 50 ? 'miss' : 'neutral'))
            });

            this.atriskList.analysis.push({
                phrase: 'The lowest rating is ' + lowestrate + '% during ' + lowestrateTime,
                class: (lowestrate >= 80 ? 'hit' : (lowestrate < 50 ? 'miss' : 'neutral'))
            });

            this.atriskList.analysis.push({
                phrase: 'The last instance (' + this.arrangedChartData.categories[this.arrangedChartData.categories.length - 1] + ') is ' + (latestDifference > 0 ? (latestDifference + '% higher than ') : (latestDifference < 0 ? ((-1 * latestDifference) + '% lower than ') : 'the same as ')) + 'the average rate.',
                class: (latestDifference > 0 ? 'hit' : (latestDifference < 0 ? 'miss' : 'neutral'))
            });
            //this.atriskList.analysis = 
        },
        onResize() {
            var dis = this;
            window.onresize = function (event) {
                dis.options.chart.width = document.getElementById("graphIndicator").offsetWidth - 300;
                //alert(document.getElementById("graphIndicator").offsetWidth + ' '+dis.options.chart.width);
                //console.log(event.target.innerWidth)
            };
        },
        SelectBarData(value) {
            //console.log('selected:', value);
            // this.loadRankedNoPerCheckList(value);
        },
        getWeeks() {

        },
        getChartSeries() {
            var result = [];
            [...new Set(this.atriskList.data.map(x => x.categoryname))].forEach(element => {
                var dataArray = [];
                // console.log('ayan:', this.atriskList.data.filter(x => x.categoryname == element))
                this.atriskList.data.filter(x => x.categoryname == element).forEach(elementScope => {
                    if (elementScope.AtriskCount == 0 || elementScope.AtriskCount == null) {
                        dataArray.push(0);
                    } else {
                        dataArray.push(elementScope.AtriskCount)
                    }
                })
                result.push({
                    name: element,
                    data: dataArray
                })
            });
            return result;
            // console.log('result:', result);
        },
        loadatriskList() {
            var requestString = '';
            if(this.byDistrict.isByDistrict){
                this.options.chart.title= this.byDistrict.selectedDistrict + '(Atrisk Behavior per Category)';
                this.optionsMobile.chart.title= this.byDistrict.selectedDistrict + '(Atrisk Behavior per Category)';
                requestString = "Itsek/get-itsek-atrisk-category?districtcode=" + this.$store.state.access.filter(x=>x.name == this.byDistrict.selectedDistrict)[0].id;
            }
            else if(this.isOverall){
                this.options.chart.title= 'FSC/FTM Wide(Atrisk Behavior per Category)';
                this.optionsMobile.chart.title= 'FSC/FTM Wide(Atrisk Behavior per Category)';
                requestString = "Itsek/get-itsek-atrisk-category?isoverall=1";
            }
            else{
                this.options.chart.title= this.$store.state.warehousedesc + '(Atrisk Behavior per Category)';
                this.optionsMobile.chart.title= this.$store.state.warehousedesc + '(Atrisk Behavior per Category)';
                requestString = "Itsek/get-itsek-atrisk-category?brancode=" + this.$store.state.warehousecode;
            }
            this.isBusy = true;
            let self = this;
            self.$store.commit("setLoading", true);
            let promise = api.instance.get(
                requestString +
                "&startYear=" +
                self.atriskList.startYear +
                "&startWeek=" +
                self.atriskList.startWeek +
                "&endYear=" +
                self.atriskList.endYear +
                "&endWeek=" +
                self.atriskList.endWeek
            );

            return promise
                .then(resp => {
                    self.atriskList.data = resp.data.rating;
                    console.log('RAW DATA!',resp.data)
                    self.atriskList.analysis = [{
                        phrase: [],
                        class: []
                    }];
                    self.table = [];
                    self.$store.commit("setLoading", false);

                    console.log(self.atriskList.data);
                    // Here we could override the busy state, setting isBusy to false
                    self.isBusy = false;
                    //   return items;
                })
                .catch(error => {
                    // Here we could override the busy state, setting isBusy to false
                    self.isBusy = false;
                    self.$store.commit("setLoading", false);
                    // Returning an empty array, allows table to correctly handle busy state in case of error
                    return [];
                });
        },
        loadRankedNoPerCheckList(value) {
            var checklistName = value.legend;
            this.isBusy = true;
            let self = this;
            self.$store.commit("setLoading", true);
            let promise = api.instance.get(
                "Itsek/get-checklist-report-rankednoperchecklist?brancode=" +
                self.$store.state.warehousecode +
                "&checklistID=" +
                self.atriskList.data.filter(x => x.ChecklistName == checklistName)[0].ChecklistID
            );

            return promise
                .then(resp => {
                    self.table = resp.data;
                    //if(resp.data.length>0){
                    self.setAnalysis(value);
                    //}
                    console.log('indicators:', self.table);
                    self.$store.commit("setLoading", false);
                    console.log(self.atriskList.data);
                    // Here we could override the busy state, setting isBusy to false
                    self.isBusy = false;
                    //   return items;
                })
                .catch(error => {
                    // Here we could override the busy state, setting isBusy to false
                    self.isBusy = false;
                    self.$store.commit("setLoading", false);
                    // Returning an empty array, allows table to correctly handle busy state in case of error
                    return [];
                });
        }
    },
    computed: {
        chartData() {
            return {
                categories: [...new Set(this.atriskList.data.map(x => x.Scope))],
                series: this.getChartSeries()
            }
        },
        graphWidth(){
            return document.getElementById('graphIndicator').clientWidth;
        },
        districtList(){
            return this.$store.state.access.filter(x=>x.id.includes('D')).map(y=>y.name);
        },     
        containerWidth() {
            return document.getElementById("graphIndicator").offsetWidth;
        },
        Calendar() {
            return this.$store.state.calendar;
        },
        startYearOptions() {
            return Array.from(
                new Set(this.Calendar.map(x => x.analysisyear))
            ).sort(function (a, b) {
                return b - a;
            })
        },
        endYearOptions() {
            return Array.from(
                    new Set(this.Calendar.map(x => x.analysisyear))
                )
                .sort(function (a, b) {
                    return b - a;
                })
                .filter(x => x >= this.atriskList.startYear)
        },
        startWeekOptions() {
            return Array.from(
                new Set(this.Calendar.filter(y => y.analysisyear == this.atriskList.startYear).map(x => Number(x.analysisweek)))
            ).sort(function (a, b) {
                return a - b;
            })
        },
        endWeekOptions() {
            return Array.from(
                new Set(
                    this.Calendar.filter(
                        x => x.analysisyear == this.atriskList.endYear
                    ).map(x => x.analysisweek)
                )
            ).sort(function (a, b) {
                return a - b;
            })
        }
    },
    watch: {
        chartData() {
            console.log('atrisk data', this.chartData)
            this.arrangedChartData = this.chartData;
            this.fields = {
                categoryname: {
                    label: "Category"
                }
            }

        },
        containerWidth() {
            console.log('width:', this.containerWidth)
            console.log('char width:', this.options.chart.width)
            this.options.chart.width = this.containerWidth - 150;
        }
    },
    mounted() {
        this.onResize();
    }
}
</script>

<style scoped>
.hit {
    color: green;
}

.miss {
    color: red;
}

.neutral {
    color: #e6b800;
}
</style>
