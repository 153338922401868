<template>
  <div>
    <router-view></router-view>
    <!-- cordova plugin add cordova-plugin-network-information
  For mobile (cordova) -->
    <div v-if="isOnline" class="version online">
      <i class="fa fa-wifi"></i> Version: {{ version }}
    </div>
    <div v-if="isOffline" class="version offline">
      <i class="fa fa-minus-circle"></i> Version: {{ version }}
    </div>
  </div>
</template>

<script>
// npm install vue-offline --save
import { VueOfflineMixin } from "vue-offline";
import axios from "axios";
import api from "./services/api";

export default {
  name: "itsek",
  mixins: [VueOfflineMixin],
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    version() {
      return this.$store.state.version5;
    },
  },
  created: function () {
    var dis = this;
    this.$http.interceptors.response.use(undefined, function (err) {
      return new Promise(function (resolve, reject) {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch(logout);
          console.log("false");
        } else {
          console.log("true");
          dis.getCalendar();
          // this.$store.dispatch('getmockData',localStorage.getItem("selectedWarehouse"))
        }
        throw err;
      });
    });
  },

  methods: {
    getCalendar() {
      let self = this;
      let promise = api.instance.get("/itsek/get-itsek-calendar");

      return promise
        .then((resp) => {
          console.log(resp.data);
          self.$store.state.calendar = resp.data;
          //   return items;
        })
        .catch((error) => {
          alert("Error in getting calendara data!");
          // Here we could override the busy state, setting isBusy to false
          //alert('Unable to obtain calendar data!')
          // Returning an empty array, allows table to correctly handle busy state in case
          // of error
          return [];
        });
    },
  },
};
</script>

<style lang="scss">
// CoreUI Icons Set
@import "~@coreui/icons/css/coreui-icons.min.css";
/* Import Font Awesome Icons Set */
$fa-font-path: "~font-awesome/fonts/";
@import "~font-awesome/scss/font-awesome.scss";
/* Import Simple Line Icons Set */
$simple-line-font-path: "~simple-line-icons/fonts/";
@import "~simple-line-icons/scss/simple-line-icons.scss";
/* Import Flag Icons Set */
@import "~flag-icon-css/css/flag-icon.min.css";
/* Import Bootstrap Vue Styles */
@import "~bootstrap-vue/dist/bootstrap-vue.css";
// Import Main styles for this application
@import "assets/scss/style";

.version {
  position: fixed;
  bottom: 0;
  right: 0;
  text-align: center;
  width: auto;
  font-size: 12px;
}
.online {
  border: 3px solid #73ad21;
  background-color: #73ad21;
  color: black;
}
.offline {
  border: 3px solid red;
  background-color: red;
  color: white;
}
</style>
