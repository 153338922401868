import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import miniToastr from "mini-toastr";
import axios from "axios";
import api from "./services/api";
import { VueOfflineStorage } from "vue-offline";
miniToastr.init();
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    version5: '3.0.0.13',
    calendar: [],
    status: "",
    token: localStorage.getItem("token") || "",
    user: {},
    apiName: "",
    isWarehouse: false,
    isFilter: false,
    branches: [],
    site: "",
    firstname: "",
    warehousecode: "",
    warehousedesc: "",
    nav: [],
    access: [],
    perPage: 10,
    selectedWarehouse: [],
    isLoading: false,
    offlineData: [],
  },
  computed: {
    selectedWarehouse: {
      get: function () {
        return this.$store.getters.selectedWarehouse;
      },
      set: function (value) {
        this.$store.commit("selectedWarehouse", value);
        this.$store.commit("warehousecode", value.warehousecode);
      }
    },


  },
  plugins: [
    createPersistedState({
      key: "itsek"
    })
  ],
  mutations: {
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, data) {
      state.status = "success";
      state.token = data.token;
      state.user = data.user;
      console.log('userInfo:', state.user);
      state.firstname = data.firstname;
      localStorage.setItem("token", data.token);
    },
    auth_error(state) {
      state.status = "error";
    },
    logout(state) {
      state.status = "";
      state.token = "";
      state.user = "";
      state.isWarehouse = false;
      state.branches = "";
      state.site = "";
      state.firstname = "";
      state.selectedWarehouse = "";
      state.warehousecode = "";
      state.warehousedesc = "";
      state.isLoading = false;
      // state.apiName = "";
    },
    warehouse(state, isWarehouse) {
      state.isWarehouse = isWarehouse;
    },
    filter(state, isFilter) {
      state.isFilter = isFilter;
    },
    branches(state, branches) {
      state.branches = branches;
    },
    site(state, site) {
      state.site = site;
    },
    firstname(state, firstname) {
      state.firstname = firstname;
    },
    accountName(state, accountName) {
      state.accountName = accountName;
    },
    selectedWarehouse(state, selectedWarehouse) {
      state.selectedWarehouse = selectedWarehouse;
      localStorage.setItem(
        "selectedWarehouse",
        selectedWarehouse.serverwarehouseId
      );
    },
    warehousecode(state, data) {
      state.warehousecode = data.warehousecode;
      state.warehousedesc = data.warehousedesc;
    },
    setLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setNav(state, newStateNav) {
      state.nav = newStateNav;
    }
  },
  actions: {
    login({ commit }, userparam) {
      return new Promise((resolve, reject) => {
        commit("auth_request");

        let user = "";
        let firstname = "";

        axios({
          url: api.baseURL + "access/LoginUser",
          data: userparam,
          method: "POST"
        })
          .then(resp => {
            //authenticate

            user = resp.data.objParam1;
            firstname = resp.data.objParam1.firstname;
            axios.defaults.headers.common["Authorization"] =
              " Basic " + resp.data.stringParam1;

            //authorize
            axios({
              url: api.baseURL + "Authenticate/start",
              method: "GET"
            })
              .then(resp => {
                if (resp.data == "Authorized") {
                  delete axios.defaults.headers.common["Authorization"];
                  let udata = {
                    token: resp.headers.token,
                    user: user,
                    firstname: firstname
                  };
                  commit("auth_success", udata);
                  commit("firstname", firstname);
                  resolve(resp);
                } else {
                  miniToastr.error("Login Failed");
                  commit("auth_error");
                  localStorage.removeItem("token");
                  reject(resp);
                }
              })
              .catch(err => {
                miniToastr.error("Login Failed");
                commit("auth_error");
                localStorage.removeItem("token");
                reject(err);
              });
          })
          .catch(err => {
            miniToastr.error("Login Failed");
            commit("auth_error");
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        commit("logout");
        localStorage.removeItem("token");
        localStorage.removeItem("selectedWarehouse");
        delete axios.defaults.headers.common["Authorization"];
        resolve();
      });
    },
    changeSite() {
      this.state.isWarehouse = true;
    },
    filter() {
      this.state.isFilter = true;
    },
    showSuccessMsg() {
      this.showSuccessMsg();
    },
    setLoader({ commit }, isLoading) {
      commit("isLoading", isLoading);
    },
    // getApi(url) {
    //   let self = this;
    //   return new Promise((resolve, reject) => {
    //     axios({
    //       url: self.state.apiName + url,
    //       method: "GET",
    //       headers: {
    //         Token: self.state.token
    //       }
    //     })
    //       .then(resp => {
    //         //authenticate
    //         resolve(resp);
    //       })
    //       .catch(err => {
    //         reject(err);
    //       });
    //   });
    // },
    getmockData({ state }, brancode) {
      let self = this;
      //get mock data for offline mode
      axios({
        url: api.baseURL + "itsek/get-offline-data?brancode=" +
          brancode,
        method: "GET",
        headers: {
          Token: self.state.token
        }
      })
        .then(resp => {
          VueOfflineStorage.set('offlineData', resp.data)
          state.offlineData = resp.data;
        })
        .catch(err => {
          console.log('Error getting indicators for offline!(' + err + ')')
        })

    }

  },
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    apiName: state => state.apiName,
    isWarehouse: state => state.isWarehouse,
    isFilter: state => state.isFilter,
    branches: state => state.branches,
    site: state => state.site,
    firstname: state => state.firstname,
    selectedWarehouse: state => state.selectedWarehouse,
    warehousecode: state => state.warehousecode,
    warehousedesc: state => state.warehousedesc,
    token: state => state.token,
    isLoading: state => state.isLoading,
    user: state => state.user,
    nav: state => state.nav,
    perPage: state => state.perPage
  }
});