<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="12" class="mt-1">
        <div class="dispatch-list mb-2">
          <!-- <b-button variant="none" @click="back">
            <i class="fa fa-chevron-left"></i>
            Back
          </b-button>-->
          <router-link
            class="text-dark"
            v-if="$routerHistory.hasPrevious()"
            :to="{ path: $routerHistory.previous().path }"
          >
            <i class="fa fa-chevron-left"></i> Back
          </router-link>
        </div>
        <b-card :header="appendtext + ' Indicator Setup'">
          <b-alert :show="isShowAlert" variant="success" @dismissed="isShowAlert=false">
            <p>
              {{appendtext}}
              Save Complete
              <b-button v-show="buttonishow" size="sm" variant="primary" @click="reload">
                <i class="fa fa-plus-circle"></i>&nbsp;Indicator
              </b-button>
            </p>
          </b-alert>
          <b-alert :show="errors.length > 0" variant="danger">
            <h6 class="alert-heading">Please fill in all required field!</h6>
            <ul>
              <li v-for="(error,i) in errors" :key="`error-${i}`">{{ error }}</li>
            </ul>
          </b-alert>
          <b-form @submit="onSubmit" v-if="!isShowAlert">
            <b-form-group id="exampleInputGroup1" label="Category*">
              <input
                type="text"
                class="form-control bg-white"
                readonly
                v-model="Categoryname"
                @click="GetCategory"

              />
            </b-form-group>

            <b-form-group label="Short Name *">
              <b-form-input placeholder="ShortName" v-model.trim="form.shortname"></b-form-input>
            </b-form-group>
                  <b-form-group label="Sort *">
              <b-form-input placeholder="Sort no." v-model.trim="form.sortnumber"  type="number"></b-form-input>
            </b-form-group>
            <b-form-group label="Description *">
              <b-form-input placeholder="Description" v-model.trim="form.description"></b-form-input>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox-group
                stacked
                id="basicCustomCheckboxes"
                class="form-group col-md-12"
              >
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="attachactive"
                    value="1"
                    v-model="attachdoption"
                  />
                  <label class="custom-control-label" for="attachactive">Required Attachment</label>
                </div>
              </b-form-checkbox-group>
            </b-form-group>

            <b-form-group label="ValueDataType*">
              <b-form-select
                id="transtype"
                :plain="true"
                :options="valueTypeOption"
                v-model="form.valuedatatype"
              ></b-form-select>
            </b-form-group>

            <b-form-checkbox-group stacked id="basicCustomCheckboxes" class="form-group col-md-12">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="isactive"
                  value="1"
                  v-model="checked"
                />
                <label class="custom-control-label" for="isactive">is Active</label>
              </div>
            </b-form-checkbox-group>
            <div class="dispatch-table">
              <b-button type="submit" variant="primary">
                <i class="fa fa-save"></i>
                {{appendtext}}
              </b-button>
            </div>
            <div class="dispatch-list">
              <b-button type="submit" variant="primary" class="btn btn-primary btn-sm btn-block">
                <i class="fa fa-save"></i>
                {{appendtext}}
              </b-button>
            </div>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
    <!-- <b-modal v-model="ChecklistidModal" title="Select Checklist" ok-title="Save">
      <input type="Text" placeholder="Search" v-model="Searchchecklistid" class="form-control mb-2" />
      <span v-if="filterchecklistid.length == 0">No available Checklist.</span>
      <form class="form">
        <div style="height:50vh; overflow-x:auto;">
          <b-list-group>
            <b-list-group-item v-for="(item,index) in filterchecklistid" :key="index">
              <b-form-radio
                v-model="ChecklistModel"
                name="some-radios"
                :value="item"
                @change="onSelectChecklist"
              >{{item.checklistdescription}}</b-form-radio>
            </b-list-group-item>
          </b-list-group>
        </div>
      </form>
    </b-modal>-->
    <b-modal v-model="CategoryModal" title="Select Category" ok-title="Save">
      <input type="Text" placeholder="Search" v-model="Searchcategory" class="form-control mb-2" />
      <span v-if="filtercategory.length == 0">No available Category.</span>
      <form class="form">
        <div style="height:50vh; overflow-x:auto;">
          <b-list-group>
            <b-list-group-item v-for="(item,index) in filtercategory" :key="index">
              <b-form-radio
                name="some-radios"
                :value="item"
                @change="onSelectCategory"
              >{{item.categoryname}}</b-form-radio>
            </b-list-group-item>
          </b-list-group>
        </div>
      </form>
    </b-modal>
    <!-- <pre>{{$data}}</pre> -->
  </div>
</template>

<script>
import api from "../../services/api";
import Vue from "vue";
import miniToastr from "mini-toastr";
export default {
  data() {
    return {
      errors: [],

      isShowAlert: false,
      attachdoption: true,
      checked: true,
      Categoryname: "",
      ChecklistDescription: "",
      checklistIdoptions: [],
      categoryItem: [],
      ChecklistidModal: false,
      CategoryModal: false,
      Searchchecklistid: "",
      Searchcategory: "",
      ChecklistModel: "",
      appendtext: this.$route.params.id == undefined ? "Create" : "Update",
      buttonishow: this.$route.params.id == undefined ? true : false,
      form: {
        categoryid: "",
        checklistId: "",
        indicatorId:
          this.$route.params.id == undefined ? "" : this.$route.params.id,
        shortname: "",
        description: "",
        attachrequired: true,
        department: this.$store.getters.warehousecode,
        valuedatatype: "",
        status: "A",
        created_by:
          this.$store.getters.user.firstname +
          " " +
          this.$store.getters.user.lastname,
        create_date: "",
        sortnumber:"",
      },
      valueTypeOption: [
        {
          value: "TEXT",
          text: "PARAGRAPH"
        },
        {
          value: "BOOLEAN",
          text: "YES/NO"
        },
        {
          value: "NUMERIC",
          text: "NUMBER"
        }
      ]
    };
  },
  computed: {
    // filterchecklistid: function() {
    //   var self = this;
    //   return self.checklistIdoptions.filter(function(cust) {
    //     return (
    //       cust.checklistdescription
    //         .toLowerCase()
    //         .indexOf(self.Searchchecklistid.toLowerCase()) >= 0
    //     );
    //   });
    // },
    filtercategory: function() {
      var self = this;
      return self.categoryItem.filter(function(cust) {
        return (
          cust.categoryname
            .toLowerCase()
            .indexOf(self.Searchcategory.toLowerCase()) >= 0
        );
      });
    }
  },
  methods: {
    checkForm: function(e) {
      if (
        this.form.shortname &&
        this.form.description &&
        this.form.categoryid
      ) {
        return true;
      }

      this.errors = [];
      if (!this.form.categoryid) {
        this.errors.push("Category required.");
      }
      if (!this.form.shortname) {
        this.errors.push("Short name required.");
      }
      if (!this.form.description) {
        this.errors.push("Description required");
      }

      e.preventDefault();
    },
    onSubmit: function(evt) {
      evt.preventDefault();
      if (!this.checkForm()) {
        return;
      }

      if (this.appendtext == "Create") {
        this.$store.commit("setLoading", true);
        api.instance
          .post("itsek/add-indicator", this.form)
          .then(resp => {
            this.isShowAlert = true;

            this.$store.commit("setLoading", false);
          })
          .catch(err => {
            if (err.response.data.ExceptionMessage === "") {
              api.httpMsg(this, err.status, err.data);
            } else {
              miniToastr.error(err.response.data.ExceptionMessage);
            }
            this.$store.commit("setLoading", false);
          });
      } else if (this.appendtext == "Update") {
        api.instance
          .put("itsek/edit-indicator", this.form)
          .then(() => {
            this.isShowAlert = true;
            this.$store.commit("setLoading", false);
          })
          .catch(function(err) {
            if (err.response.data.ExceptionMessage === "") {
              api.httpMsg(this, err.status, err.data);
            } else {
              miniToastr.error(err.response.data.ExceptionMessage);
            }
            this.$store.commit("setLoading", false);
          });
      }
    },
    GetCategory: function() {
      let self = this;
      if (self.$route.params.checklistId !== undefined) {
      self.$store.commit("setLoading", true);
      self.categoryItem = [];
      let promise = api.instance.get(
        "itsek/get-category-ID?&brancode=" + self.$store.getters.warehousecode  + "&checklistId="
       + self.$route.params.checklistId
      );
      return promise
        .then(resp => {
          resp.data.forEach(element => {
            self.categoryItem.push({
              id: element.id,
              categorycode: element.categorycode,
              categoryname: element.categoryname,
              checklistid: element.checklistid
            });
          });
          self.items = resp.data;
          self.$store.commit("setLoading", false);
          self.CategoryModal = true;
        })
        .catch(err => {
          self.$store.commit("setLoading", false);
          api.httpMsg(self, err.status, err.data);

          return [];
        });
      }

      if (self.$route.params.id !== undefined) {
      self.$store.commit("setLoading", true);
      self.categoryItem = [];
      let promise = api.instance.get(
        "itsek/get-category-list?&brancode=" + self.$store.getters.warehousecode
      );
      return promise
        .then(resp => {
          resp.data.forEach(element => {
            self.categoryItem.push({
              id: element.id,
              categorycode: element.categorycode,
              categoryname: element.categoryname,
              checklistid: element.checklistid
            });
          });
          self.items = resp.data;
          self.$store.commit("setLoading", false);
          self.CategoryModal = true;
        })
        .catch(err => {
          self.$store.commit("setLoading", false);
          api.httpMsg(self, err.status, err.data);

          return [];
        });
      }

    },
    onSelectCategory: function(val) {
      this.form.categoryid = val.id;
      this.Categoryname = val.categoryname;
      this.form.checklistId = val.checklistid;
    },
    // onSelectChecklist: function(val) {
    //   let self = this;
    //   self.form.checklistId = val.checklistId;
    //   self.ChecklistDescription = val.checklistdescription;
    // },
    GetChecklistID: function() {
      let self = this;

      self.$store.commit("setLoading", true);
      self.checklistIdoptions = [];
      api.instance
        .get(
          "itsek/get-chechlist-by-department?department=" +
            this.$store.getters.warehousecode
        )
        .then(function(resp) {
          resp.data.forEach(element => {
            self.checklistIdoptions.push({
              checklistId: element.checklistId,
              checklistdescription: element.checklistdescription
            });
          });
          self.ChecklistidModal = true;
          self.$store.commit("setLoading", false);
        })
        .catch(function(err) {
          api.httpMsg(self, err.status, err.data);
          self.$store.commit("setLoading", false);
        });
    },
    // back: function() {
    //   this.$router.push("/Maintenance/2017042003480096");
    // },
    reload: function() {
      this.$router.go(this.$router.currentRoute);
    },
    getData: function() {
      let self = this;
      if (this.$route.params.id !== undefined) {
        self.$store.commit("setLoading", true);
        api.instance
          .get("itsek/get-indicator-by-id?indicatorId=" + this.$route.params.id)
          .then(resp => {
            self.form = resp.data;
            self.ChecklistDescription = resp.data.ChecklistDescription;
            self.Categoryname = resp.data.categoryname;
            if (self.form.status === "A") {
              self.checked = true;
            } else {
              self.checked = false;
            }
            if (self.form.attachrequired === true) {
              self.attachdoption = true;
            } else {
              self.attachdoption = false;
            }
            self.$store.commit("setLoading", false);
          })
          .catch(err => {
            self.$store.commit("setLoading", false);
            api.httpMsg(self, err.status, err.data);
          });
      }
    },
    //  getCategoryID: function() {
    //   let self = this;
    //   if (self.$route.params.indicatorId !== undefined) {
    //     self.$store.commit("setLoading", true);
    //     api.instance
    //       .get("itsek/get-category-byid?id=" + self.$route.params.indicatorId +"&brancode="+ self.$store.getters.warehousecode)
    //       .then(resp => {
    //         self.Categoryname = resp.data.categoryname;
    //         self.form.categoryid = resp.data.id;

    //         self.$store.commit("setLoading", false);
    //       })
    //       .catch(err => {
    //         self.$store.commit("setLoading", false);
    //         api.httpMsg(self, err.status, err.data);
    //       });
    //   }
    // }
  },
  watch: {
    checked: function(val) {
      if (val) {
        this.form.status = "A";
      } else {
        this.form.status = "I";
      }
    },
    attachdoption: function(val) {
      if (val) {
        this.form.attachrequired = true;
      } else {
        this.form.attachrequired = false;
      }
    }
  },
  created: function() {
    this.getData();

  }
};
</script>
