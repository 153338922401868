<template>
  <div class="animated fadeIn">
    <div class="dispatch-table">
      <b-row>
        <b-col md="12">
          <b-table :items="forUpload" hover striped :fields="uploadFields">
            <template slot="dateCreated" slot-scope="data">
              {{formatDate(data.item.dateCreated)}}
            </template>
            <template slot="action" slot-scope="data">
              <b-button class="btn btn-success btnsm btn-block" @click="uploadTransaction(data.item.index)">Upload
              </b-button>
              <b-button class="btn btn-danger btnsm btn-block mx-1" variant="danger"
                @click="deleteTransaction(data.item.index)">Delete</b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </div>

    <div class="dispatch-list">

      <b-list-group v-for="(item,index) in forUpload" :key="`dynlist-${index}`" class="shadow">
        <b-list-group-item class="flex-column align-items-start">
          <div class="list-field">
            <span class="mb-1 dis">Checklist:{{item.checklistName}}</span>
          </div>
          <div class="list-field">
            <span class="mb-1 dis">Created By:</span>
            <span>{{item.createdBy}}</span>
          </div>
          <div class="list-field">
            <span class="mb-1 dis">Created:</span>
            <span>{{item.dateCreated | moment("dddd, MMMM Do YYYY")}}</span>
          </div>
          <hr />
          <div class="row">
            <div class="col">
              <b-button @click="uploadTransaction(index)" class="btn btn-success btnsm btn-block">
                <span class="text-light">
                  <i class="fa fa-pencil-square-o"></i>
                  Upload
                </span>
              </b-button>

              <b-button class="btn btn-danger btnsm btn-block" @click="deleteTransaction(index)">
                <span class="text-light">
                  <i class="fa fa-trash-o"></i>
                  Remove
                </span>
              </b-button>
            </div>
          </div>
        </b-list-group-item>
      </b-list-group>
    </div>
    <!-- <pre>{{$data}}</pre> -->
  </div>
</template>

<script>
  import api from "../../services/api";
  import InputChecklist from "../../components/InputChecklist";
  import miniToastr from "mini-toastr";
  import { Camera, CameraResultType } from '@capacitor/camera';
  import { VueOfflineStorage } from "vue-offline";


  miniToastr.init();
  document.addEventListener(
    "deviceready",
    () => {
      // it's only now that we are sure the event has triggered
    },
    false
  );
  export default {
    data: function () {
      return {
        selectedIndex: null,
        selectedOfflineSign: {},
        uploadFields: ['checklistName', 'createdBy', 'dateCreated', 'action'],
        forUpload: [],
        count: 0,
        errors: [],
        isShowSigAlert: false,
        isShowAlert: false,
        max: 100,
        checklistIdoptions: [],
        ChecklistidModal: false,
        uploadCounter: 0,
        modalAttach: false,
        files: [],
        selectedImg: [],
        availableModal: false,
        GroupcodeDescription: "",
        Searchgroupid: "",
        GroupidModel: "",
        selected: {},
        imglistselected: [],
        uploadedImage: [],
        optionschecklist: [],
        ChecklistDescription: "",
        Searchchecklistid: "",
        ChecklistModel: "",
        reason: "",
        checked: false,
        inputcheck: false,
        checklist: [],

        checklistvalue: false,
        appendtext: this.$route.params.id == undefined ? "Create" : "Modify",
        isdisabled: this.$route.params.id == undefined ? false : true,
        form: {
          id: this.$route.params.id == undefined ? "" : this.$route.params.id,
          checklistid: "",
          doc: "",
          refno: "",
          remarks: "",
          isposted: 0,
          created_by:
            this.$store.getters.user.firstname +
            " " +
            this.$store.getters.user.lastname,
          createbyid: this.$store.getters.user.emplId,
          created_date: ""
        },
        fields: {
          description: {
            label: "Checklist",
            sortable: true
          },

          valuedatatype: {
            label: "Action"
          },
          reason: {
            label: "Reason"
          },
          attachrequired: {
            label: "Attachment",
            sortable: true
          }
        }
      };
    },

    components: {
      "input-checklist": InputChecklist
    },
    computed: {
      filterchecklistid: function () {
        var self = this;
        return self.checklistIdoptions.filter(function (cust) {
          return (
            cust.checklistdescription
              .toLowerCase()
              .indexOf(self.Searchchecklistid.toLowerCase()) >= 0
          );
        });
      }
    },

    methods: {
      uploadTransaction(index) {
        this.$store.commit("setLoading", true);
        this.selectedIndex = index;
        this.form = this.forUpload[index].rawData.first;
        this.checklist = this.forUpload[index].rawData.second;
        // this.selectedOfflineSign = this.forUpload[index].rawData.third;
        this.validateFirstStep();
      },
      deleteTransaction(index) {
        this.forUpload.splice(index, 1);
        let newOfflineData = []
        this.forUpload.forEach(item => {
          newOfflineData.push(item.rawData)
        })
        VueOfflineStorage.set('forSubmition', newOfflineData);
      },
      formatDate(date) {
        let comp = date.split('T');
        let dateComp = comp[0].split('-');
        let timeComp = comp[1].split('.');

        return dateComp[1] + '/' + dateComp[2] + '/' + dateComp[0] + ' ' + timeComp[0]
      },
      async camCapture() {
        let self = this;
        this.isShowAlert = false;

        const image = await Camera.getPhoto({
          quality: 75,
          allowEditing: false,
          resultType: CameraResultType.Base64
        });
        // image.webPath will contain a path that can be set as an image src.
        // You can access the original file using image.path, which can be
        // passed to the Filesystem API to read the raw data of the image,
        // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
        //var imageUrl = image.webPath;
        console.log(image);
        self.imglistselected.push({
          img: image.base64String,
          filename: "Mobile caputure",
          extension: "PNG"
        }); //

        self.imageSrc = "data:image/jpeg;base64," + image.base64String;
        `data:image/jpeg;base64,${image.base64String}`;
        console.log('here:', self.imageSrc);
        // Can be set to the src of an image now
        //imageElement.src = imageUrl;

      },
      onReason: function (val) {
        if (val === "True" || val === true) {
          return true;
        } else if (val === "false" || val === false) {
          return false;
        }
      },

      getFileContentAsBase64(path, callback) {
        window.resolveLocalFileSystemURL(path, gotFile, fail);

        function fail(e) {
          alert("Cannot found requested file");
        }

        function gotFile(fileEntry) {
          fileEntry.file(function (file) {
            var reader = new FileReader();
            reader.onloadend = function (e) {
              var content = this.result;
              callback(content);
            };
            // The most important point, use the readAsDatURL Method from the file plugin
            reader.readAsDataURL(file);
          });
        }
      },
      captureSuccess(mediaFiles) {
        let self = this;
        var i, len;
        for (i = 0, len = mediaFiles.length; i < len; i += 1) {
          console.log(mediaFiles[i]);
          this.getFileContentAsBase64(mediaFiles[i].fullPath, function (
            base64Image
          ) {
            //window.open(base64Image);
            console.log(base64Image);
            self.imglistselected.push({
              img: base64Image.split(",")[1],
              filename: "Mobile caputure",
              extension: "JPG"
            });
          });
        }
      },
      captureError(error) {
        var msg = "An error occurred during capture: " + error.code;
        navigator.notification.alert(msg, null, "Uh oh!");
      },
      onCapure: function () {
        let self = this;
        this.isShowAlert = false;
        navigator.camera.getPicture(
          data => {
            // on success
            console.log(data);
            self.imglistselected.push({
              img: data,
              filename: "Mobile caputure",
              extension: "JPG"
            }); //
            self.imageSrc = "data:image/jpeg;base64," + data;
            `data:image/jpeg;base64,${data}`;
          },
          () => {
            // on fail
            miniToastr.error("Could not access device camera.");
          },
          {
            quality: 75,
            destinationType: Camera.DestinationType.DATA_URL,
            sourceType: Camera.PictureSourceType.CAMERA,
            mediaType: Camera.MediaType.PICTURE,
            encodingType: Camera.EncodingType.JPEG,
            cameraDirection: Camera.Direction.BACK,
            targetWidth: 720,
            targetHeight: 500,
            correctOrientation: true
          }
        );

        // var options = {     quality: 75,     sourceType:
        // Camera.PictureSourceType.CAMERA,     mediaType: Camera.MediaType.PICTURE,
        // encodingType: Camera.EncodingType.JPEG,     cameraDirection:
        // Camera.Direction.BACK,     targetWidth: 720,     targetHeight: 500,
        // correctOrientation: true }; navigator     .device     .capture
        // .captureImage(this.captureSuccess, this.captureError, {         limit: 2 },
        // options);
      },

      getData: function () {
        let self = this;
        if (this.$route.params.id !== undefined) {
          self.$store.commit("setLoading", true);
          api.instance
            .get("itsek/get-itsek-master-id?id=" + this.$route.params.id)
            .then(resp => {
              self.form = resp.data;
              self.ChecklistDescription = resp.data.checklistDescription;
              self.form.checklistid = resp.data.checklistid;
              self.$store.commit("setLoading", false);
            })
            .catch(err => {
              console.log(err);
              self.$store.commit("setLoading", false);
            });
        }
      },
      checkForm: function () {
        if (this.form.checklistid) {
          return true;
        } else {
          this.errors = [];

          if (!this.form.checklistid) {
            this.errors.push("Checklist required.");
          }

          return false;
        }
      },
      onDeleteTepmfile: function (index) {
        this.imglistselected.splice(index, 1);
      },
      validateFirstStep() {
        let self = this;

        return new Promise((resolve, reject) => {
          if (this.checkForm() == false) {
            reject();
          } else {
            // this.$store.commit("setLoading", true);
            // console.log('Forwarded Data:', this.form);
            // DitoRox
            let error = '';
            api.instance
              .post("itsek/add-itsek-master", this.form)
              .then(resp => {

                let checklist = resp.data.categoryGroup;
                self.checklist.forEach(cat => {
                  let counter = 0
                  let catItems = checklist.filter(x => x.categoryDesc == cat.categoryDesc)[0].checklistItem;
                  cat.checklistItem.forEach(ind => {

                    error = [ind, catItems, counter, ind.checklistindicatorId, cat.checklistItem.length + 'vs' + catItems.length, cat.checklistItem]
                    ind.checklistransactionId = catItems.filter(x => x.checklistindicatorId == ind.checklistindicatorId)[0].checklistransactionId
                    counter++
                  })
                  counter++
                })
                self.form = resp.data.viewitsekmaster;
                self.beforeTabSwitch();
                // this.$store.commit("setLoading", false);
                resolve(true);
              })
              .catch(function (err) {
                console.log('error', error)

                api.httpMsg(self, err.status, err.data);
                self.$store.commit("setLoading", false);
                resolve(false);
              });

          }
        });
        // return true;
      },
      onSelectChecklist: function (val) {
        let self = this;
        self.form.checklistid = val.checklistId;
        self.ChecklistDescription = val.checklistdescription;
      },
      //get groupCode

      GetChecklistID: function () {
        let self = this;
        self.errors = false;
        self.$store.commit("setLoading", true);
        self.checklistIdoptions = [];
        api.instance
          .get(
            "itsek/get-chechlist-by-indicator?department=" +
            this.$store.getters.warehousecode
          )
          .then(function (resp) {
            resp.data.forEach(element => {
              self.checklistIdoptions.push({
                checklistId: element.checklistId,
                checklistdescription: element.checklistdescription
              });
            });
            self.ChecklistidModal = true;
            self.$store.commit("setLoading", false);
          })
          .catch(function (err) {
            console.log(err);
            self.$store.commit("setLoading", false);
          });
      },

      onDeleteAttachment: function (val) {
        api.instance
          .delete("itsek/delete-checklist-attachment/" + val.attachId)
          .then(resp => {
            val.isdelete = true;
            miniToastr.success("Delete attachment complete.");
          })
          .catch(error => {
            miniToastr.error(error);
          });
      },
      onUpload() {
        let self = this;
        var i = 0;
        for (var value of this.imglistselected) {
          const evt = new FormData();
          evt.append("image", value.img);
          evt.append("extension", value.extension);
          evt.append("origfilename", value.filename);
          evt.append(
            "checklistransactionId",
            this.selected.checklistransactionId
          );
          evt.append("created_by", self.form.created_by);
          evt.append("createbyid", self.form.createbyid);
          api.instance
            .post("itsek/UploadChecklist", evt, {
              onUploadProgress: uploadEvent => {
                console.log(
                  "Upload Progress: " +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
                );
              }
            })
            .then(res => {
              self.uploadCounter = Math.round(
                (i / self.imglistselected.length) * 100
              );
              self.isShowAlert = true;
              if (self.uploadCounter == 100) {
                this.imglistselected = [];
              }
            })
            .catch(function (error) {
              miniToastr.error(error);
            });
          i++;
        }
      },
      onSelectImage: function (param) {
        this.selected = param;
        this.modalAttach = true;
        this.imglistselected = [];
        this.uploadedImage = [];
        this.uploadCounter = 0;
        this.onGetChecklistAttachment(this.selected.checklistransactionId);
      },
      onViewUploaded: function () {
        this.onGetChecklistAttachment(this.selected.checklistransactionId);
      },
      onGetChecklistAttachment: function (checklistTransId) {
        let self = this;
        self.$store.commit("setLoading", true);
        api.instance
          .get("itsek/get-checklist-attachment?id=" + checklistTransId)
          .then(resp => {
            self.uploadedImage = resp.data;
            self.$store.commit("setLoading", false);
          })
          .catch(err => {
            miniToastr.error(err);
            self.$store.commit("setLoading", false);
          });
      },
      onBrownserImage: function () {
        this.isShowAlert = false;
        this.uploadCounter = 0;
        this.$refs.imageinput.click();
        this.files = null;
      },
      onfileselected: function (event) {
        for (var i = 0; i <= event.target.files.length - 1; i++) {
          this.selectedImg.push(event.target.files[i]);
          this.loadPicture(
            event.target.files[i],
            event.target.files[i].name,
            event.target.files[i].name
              .split(".")
              .pop()
              .toLowerCase()
          );
        }
      },
      loadPicture(img, filename, extension) {
        let self = this;
        //  let img = document.getElementById("img");
        var reader = new FileReader();
        reader.onload = function (e) {
          //self.src = e.target.result; self.imglistselected.push(e.target.result);
          self.imglistselected.push({
            img: e.target.result.split(",")[1],
            filename: filename,
            extension: extension
          });
        };
        reader.readAsDataURL(img);
      },
      // DitoRox
      beforeTabSwitch: function () {
        let self = this;
        return new Promise((resolve, reject) => {
          if (this.checkForm() == false) {
            reject();
          } else {
            // this.$store.commit("setLoading", true);
            api.instance
              .post("itsek/post-updated-checklist", self.checklist)
              .then(resp => {
                // this.$store.commit("setLoading", false);
                // self.onComplete();
                self.forUpload.splice(self.selectedIndex, 1);
                let newOfflineData = []
                self.forUpload.forEach(item => {
                  newOfflineData.push(item.rawData)
                })
                VueOfflineStorage.set('forSubmition', newOfflineData);
                self.$store.commit("setLoading", false);
                miniToastr.success("Your Checklsit has bee uploaded, go to iTsek List");
                resolve(true);
              })
              .catch(error => {
                self.$store.commit("setLoading", false);
                miniToastr.error(error);
                resolve(true);
              });
            return true;
          }
        });
      },

      // DitoRox
      onComplete: function () {

        let self = this;
        const evt = new FormData();
        //  evt.append("image", self.selectedOfflineSign.image);
        evt.append("transactionId", self.form.id);
        evt.append("created_by", self.form.createbyid);
        api.instance
          .post("itsek/UploadFleetSignature?id=" + self.form.id + "&isOffline=1", evt)
          .then(res => {
            self.forUpload.splice(self.selectedIndex, 1);
            let newOfflineData = []
            self.forUpload.forEach(item => {
              newOfflineData.push(item.rawData)
            })
            VueOfflineStorage.set('forSubmition', newOfflineData);
            self.$store.commit("setLoading", false);
            // this.isShowSigAlert = true;

          })
          .catch(function (error) {
            self.$store.commit("setLoading", false);
            miniToastr.error(error);
          });

      },

      onSigClear: function () {
        this.$refs.signaturePad.clearSignature();
      },
      onChange: function () {
        this.$nextTick(function () {
          this.$refs.signaturePad.resizeCanvas();
        });
      },
      resizeCanvas() {
        // When zoomed out to less than 100%, for some very strange reason, some
        // browsers report devicePixelRatio as less than 1 and only part of the canvas
        // is cleared then.
        var ratio = Math.max(window.devicePixelRatio || 1, 1);
        // canvas.width = canvas.offsetWidth * ratio; canvas.height =
        // canvas.offsetHeight * ratio; canvas.getContext("2d").scale(ratio, ratio);
      },
      loadOfflineData() {
        let self = this;
        self.forUpload = []
        if (VueOfflineStorage.get('forSubmition')) {
          let forSubmition = VueOfflineStorage.get('forSubmition')
          let indexCount = 0;
          forSubmition.forEach(sub => {
            self.forUpload.push({
              index: indexCount,
              checklistName: self.$store.state.offlineData.filter(x => x.checklistId == sub.first.checklistid)[0].checklistdescription,
              createdBy: sub.first.created_by,
              dateCreated: sub.first.created_date,
              rawData: sub
            })
            indexCount++
          })
        }
      }
    },
    watch: {
      // checked: function(val) {
      //   if (val) {
      //     this.form.isposted = 1;
      //   } else {
      //     this.form.isposted = 0;
      //   }
      // }
    },
    created: function () {
      this.loadOfflineData();
    }
  };
</script>

<style>
  #signature {
    border: 1px solid;
  }
</style>
