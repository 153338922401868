import Vue from "vue";
import Router from "vue-router";
import store from "../store.js";
import Login from "../views/Login.vue";
import GroupCodeList from "../views/maintenance/GroupCodeList.vue";
import GroupCodeSetup from "../views/maintenance/GroupCodeSetup.vue";
import CheckList from "../views/maintenance/CheckList.vue";
import CheckSetup from "../views/maintenance/CheckSetup.vue";
import CheckListIndicatorList from "../views/maintenance/CheckListIndicatorList.vue";
import CheckListIndicatorSetup from "../views/maintenance/CheckListIndicatorSetup.vue";
import Indicatorlist from "../views/maintenance/IndicatorList.vue";
import IndicatorSetup from "../views/maintenance/IndicatorSetup.vue";
import Utilization from "../views/Reports/Utilization.vue";

import MaintenanceMenu from "../views/maintenance/MaintenanceMenu";

import ITSEK from "../views/Transaction/itsek.vue";
import ITSEKOffline from "../views/Transaction/itsekOffline.vue";
import ITSEKLIST from "../views/Transaction/ItsekList.vue";

import ChecklistAttachment from "../views/Transaction/ChecklistAttachment.vue";
import CheckListbyID from "../views/Reports/CheckListbyID.vue";
import CheckListReport from "../views/Reports/CheckListreport.vue";
import Graphs from "../views/Reports/Graphs.vue";

import ReviewChecklist from "../views/Reports/ReviewChecklist.vue";
import CategoryList from "../views/maintenance/CategoryList.vue";
import CategorySetup from "../views/maintenance/CategorySetup.vue";
import IndicatorListCard from "../views/maintenance/IndicatorListCard.vue";
import CheckListCard from "../views/maintenance/CheckListCard.vue";
import ITSEKVALIDATION from "../views/Transaction/ItsekValidation.vue";
import ITSEKVALIDATIONLIST from "../views/Transaction/itsekValidationList.vue";
import CategoryListCard from "../views/maintenance/CategoryListCard.vue";
import Utos from "../views/Reports/7Utos.vue";
import Other from "../views/Reports/OtherReport.vue";


import GroupCodeSetupSite from "../views/maintenance/GroupCodeSetupSite.vue"
import GroupCodeListSite from "../views/maintenance/GroupCodeListSite.vue"

import CheckSetupSite from "../views/maintenance/CheckSetupSite.vue"
import CheckListCardSite from "../views/maintenance/ChecklistCardSite.vue"
import ChecklistSite from "../views/maintenance/ChecklistSite.vue"

import IndicatorListSite from "../views/maintenance/IndicatorListSite.vue"
import IndicatorSetupSite from "../views/maintenance/IndicatorSetupSite.vue"
import IndicatorListCardSite from "../views/maintenance/IndicatorListCardSite.vue"

import CategoryListCardSite from "../views/maintenance/CategoryListCardSite.vue"
import CategorySetupSite from "../views/maintenance/CategorySetupSite.vue"
import CategoryListSite from "../views/maintenance/CategoryListSite.vue"


// Containers
const DefaultContainer = () => import("@/containers/DefaultContainer");

// Views
const Dashboard = () => import("@/views/Dashboard");

Vue.use(Router);

let router = new Router({
  mode: "hash", // Demo is living in GitHub.io, so required!
  linkActiveClass: "open active",
  scrollBehavior: () => ({
    y: 0
  }),
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      name: "Home",
      component: DefaultContainer,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: "dashboard",
          name: "Main",
          component: Dashboard,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/maintenance",
          redirect: "/Maintenance/636898096947541420",
          name: "Maintenance",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "/maintenance/menu",
              component: MaintenanceMenu
            },

            //groupcode
            {
              path: "636898096947541420",
              redirect: "/Maintenance/636898096947541420",
              name: "Group Maintenance",
              component: {
                render(c) {
                  return c("router-view");
                }
              },
              meta: {
                requiresAuth: true
              },
              children: [
                {
                  path: "/Maintenance/636898096947541420/new/groupcode",
                  name: "Create Group Maintenance",
                  component: GroupCodeSetup,
                  meta: {
                    requiresAuth: true
                  }
                },
                {
                  path: "/Maintenance/636898096947541420/modify/groupcode/:id",
                  props: true,
                  name: "Update Group Maintenace",
                  component: GroupCodeSetup,
                  meta: {
                    requiresAuth: true
                  }
                },
                {
                  path: "/Maintenance/636898096947541420",
                  component: GroupCodeList,
                  meta: {
                    requiresAuth: true
                  }
                }
              ]
            },
            //groupcode site
            {
              path: "637523750183808272",
              redirect: "/Maintenance/637523750183808272",
              name: "Group Maintenance Site",
              component: {
                render(c) {
                  return c("router-view");
                }
              },
              meta: {
                requiresAuth: true
              },
              children: [
                {
                  path: "/Maintenance/637523750183808272/new/groupcode",
                  name: "Create Group Maintenance Site",
                  component: GroupCodeSetupSite,
                  meta: {
                    requiresAuth: true
                  }
                },
                {
                  path: "/Maintenance/637523750183808272/modify/groupcode/:id",
                  props: true,
                  name: "Update Group Maintenace Site",
                  component: GroupCodeSetupSite,
                  meta: {
                    requiresAuth: true
                  }
                },
                {
                  path: "/Maintenance/637523750183808272",
                  component: GroupCodeListSite,
                  meta: {
                    requiresAuth: true
                  }
                }
              ]
            },
            
            //checklist
            {
              path: "2017042410233814",
              name: "CheckList Card",
              redirect: "/Maintenance/2017042410233814",
              component: {
                render(c) {
                  return c("router-view");
                }
              },
              meta: {
                requiresAuth: true
              },
              children: [
                {
                  path: "/Maintenance/2017042410233814/new/check-setup/:groupId",
                  name: "New checkList",
                  component: CheckSetup,
                  meta: {
                    requiresAuth: true
                  }
                },
                {
                  path: "/Maintenance/2017042410233814/modify/check/:id/:groupId",
                  props: true,
                  name: "Update check",
                  component: CheckSetup,
                  meta: {
                    requiresAuth: true
                  }
                },
                {
                  path: "/Maintenance/2017042410233814",
                  component: CheckListCard,
                  meta: {
                    requiresAuth: true
                  }
                },

                {
                  path:
                    "/Maintenance/2017042410233814/checlistId/:id",
                  component: CheckList,
                  name: "CheckList",
                  meta: {
                    requiresAuth: true
                  }
                }
              ]
            },
            
            //checklist site
            {
              path: "637533799999812067",
              name: "CheckList Card Site",
              redirect: "/Maintenance/637533799999812067",
              component: {
                render(c) {
                  return c("router-view");
                }
              },
              meta: {
                requiresAuth: true
              },
              children: [
                {
                  path: "/Maintenance/637533799999812067/new/check-setup/:groupId",
                  name: "New checkList Site",
                  component: CheckSetupSite,
                  meta: {
                    requiresAuth: true
                  }
                },
                {
                  path: "/Maintenance/637533799999812067/modify/check/:id/:groupId",
                  props: true,
                  name: "Update check Site",
                  component: CheckSetupSite,
                  meta: {
                    requiresAuth: true
                  }
                },
                {
                  path: "/Maintenance/637533799999812067",
                  component: CheckListCardSite,
                  meta: {
                    requiresAuth: true
                  }
                },

                {
                  path:
                    "/Maintenance/637533799999812067/checlistId/:id",
                  component: ChecklistSite,
                  name: "CheckList Site",
                  meta: {
                    requiresAuth: true
                  }
                }
              ]
            },

            //checklistindicator
            {
              path: "2017042003511326",
              name: "CheckList Indicator",
              redirect: "/Maintenance/2017042003511326",
              component: {
                render(c) {
                  return c("router-view");
                }
              },
              meta: {
                requiresAuth: true
              },
              children: [
                {
                  path: "/Maintenance/2017042003511326/new/checklist-indicator",
                  name: "New CheckList Indicator",
                  component: CheckListIndicatorSetup,
                  meta: {
                    requiresAuth: true
                  }
                },
                {
                  path:
                    "/Maintenance/2017042003511326/modify/checklist-indicator/:id",
                  props: true,
                  name: "Update CheckList Indicator",
                  component: CheckListIndicatorSetup,
                  meta: {
                    requiresAuth: true
                  }
                },
                {
                  path: "/Maintenance/2017042003511326",
                  component: CheckListIndicatorList,
                  meta: {
                    requiresAuth: true
                  }
                }
              ]
            },

            //Indicator Menu
            {
              path: "2017042003480096",
              name: "Indicator List Card",
              redirect: "/Maintenance/2017042003480096",
              component: {
                render(c) {
                  return c("router-view");
                }
              },
              meta: {
                requiresAuth: true
              },

              children: [
                {
                  path: "/Maintenance/2017042003480096",
                  component: IndicatorListCard,
                  meta: {
                    requiresAuth: true
                  }
                },
                {
                  path: "/Maintenance/2017042003480096/new/indicator/:checklistId",
                  name: "New Indicator Setup",
                  component: IndicatorSetup,
                  meta: {
                    requiresAuth: true
                  }
                },
                {
                  path: "/Maintenance/2017042003480096/modify/indicator/:id/:checklistId",
                  props: true,
                  name: "Update Indicator Setup",
                  component: IndicatorSetup,
                  meta: {
                    requiresAuth: true
                  }
                },
                {
                  path:
                    "/Maintenance/2017042003480096/indicator-by-checlistId/:id",
                  component: Indicatorlist,
                  name: "Indicator List",
                  meta: {
                    requiresAuth: true
                  }
                }
              ]
            },

            //Indicator Site
            {
              path: "637523756899477080",
              name: "Indicator List Card Site",
              redirect: "/Maintenance/637523756899477080",
              component: {
                render(c) {
                  return c("router-view");
                }
              },
              meta: {
                requiresAuth: true
              },

              children: [
                {
                  path: "/Maintenance/637523756899477080",
                  component: IndicatorListCardSite,
                  meta: {
                    requiresAuth: true
                  }
                },
                {
                  path: "/Maintenance/637523756899477080/new/indicator/:checklistId",
                  name: "New Indicator Setup Site",
                  component: IndicatorSetupSite,
                  meta: {
                    requiresAuth: true
                  }
                },
                {
                  path: "/Maintenance/637523756899477080/modify/indicator/:id/:checklistId",
                  props: true,
                  name: "Update Indicator Setup Site",
                  component: IndicatorSetupSite,
                  meta: {
                    requiresAuth: true
                  }
                },
                {
                  path:
                    "/Maintenance/637523756899477080/indicator-by-checlistId/:id",
                  component: IndicatorListSite,
                  name: "Indicator List Site",
                  meta: {
                    requiresAuth: true
                  }
                }
              ]
            },
            
            //Category
            {
              path: "2017042003480096",
              name: "Category List Card",
              redirect: "/Maintenance/636984307928200838",
              component: {
                render(c) {
                  return c("router-view");
                }
              },
              meta: {
                requiresAuth: true
              },

              children: [
                {
                  path: "/Maintenance/636984307928200838",
                  component: CategoryListCard,
                  meta: {
                    requiresAuth: true
                  }
                },
                {
                  path: "/Maintenance/636984307928200838/new/category/",
                  name: "New Category Setup",
                  component: CategorySetup,
                  meta: {
                    requiresAuth: true
                  }
                },
                {
                  path: "/Maintenance/636984307928200838/add/category/:checklistId",
                  name: "New Category Setup",
                  component: CategorySetup,
                  meta: {
                    requiresAuth: true
                  }
                },
                {
                  path: "/Maintenance/636984307928200838/modify/category/:id/:checklistId",
                  props: true,
                  name: "Update Category Setup",
                  component: CategorySetup,
                  meta: {
                    requiresAuth: true
                  }
                },
                {
                  path: "/Maintenance/636984307928200838/category-by-checlistId/:checklistId",
                  props: true,
                  name: "Category List",
                  component: CategoryList,
                  meta: {
                    requiresAuth: true
                  }
                }
              ]
            },

            //category site
            {
              path: "637523756515216988",
              name: "Category List Card Site",
              redirect: "/Maintenance/637523756515216988",
              component: {
                render(c) {
                  return c("router-view");
                }
              },
              meta: {
                requiresAuth: true
              },

              children: [
                {
                  path: "/Maintenance/637523756515216988",
                  component: CategoryListCardSite,
                  meta: {
                    requiresAuth: true
                  }
                },
                {
                  path: "/Maintenance/637523756515216988/new/category/",
                  name: "New Category Setup Site",
                  component: CategorySetupSite,
                  meta: {
                    requiresAuth: true
                  }
                },
                {
                  path: "/Maintenance/637523756515216988/add/category/:checklistId",
                  name: "New Category Setup Site",
                  component: CategorySetupSite,
                  meta: {
                    requiresAuth: true
                  }
                },
                {
                  path: "/Maintenance/637523756515216988/modify/category/:id/:checklistId",
                  props: true,
                  name: "Update Category Setup Site",
                  component: CategorySetupSite,
                  meta: {
                    requiresAuth: true
                  }
                },
                {
                  path: "/Maintenance/637523756515216988/category-by-checlistId/:checklistId",
                  props: true,
                  name: "Category List Site",
                  component: CategoryListSite,
                  meta: {
                    requiresAuth: true
                  }
                }
              ]
            }
          ]
        },
        {
          path: "/transaction",
          redirect: "/Transaction/2017072411081871/",
          name: "Transaction",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "/2017072411081871",
              redirect: "/Transaction/itsek-entry",
              component: {
                render(c) {
                  return c("router-view");
                }
              },
              meta: {
                requiresAuth: true
              },
              children: [
                {
                  path: "/Transaction/2017072411081871/",
                  component: ITSEK,
                  name: "Itsek Entry",
                  meta: {
                    requiresAuth: true
                  }
                },
                {
                  path: "/Transaction/2017072411081871/:id",
                  component: ITSEK,
                  meta: {
                    requiresAuth: true
                  }
                },
                {
                  path: "/2017072411081871/attactment/:id",
                  component: ChecklistAttachment,
                  meta: {
                    requiresAuth: true
                  }
                },
                {
                  path: "/Transaction/2017060610340995",
                  component: ITSEKLIST,
                  name: "Itsek List",
                  meta: {
                    requiresAuth: true
                  }
                },
                //Itsek Validation
                {
                  path: "637015532788413767",
                  name: "iTsek Validation List",
                  redirect: "/Transaction/637015532788413767",
                  component: {
                    render(c) {
                      return c("router-view");
                    }
                  },
                  meta: {
                    requiresAuth: true
                  },

                  children: [
                    {
                      path: "/Transaction/637015532788413767",
                      component: ITSEKVALIDATIONLIST,
                      meta: {
                        requiresAuth: true
                      }
                    },

                    {
                      path: "/Transaction/637015532788413767/validation/:id",
                      props: true,
                      name: "iTsek Validation",
                      component: ITSEKVALIDATION,
                      meta: {
                        requiresAuth: true
                      }
                    }
                  ]
                },
                {
                  path: "/Transaction/637401045748600833",
                  component: ITSEKOffline,
                  name: "Itsek Offline",
                  meta: {
                    requiresAuth: true
                  }
                },
              ]
            }
          ]
        },
        {
          path: "/report",
          redirect: "Reports/636427046995420000",
          name: "Reports",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "/636427046995420000",
              name: "Checklist Report",
              redirect: "Reports/636427046995420000",
              component: {
                render(c) {
                  return c("router-view");
                }
              },
              meta: {
                requiresAuth: true
              },
              children: [
                {
                  path: "/Reports/636427046995420000",
                  component: CheckListReport,
                  meta: {
                    requiresAuth: true
                  }
                },
                {
                  path: "/Reports/636427046995420000/:id",
                  component: CheckListbyID,
                  meta: {
                    requiresAuth: true
                  }
                }
              ]
            },
            {
              path: "/201927046995420000",
              name: "Graphs",
              redirect: "Reports/201927046995420000",
              component: {
                render(c) {
                  return c("router-view");
                }
              },
              meta: {
                requiresAuth: true
              },
              children: [
                {
                  path: "/Reports/201927046995420000",
                  component: Graphs,
                  meta: {
                    requiresAuth: true
                  }
                }
              ]
            },
            {
              path: "/637214142132680426",
              name: "Utilization",
              redirect: "Reports/637214142132680426",
              component: {
                render(c) {
                  return c("router-view");
                }
              },
              meta: {
                requiresAuth: true
              },
              children: [
                {
                  path: "/Reports/637214142132680426",
                  component: Utilization,
                  meta: {
                    requiresAuth: true
                  }
                }
              ]
            },
            {
              path: "/637359846669870204",
              name: "7 Utos",
              redirect: "Reports/637359846669870204",
              component: {
                render(c) {
                  return c("router-view");
                }
              },
              meta: {
                requiresAuth: true
              },
              children: [
                {
                  path: "/Reports/637359846669870204",
                  component: Utos,
                  meta: {
                    requiresAuth: true
                  }
                }
              ]
            },
             {
              path: "/637575336006519324",
              name: "Checklist Rating",
              redirect: "Reports/637575336006519324",
              component: {
                render(c) {
                  return c("router-view");
                }
              },
              meta: {
                requiresAuth: true
              },
              children: [
                {
                  path: "/Reports/637575336006519324",
                  component: Other,
                  meta: {
                    requiresAuth: true
                  }
                }
              ]
            }
          ]
        },
        {
          path: "/reportslist",
          props: true,
          name: "Report",
          component: ReviewChecklist,
          meta: {
            requiresAuth: true
          }
        }
      ]
    },
    {
      path: "/login",
      name: "login",
      component: Login
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    console.log(store.getters.isLoggedIn);
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

export default router;
