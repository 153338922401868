<template>
  <span v-if="valuedatatype=='TEXT'">
    <input
    :disabled="disabled"
      type="text"
      class="form-control"
      @input="$emit('input', $event.target.value)"
      :value="value"
    />
  </span>
  <span v-else-if="valuedatatype=='NUMERIC'">
    <input
    :disabled="disabled"
      type="number"
      class="form-control"
      @input="$emit('input', $event.target.value)"
      :value="value"
    />
  </span>
  <span v-else-if="valuedatatype=='BOOLEAN'">
    <label class="mx-1 switch switch-pill switch-primary form-check-label">
      <input
        :disabled="disabled"
        type="checkbox"
        @change="$emit('input', $event.target.checked)"
        
        value="foo"
        size="sm"
        :checked="value === 'True' || value === 'TRUE'"
      />
      <!-- <span class="switch-slider"></span> -->
      <!-- <p-check class="p-icon p-round p-pulse" color="success">
        <i slot="extra" class="fas fa-check"></i>
        class="switch-input form-check-input"
    </p-check> -->
    </label>
  </span>
</template>

<script>
import { Switch as cSwitch } from "@coreui/vue";
export default {
  name: "switches",
  components: {
    cSwitch
  },
  props: {
    valuedatatype: {
      required: true
    },
    value: {
      required: true
    },
    disabled: false
  }
};
</script>

