<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="12" class="mt-1">
        <div class="dispatch-list row">
          <b-button variant="none" @click="back">
            <i class="fa fa-chevron-left"></i> Back
          </b-button>
        </div>
        <b-card :header="appendtext +' CheckList Setup'">
          <b-alert :show="isShowAlert" variant="success" isShowAlert="false">
            <p>
              {{appendtext}} Save Complete
              <b-button v-show="buttonishow" size="sm" variant="primary" @click="reload">
                <i class="fa fa-plus-circle"></i>&nbsp;Checklist
              </b-button>
            </p>
          </b-alert>

          <b-form @submit="onSubmit" v-if="!isShowAlert">
            <b-alert :show="errors.length > 0" variant="danger">
              <h6 class="alert-heading">Please fill in all required field!</h6>
              <ul>
                <li v-for="(error,i) in errors" :key="`error-${i}`">{{ error }}</li>
              </ul>
            </b-alert>
            <b-form-group>
              <label for="tel">Checklist Description*</label>
              <!-- <v-select v-model="selectgroupcode" :options="listgroupid" label="groupcode"></v-select> -->
              <input
                type="text"
                class="form-control"
                readonly
                v-model="GroupDescription"
                @click="GetgroupCode"
                :disabled="isDisabled == 1 ? true : false"
              />
            </b-form-group>

            <b-form-group>
              <label for="tel">Description*</label>
              <b-form-input
                type="text"
                id="checklistdescription"
                v-model="form.checklistdescription"
                placeholder="Description"
              ></b-form-input>
            </b-form-group>

            <b-form-group>
              <label for="tel">Frequency Scope*</label>
              <b-form-select v-model="form.frequencyscope" :options="scopeList"></b-form-select>
            </b-form-group>

            <b-form-group>
              <label for="tel">Frequency of Utilization*</label>
              <input
                type="number"
                class="form-control"
                v-model="form.frequency"
              />
            </b-form-group>
            
            <b-form-checkbox-group stacked id="basicCustomCheckboxes" class="form-group col-md-12">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="isactive"
                  value="1"
                  v-model="checked"
                />
                <label class="custom-control-label" for="isactive">is Active</label>
              </div>
            </b-form-checkbox-group>

            <div class="dispatch-table">
              <b-button type="submit" variant="primary">
                <i class="fa fa-save"></i>
                {{appendtext}}
              </b-button>
            </div>
            <div class="dispatch-list">
              <b-button type="submit" variant="primary" class="btn btn-primary btn-sm btn-block">
                <i class="fa fa-save"></i>
                {{appendtext}}
              </b-button>
            </div>
          </b-form>
        </b-card>
      </b-col>

    </b-row>
    <b-modal v-model="availableModal" title="Select Group Code " ok-title="Save">
      <input type="Text" placeholder="Search" v-model="Searchgroupid" class="form-control mb-2" />
      <span v-if="filterGroupid.length == 0">No available Groupcode.</span>
      <form class="form">
        <div style="height:50vh; overflow-x:auto;">
          <b-list-group>
            <b-list-group-item v-for="(item,index) in filterGroupid" :key="index">
              <b-form-radio
                v-model="GroupidModel"
                name="some-radios"
                :value="item"
                @change="onSelectGroupId"
              >{{item.description}}</b-form-radio>
            </b-list-group-item>
          </b-list-group>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import api from "../../services/api";

export default {
  data() {
    return {
      errors: [],
      scopeList:['Daily','Weekly','Monthly','Quarterly','Annual'],
      isDisabled: this.$route.params.groupId == undefined ? false : true,
      appendtext: this.$route.params.id == undefined ? "Create" : "Update",
      buttonishow: this.$route.params.id == undefined ? true : false,
      isShowAlert: false,
      listgroupid: [],
      GroupidModel: "",
      GroupDescription: "",
      Searchgroupid: "",
      checked: true,
      availableModal: false,
      form: {
        checklistId:
          this.$route.params.id == undefined ? "" : this.$route.params.id,
        groupId: "",
        frequency:null,
        frequencyscope:null,
        checklistdescription: "",
        fontcolor: undefined ? "#000000" : "#000000",
        bgcolor: undefined ? "#F2F3F4" : "#F2F3F4",
        status: "A",
        created_by:
          this.$store.getters.user.firstname +
          " " +
          this.$store.getters.user.lastname,
        create_date: "2017-05-11T14:35:31",
        department: this.$store.getters.warehousecode
      }
    };
  },
  computed: {
    filterGroupid: function() {
      var self = this;
      return self.listgroupid.filter(function(cust) {
        return (
          cust.description
            .toLowerCase()
            .indexOf(self.Searchgroupid.toLowerCase()) >= 0
        );
      });
    }
  },
  methods: {
    checkForm: function(e) {
      if (this.form.groupId && this.form.checklistdescription) {
        return true;
      }

      this.errors = [];
      if (!this.form.groupId) {
        this.errors.push("Group Code ID required.");
      }
      if (!this.form.checklistdescription) {
        this.errors.push("Checklist Description required");
      }

      e.preventDefault();
    },
    onSubmit: function(evt) {
      if (!this.checkForm()) {
        return;
      }
      this.$store.commit("setLoading", true);
      if (this.appendtext == "Create") {
        evt.preventDefault();

        api.instance
          .post("itsek/add-checklist", this.form)
          .then(() => {
            this.isShowAlert = true;
            this.$store.commit("setLoading", false);
          })
          .catch(function(err) {
            api.httpMsg(this, err.status, err.data);
            this.$store.commit("setLoading", false);
          });
      } else if (this.appendtext == "Update") {
        evt.preventDefault();

        api.instance
          .put("itsek/edit-checklist", this.form)
          .then(() => {
            this.isShowAlert = true;
            this.$store.commit("setLoading", false);
          })
          .catch(function(err) {
            api.httpMsg(this, err.status, err.data);
            this.$store.commit("setLoading", false);
          });
      }
    },

    onSelectGroupId: function(param) {
      this.form.groupId = param.ID;
      this.GroupDescription = param.groupcode;
    },
    reload: function() {
      this.$router.go(this.$router.currentRoute);
    },
    back: function() {
      this.$router.push("/Maintenance/637533799999812067");
    },
    getData() {
      let self = this;

      if (self.$route.params.id !== undefined) {
        self.$store.commit("setLoading", true);
        api.instance
          .get(
            "itsek/select-itsekcheckby-id?checklistId=" + self.$route.params.id
          )
          .then(resp => {
            console.log(resp.data)
            self.form = resp.data;
            self.transOutput = resp.data;
            self.GroupDescription = resp.data.GroupDescription;
            if (self.form.status === "A") {
              self.checked = true;
            } else {
              self.checked = false;
            }
            self.$store.commit("setLoading", false);
          })
          .catch(err => {
            self.$store.commit("setLoading", false);
            api.httpMsg(self, err.status, err.data);
          });
      }
    },

    GetgroupCode: function() {
      //get groupCode
      let self = this;
      self.listgroupid = [];
      self.$store.commit("setLoading", true);

      api.instance
        .get(
          "itsek/get-groupId?department=" +
            this.$store.getters.selectedWarehouse
        )
        .then(function(resp) {
          self.listgroupid = resp.data;
          self.availableModal = true;
          self.$store.commit("setLoading", false);
        })
        .catch(function(err) {
          api.httpMsg(self, err.status, err.data);
          self.$store.commit("setLoading", false);
        });
    },
      getGroupCodeID: function() {
      let self = this;
      if (self.$route.params.groupId !== undefined) {
        self.$store.commit("setLoading", true);
        api.instance
          .get("itsek/select-groupcode-id?ID=" + self.$route.params.groupId)
          .then(resp => {
            self.form.groupId = resp.data.ID;
            self.GroupDescription = resp.data.description;
            self.$store.commit("setLoading", false);
          })
          .catch(err => {
            self.$store.commit("setLoading", false);
            api.httpMsg(self, err.status, err.data);
          });
      }
    }
  },

  created: function() {
    this.getData();
    this.getGroupCodeID();

  },
  watch: {
    // selectgroupcode: function(param) {
    //   //select groupcode
    //   this.form.groupId = param.ID;
    // },
    checked: function(val) {
      if (val) {
        this.form.status = "A";
      } else {
        this.form.status = "I";
      }
    }
  }
};
</script>
