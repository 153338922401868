<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col md="12">
        <b-alert
          :show="isShowSigAlert"
          variant="success"
          @dismissed="isShowSigAlert = false"
        >
          <p>
            Thank you the transaction has been save. Generated Checklist&nbsp;{{
              form.checklistid
            }}
            <router-link variant="primary"></router-link>
          </p>
        </b-alert>
        <form-wizard
          @on-complete="onComplete"
          @on-change="onChange"
          title
          subtitle
          finish-button-text="POST"
          step-size="sm"
          color="#2196f3"
          size="sm"
          class="newone"
          v-if="!isShowSigAlert"
        >
          <tab-content
            :title="appendtext + ' Checklist Master'"
            :before-change="validateFirstStep"
          >
            <b-card header="Master Checklist">
              <b-form v-if="!isShowAlert">
                <b-alert :show="errors.length > 0" variant="danger">
                  <h6 class="alert-heading">
                    Please fill in all required field!
                  </h6>
                  <ul>
                    <li v-for="(error, i) in errors" :key="`error-${i}`">
                      {{ error }}
                    </li>
                  </ul>
                </b-alert>
                <b-form-group id="exampleInputGroup1" label="Checklist*">
                  <input
                    type="text"
                    class="form-control bg-white"
                    readonly
                    v-model="ChecklistDescription"
                    @click="GetChecklistID"
                  />
                </b-form-group>
                <b-form-group>
                  <label for="remarks">Reference 1</label>
                  <b-form-input
                    type="text"
                    id="remarks"
                    v-model="form.doc"
                    placeholder="Document [Optional]"
                  ></b-form-input>
                </b-form-group>
                <b-form-group>
                  <label for="remarks">Reference 2</label>
                  <b-form-input
                    type="text"
                    id="remarks"
                    v-model="form.refno"
                    placeholder="Reference [Optional]"
                  ></b-form-input>
                </b-form-group>
                <b-form-group>
                  <label for="remarks">Remark</label>
                  <b-form-input
                    type="text"
                    id="remark"
                    v-model="form.remarks"
                    placeholder="Remark [Optional]"
                  ></b-form-input>
                </b-form-group>
              </b-form>
            </b-card>
          </tab-content>
          <tab-content title="Checklist" :before-change="beforeTabSwitch">
            <div class="dispatch-table">
              <b-card header="Master Checklist'" class="mt-1">
                <input
                  ref="imageinput"
                  style="display: none"
                  type="file"
                  @change="onfileselected"
                  multiple
                  id="fileinput"
                  accept="image/*"
                />
                <div v-for="(item, index) in checklist" :key="index">
                  <p class="font-weight-bold mb-2 text-center">
                    Category:
                    {{
                      item.categoryDesc == undefined ? "" : item.categoryDesc
                    }}
                  </p>
                  <table
                    class="
                      table
                      b-table
                      table-sm
                      b-table-fixed b-table-stacked-md
                    "
                  >
                    <thead class="thead-light">
                      <th>Checklist</th>
                      <th>Action</th>
                      <th>Reason</th>
                      <th>Attachment</th>
                      <th>Required Attachment</th>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in item.checklistItem"
                        :key="index"
                      >
                        <td>{{ index + 1 }}. {{ item.description }}</td>
                        <td>
                          <input-checklist
                            :valuedatatype="item.valuedatatype"
                            v-model="item.checklistvalue"
                            :value="item.checklistvalue"
                          />
                        </td>
                        <td>
                          <b-form-group
                            v-show="item.valuedatatype === 'BOOLEAN'"
                          >
                            <b-form-input
                              :disabled="onReason(item.checklistvalue)"
                              type="text"
                              v-model="item.reason"
                              placeholder="Reason"
                            ></b-form-input>
                          </b-form-group>
                        </td>
                        <td>
                          <button
                            class="btn-sm form-control"
                            :class="
                              item.attachrequired ? 'btn-danger' : 'btn-primary'
                            "
                            @click="onSelectImage(item)"
                          >
                            <i class="fa fa-paperclip"></i> View Attachment
                          </button>
                        </td>
                        <td style="width: 100px">
                          <center>
                            <p v-if="item.attachrequired == 1">YES</p>
                            <p v-else>NO</p>
                          </center>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-card>
            </div>
            <div class="dispatch-list">
              <div
                v-for="(items, index) in checklist"
                :key="index"
                class="shadow"
              >
                <b-list-group-item>
                  <p class="font-weight-bold mb-2 text-center">
                    {{
                      items.categoryDesc == undefined ? "" : items.categoryDesc
                    }}
                  </p>
                  <b-list-group
                    v-for="(item, index) in items.checklistItem"
                    :key="index"
                  >
                    <b-list-group-item class="flex-column align-items-start">
                      <div class="d-flex w-100 justify-content-between mb-2">
                        {{ index + 1 }}. {{ item.description }}
                        <b-badge variant="none">
                          <input-checklist
                            v-show="item.valuedatatype === 'BOOLEAN'"
                            :valuedatatype="item.valuedatatype"
                            v-model="item.checklistvalue"
                          />
                        </b-badge>
                      </div>

                      <input-checklist
                        v-show="item.valuedatatype === 'TEXT'"
                        :valuedatatype="item.valuedatatype"
                        v-model.trim="item.checklistvalue"
                      />
                      <input-checklist
                        v-show="item.valuedatatype === 'NUMERIC'"
                        :valuedatatype="item.valuedatatype"
                        v-model.trim="item.checklistvalue"
                      />
                      <div class="d-flex w-100 justify-content-between mb-2">
                        <b-form-input
                          v-show="item.valuedatatype === 'BOOLEAN'"
                          :disabled="onReason(item.checklistvalue)"
                          type="text"
                          v-model="item.reason"
                          placeholder="Reason"
                        ></b-form-input>
                      </div>
                      <div style="text-align: center">
                        <button
                          class="btn-sm btn-block form-control"
                          :class="
                            item.attachrequired ? 'btn-danger' : 'btn-primary'
                          "
                          @click="onSelectImage(item)"
                        >
                          <i class="fa fa-paperclip"></i>
                          View Attachment
                        </button>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                  <b-col>
                    <div class="list-field float-right"></div>
                  </b-col>
                </b-list-group-item>
              </div>
            </div>
          </tab-content>
          <tab-content title="Signature">
            <div class="col-12 mt-2">
              <b-row md="2">
                <VueSignaturePad
                  id="signature"
                  width="100%"
                  height="200px"
                  ref="signaturePad"
                />
              </b-row>

              <b-row style="margin-top: 5px">
                <b-col>
                  <div style="float: right">
                    <b-button @click="onSigClear" size="sm" variant="secondary"
                      >clear</b-button
                    >&nbsp;
                  </div>
                </b-col>
              </b-row>
            </div>
          </tab-content>
        </form-wizard>
      </b-col>
    </b-row>
    <b-modal
      title="View Attachment"
      v-model="modalAttach"
      no-close-on-backdrop
      hide-footer
      id="modal-center"
      centered
    >
      <span>
        <strong>*{{ selected.description }}*</strong>
      </span>
      <br />
      <b-tabs>
        <b-tab active>
          <template slot="title">
            <strong>Browse File</strong>
          </template>
          <b-alert :show="isShowAlert" variant="success"
            >Upload Complete...</b-alert
          >
          <div class="dispatch-table">
            <button
              type="button"
              class="btn btn-secondary mb-2"
              variant="outline-secondary"
              @click="onBrownserImage"
            >
              <i class="fa fa-file-image-o"></i>
              Browse Image
            </button>
          </div>

          <div class="dispatch-list">
            <b-button-toolbar class="btn-group mb-1" role="group">
              <button
                type="button"
                class="btn btn-secondary"
                @click="camCapture"
              >
                <i class="fa fa-camera"></i>
                Camera
              </button>
              <button
                type="button"
                @click="onBrownserImage"
                class="btn btn-secondary"
              >
                <i class="fa fa-file-image-o"></i>
                Image
              </button>
            </b-button-toolbar>
          </div>
          <div
            style="height: 50vh; overflow-x: auto"
            v-show="uploadCounter != 100 ? true : false"
          >
            <div
              class="box"
              v-for="(file, index) in imglistselected"
              :key="file.img"
            >
              <button
                type="button"
                class="delete"
                @click="onDeleteTepmfile(file)"
              >
                <span>
                  <i
                    style="color: #20a8d8"
                    class="fa fa-times-circle fa-lg"
                  ></i>
                </span>
              </button>
              <div class="image">
                <img
                  class="img-thumbnail"
                  v-bind:ref="'img-' + index"
                  :src="'data:image/png;base64,' + file.img"
                  style="width: 100px; height: 100px"
                />
              </div>
            </div>
          </div>

          <b-card-footer>
            <b-button
              :disabled="imglistselected.lenght > 0"
              class="mt-3"
              variant="outline-primary"
              block
              @click="onUpload"
              >Upload</b-button
            >
            <br />

            <b-progress
              height="2px"
              :value="uploadCounter"
              :max="max"
            ></b-progress>
          </b-card-footer>
        </b-tab>
        <b-tab @click="onViewUploaded">
          <template slot="title">
            <strong>Uploaded</strong>
          </template>
          <div class="row" style="height: 50vh; overflow-x: auto">
            <div v-for="(file, index) in uploadedImage" :key="index">
              <div class="box" v-if="!file.isdelete">
                <button type="button" class="delete">
                  <span>
                    <i
                      style="color: red"
                      class="fa fa-times-circle fa-lg"
                      @click="onDeleteAttachment(file)"
                    ></i>
                  </span>
                </button>
                <div class="image">
                  <img
                    class="img-thumbnail"
                    :id="'img-' + index"
                    :src="file.img"
                    style="width: 100px; height: 100px"
                  />
                </div>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>
    <b-modal
      v-model="ChecklistidModal"
      title="Select Checklist"
      ok-title="Save"
    >
      <input
        type="Text"
        placeholder="Search"
        v-model="Searchchecklistid"
        class="form-control mb-2"
      />
      <span v-if="filterchecklistid.length == 0">No available Checklist.</span>
      <form class="form">
        <div style="height: 50vh; overflow-x: auto">
          <b-list-group>
            <b-list-group-item
              v-for="(item, index) in filterchecklistid"
              :key="index"
            >
              <b-form-radio
                v-model="ChecklistModel"
                name="some-radios"
                :value="item"
                @change="onSelectChecklist"
                >{{ item.checklistdescription }}</b-form-radio
              >
            </b-list-group-item>
          </b-list-group>
          {{ ChecklistModel }}
        </div>
      </form>
    </b-modal>
    <!-- <b-modal
      v-model="RequiredModal"
      title="Required Attachment"
      ok-title="Ok"
    >
      <div v-for="(item, index) in requiredlist" :key="index">
        <p class="font-weight-bold mb-2 text-center">
          Category:
          {{ item.categoryname == undefined ? "" : item.categoryname }}
        </p>
        <table class="table">
          <thead>
            <th>Indicator</th>
          </thead>
          <tbody>
            <tr>
<td>
  {{item.description}}
</td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-modal> -->
    <!-- <pre>{{$data}}</pre> -->
  </div>
</template>

<script>
import api from "../../services/api";
import InputChecklist from "../../components/InputChecklist";
import miniToastr from "mini-toastr";
import { Plugins, CameraResultType } from "@capacitor/core";

miniToastr.init();
document.addEventListener(
  "deviceready",
  () => {
    // it's only now that we are sure the event has triggered
  },
  false
);
export default {
  data: function () {
    return {
      count: 0,
      errors: [],
      isShowSigAlert: false,
      isShowAlert: false,
      max: 100,
      checklistIdoptions: [],
      ChecklistidModal: false,
      RequiredModal: false,
      requiredlist: null,
      uploadCounter: 0,
      modalAttach: false,
      files: [],
      selectedImg: [],
      availableModal: false,
      GroupcodeDescription: "",
      Searchgroupid: "",
      GroupidModel: "",
      selected: {},
      imglistselected: [],
      uploadedImage: [],
      optionschecklist: [],
      ChecklistDescription: "",
      Searchchecklistid: "",
      ChecklistModel: "",
      reason: "",
      checked: false,
      inputcheck: false,
      checklist: [],

      checklistvalue: false,
      appendtext: this.$route.params.id == undefined ? "Create" : "Modify",
      isdisabled: this.$route.params.id == undefined ? false : true,
      form: {
        id: this.$route.params.id == undefined ? "" : this.$route.params.id,
        checklistid: "",
        doc: "",
        refno: "",
        remarks: "",
        isposted: "",
        created_by:
          this.$store.getters.user.firstname +
          " " +
          this.$store.getters.user.lastname,
        createbyid: this.$store.getters.user.emplId,
        created_date: "",
      },

      fields: [
        {
          key: "description",
          label: "Checklist",
          sortable: true,
        },
        {
          key: "valuedatatype",
          label: "Action",
        },
        {
          key: "reason",
          label: "Reason",
        },
        {
          key: "attachrequired",

          label: "Attachment",
          thClass: ["width-20", "text-center"],
          tdClass: "text-center",
          sortable: true,
        },
      ],
      itseklistfield: [
        {
          key: "description",
          label: "Indicator",
        },
        {
          key: "categorycode",
          label: "Category",
        },
      ],
    };
  },

  components: {
    "input-checklist": InputChecklist,
  },
  computed: {
    filterchecklistid: function () {
      var self = this;
      return self.checklistIdoptions.filter(function (cust) {
        return (
          cust.checklistdescription
            .toLowerCase()
            .indexOf(self.Searchchecklistid.toLowerCase()) >= 0
        );
      });
    },
  },

  methods: {
    async camCapture() {
      let self = this;
      this.isShowAlert = false;
      /*    const { Camera } = Plugins;
  const image = await Camera.getPhoto({
    quality: 75,
    allowEditing: false,
    resultType: CameraResultType.Base64
  });
  // image.webPath will contain a path that can be set as an image src. 
  // You can access the original file using image.path, which can be 
  // passed to the Filesystem API to read the raw data of the image, 
  // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
  //var imageUrl = image.webPath;
  console.log(image);
          self.imglistselected.push({
            img: image.base64String,
            filename: "Mobile caputure",
            extension: "PNG"
          }); //

          self.imageSrc = "data:image/jpeg;base64," + image.base64String;
          `data:image/jpeg;base64,${image.base64String}`;
          console.log('here:',self.imageSrc);
  // Can be set to the src of an image now
  //imageElement.src = imageUrl;*/

      navigator.camera.getPicture(
        (data) => {
          // on success
          console.log(data);
          self.imglistselected.push({
            img: data,
            filename: "Mobile caputure",
            extension: "JPG",
          });
          //    self.imageSrc = "data:image/jpeg;base64," + data;
          //     // self.imageSrc = `data:image/jpeg;base64,${data}`;
          self.imageSrc = "data:image/jpeg;base64," + image.base64String;
          `data:image/jpeg;base64,${image.base64String}`;
          console.log("here:", self.imageSrc);
        },
        () => {
          // on fail
          miniToastr.error("Could not access device camera.");
        },
        {
          quality: 100,
          destinationType: Camera.DestinationType.DATA_URL,
          sourceType: Camera.PictureSourceType.CAMERA,
          mediaType: Camera.MediaType.PICTURE,
          encodingType: Camera.EncodingType.JPEG,
          cameraDirection: Camera.Direction.BACK,
          targetWidth: 720,
          targetHeight: 500,
        }
      );
    },
    onReason: function (val) {
      if (val === "True" || val === true) {
        return true;
      } else if (val === "false" || val === false) {
        return false;
      }
    },

    getFileContentAsBase64(path, callback) {
      window.resolveLocalFileSystemURL(path, gotFile, fail);

      function fail(e) {
        alert("Cannot found requested file");
      }

      function gotFile(fileEntry) {
        fileEntry.file(function (file) {
          var reader = new FileReader();
          reader.onloadend = function (e) {
            var content = this.result;
            callback(content);
          };
          // The most important point, use the readAsDatURL Method from the file plugin
          reader.readAsDataURL(file);
        });
      }
    },
    captureSuccess(mediaFiles) {
      let self = this;
      var i, len;
      for (i = 0, len = mediaFiles.length; i < len; i += 1) {
        console.log(mediaFiles[i]);
        this.getFileContentAsBase64(
          mediaFiles[i].fullPath,
          function (base64Image) {
            //window.open(base64Image);
            console.log(base64Image);
            self.imglistselected.push({
              img: base64Image.split(",")[1],
              filename: "Mobile caputure",
              extension: "JPG",
            });
          }
        );
      }
    },
    captureError(error) {
      var msg = "An error occurred during capture: " + error.code;
      navigator.notification.alert(msg, null, "Uh oh!");
    },
    onCapure: function () {
      let self = this;
      this.isShowAlert = false;
      navigator.camera.getPicture(
        (data) => {
          // on success
          console.log(data);
          self.imglistselected.push({
            img: data,
            filename: "Mobile caputure",
            extension: "JPG",
          }); //
          self.imageSrc = "data:image/jpeg;base64," + data;
          `data:image/jpeg;base64,${data}`;
        },
        () => {
          // on fail
          miniToastr.error("Could not access device camera.");
        },
        {
          quality: 75,
          destinationType: Camera.DestinationType.DATA_URL,
          sourceType: Camera.PictureSourceType.CAMERA,
          mediaType: Camera.MediaType.PICTURE,
          encodingType: Camera.EncodingType.JPEG,
          cameraDirection: Camera.Direction.BACK,
          targetWidth: 720,
          targetHeight: 500,
          correctOrientation: true,
        }
      );

      // var options = {     quality: 75,     sourceType:
      // Camera.PictureSourceType.CAMERA,     mediaType: Camera.MediaType.PICTURE,
      // encodingType: Camera.EncodingType.JPEG,     cameraDirection:
      // Camera.Direction.BACK,     targetWidth: 720,     targetHeight: 500,
      // correctOrientation: true }; navigator     .device     .capture
      // .captureImage(this.captureSuccess, this.captureError, {         limit: 2 },
      // options);
    },

    getData: function () {
      let self = this;
      if (this.$route.params.id !== undefined) {
        self.$store.commit("setLoading", true);
        api.instance
          .get("itsek/get-itsek-master-id?id=" + this.$route.params.id)
          .then((resp) => {
            self.form = resp.data;
            self.ChecklistDescription = resp.data.checklistDescription;
            self.form.checklistid = resp.data.checklistid;

            console.log("form", resp.data);
            self.$store.commit("setLoading", false);
          })
          .catch((err) => {
            console.log(err);
            self.$store.commit("setLoading", false);
          });
      }
    },

    getrequired: function () {
      let self = this;
      //if (this.$route.params.id !== undefined) {
      self.$store.commit("setLoading", true);
      api.instance
        .get("Itsek/get-itsek-attachmentlist-complete?itsekid=" + this.form.id)
        .then((resp) => {
          self.requiredlist = resp.data;
          // self.ChecklistDescription = resp.data.checklistDescription;
          // self.form.checklistid = resp.data.checklistid;

          console.log("form", resp.data);
          self.$store.commit("setLoading", false);
        })
        .catch((err) => {
          console.log(err);
          self.$store.commit("setLoading", false);
        });
      // }
    },

    checkForm: function () {
      if (this.form.checklistid) {
        return true;
      } else {
        this.errors = [];

        if (!this.form.checklistid) {
          this.errors.push("Checklist required.");
        }

        return false;
      }
    },
    onDeleteTepmfile: function (index) {
      this.imglistselected.splice(index, 1);
    },
    validateFirstStep() {
      return new Promise((resolve, reject) => {
        if (this.checkForm() == false) {
          reject();
        } else {
          this.$store.commit("setLoading", true);
          // this.getrequired();
          if (this.appendtext == "Create") {
            console.log("Forwarded Data:", this.form);
            api.instance
              .post("itsek/add-itsek-master", this.form)
              .then((resp) => {
                if (resp.data.categoryGroup.length == 0) {
                  miniToastr.error(
                    "You have an empty checklist. Create OS Ticket for this. Provide the Checklist Name and Site"
                  );
                  resolve(false);
                } else {
                  this.checklist = resp.data.categoryGroup;
                  this.form = resp.data.viewitsekmaster;
                }

                this.$store.commit("setLoading", false);
                resolve(true);
              })
              .catch(function (err) {
                api.httpMsg(this, err.status, err.data);
                this.$store.commit("setLoading", false);
                resolve(false);
              });
          } else if (this.appendtext == "Modify") {
            api.instance
              .put("itsek/modify-itsek-master", this.form)
              .then((resp) => {
                this.checklist = resp.data.categoryGroup;

                this.form = resp.data.viewitsekmaster;
                //this.reason = resp.data.categoryGroup.chek.reason;
                this.$store.commit("setLoading", false);

                resolve(true);
              })
              .catch(function (err) {
                this.$store.commit("setLoading", false);
                api.httpMsg(this, err.status, err.data);
                resolve(false);
              });
          }
        }
      });
      // return true;
    },
    onSelectChecklist: function (val) {
      let self = this;
      self.form.checklistid = val.checklistId;
      self.ChecklistDescription = val.checklistdescription;
    },
    //get groupCode

    GetChecklistID: function () {
      let self = this;
      self.errors = false;
      self.$store.commit("setLoading", true);
      self.checklistIdoptions = [];
      api.instance
        .get(
          "itsek/get-chechlist-by-indicator?department=" +
            this.$store.getters.warehousecode
        )
        .then(function (resp) {
          resp.data.forEach((element) => {
            self.checklistIdoptions.push({
              checklistId: element.checklistId,
              checklistdescription: element.checklistdescription,
            });
          });
          self.ChecklistidModal = true;
          self.$store.commit("setLoading", false);
        })
        .catch(function (err) {
          console.log(err);
          self.$store.commit("setLoading", false);
        });
    },

    onDeleteAttachment: function (val) {
      api.instance
        .delete("itsek/delete-checklist-attachment/" + val.attachId)
        .then((resp) => {
          val.isdelete = true;
          miniToastr.success("Delete attachment complete.");
        })
        .catch((error) => {
          miniToastr.error(error);
        });
    },
    onUpload() {
      let self = this;
      var i = 0;
      for (var value of this.imglistselected) {
        const evt = new FormData();
        evt.append("image", value.img);
        evt.append("extension", value.extension);
        evt.append("origfilename", value.filename);
        evt.append(
          "checklistransactionId",
          this.selected.checklistransactionId
        );
        evt.append("created_by", self.form.created_by);
        evt.append("createbyid", self.form.createbyid);
        api.instance
          .post("itsek/UploadChecklist", evt, {
            onUploadProgress: (uploadEvent) => {
              console.log(
                "Upload Progress: " +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
            },
          })
          .then((res) => {
            self.uploadCounter = Math.round(
              (i / self.imglistselected.length) * 100
            );
            self.isShowAlert = true;
            if (self.uploadCounter == 100) {
              this.imglistselected = [];
            }
          })
          .catch(function (error) {
            miniToastr.error(error);
          });
        i++;
      }
    },
    onSelectImage: function (param) {
      this.selected = param;
      this.modalAttach = true;
      this.imglistselected = [];
      this.uploadedImage = [];
      this.uploadCounter = 0;
      this.onGetChecklistAttachment(this.selected.checklistransactionId);
    },
    onViewUploaded: function () {
      this.onGetChecklistAttachment(this.selected.checklistransactionId);
    },
    onGetChecklistAttachment: function (checklistTransId) {
      let self = this;
      self.$store.commit("setLoading", true);
      api.instance
        .get("itsek/get-checklist-attachment?id=" + checklistTransId)
        .then((resp) => {
          self.uploadedImage = resp.data;
          self.$store.commit("setLoading", false);
        })
        .catch((err) => {
          miniToastr.error(err);
          self.$store.commit("setLoading", false);
        });
    },
    onBrownserImage: function () {
      this.isShowAlert = false;
      this.uploadCounter = 0;
      this.$refs.imageinput.click();
      this.files = null;
    },
    onfileselected: function (event) {
      for (var i = 0; i <= event.target.files.length - 1; i++) {
        this.selectedImg.push(event.target.files[i]);
        this.loadPicture(
          event.target.files[i],
          event.target.files[i].name,
          event.target.files[i].name.split(".").pop().toLowerCase()
        );
      }
    },
    loadPicture(img, filename, extension) {
      let self = this;
      //  let img = document.getElementById("img");
      var reader = new FileReader();
      reader.onload = function (e) {
        //self.src = e.target.result; self.imglistselected.push(e.target.result);
        self.imglistselected.push({
          img: e.target.result.split(",")[1],
          filename: filename,
          extension: extension,
        });
      };
      reader.readAsDataURL(img);
    },

    beforeTabSwitch: function () {
      console.log(this.form.id);

      return new Promise((resolve, reject) => {
        if (this.checkForm() == false) {
          reject();
        } else {
          this.$store.commit("setLoading", true);

          api.instance
            .post("itsek/post-updated-checklist", this.checklist)
            .then((resp) => {
              this.$store.commit("setLoading", false);
              // resolve(true);
            })
            .catch((error) => {
              this.$store.commit("setLoading", false);
              miniToastr.error(error);
              // resolve(true);
            });

          api
            .instance(
              "itsek/get-itsek-attachment-complete?itsekid=" + this.form.id
            )
            .then((resp) => {
              miniToastr.success(resp.data);
              resolve(true);
            })
            .catch((error) => {
              this.$store.commit("setLoading", false);
              this.getrequired();
              this.RequiredModal = true;
              miniToastr.error("Please Complete Required Attachment.");
              reject();
            });

          // resolve(true)

          return true;
        }
      });
    },

    requiredattach(reject) {},

    onComplete: function () {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (!isEmpty) {
        this.$store.commit("setLoading", true);
        const evt = new FormData();

        evt.append("image", data.split(",")[1]);
        evt.append("transactionId", this.id);
        evt.append("created_by", this.created_by);
        api.instance
          .post("itsek/UploadFleetSignature?id=" + this.form.id, evt)
          .then((res) => {
            this.$store.commit("setLoading", false);
            this.isShowSigAlert = true;
          })
          .catch(function (error) {
            // this.$store.commit("setLoading", false);
            miniToastr.error(error);
          });
      } else {
        miniToastr.error("Please provide signature!");
      }
    },

    onSigClear: function () {
      this.$refs.signaturePad.clearSignature();
    },
    onChange: function () {
      this.$nextTick(function () {
        this.$refs.signaturePad.resizeCanvas();
      });
    },
    resizeCanvas() {
      // When zoomed out to less than 100%, for some very strange reason, some
      // browsers report devicePixelRatio as less than 1 and only part of the canvas
      // is cleared then.
      var ratio = Math.max(window.devicePixelRatio || 1, 1);
      // canvas.width = canvas.offsetWidth * ratio; canvas.height =
      // canvas.offsetHeight * ratio; canvas.getContext("2d").scale(ratio, ratio);
    },
  },
  watch: {
    checked: function (val) {
      if (val) {
        this.form.isposted = 1;
      } else {
        this.form.isposted = 0;
      }
    },
  },
  created: function () {
    this.getData();
    //this.getrequired();
    console.log(this.form.checklistid);
  },
};
</script>

<style>
#signature {
  border: 1px solid;
}
</style>
